import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
//imported components within webapp
import './AddInquiryModal.css';
import { CommonTKeys, ShareModalTKeys, AddPersonModalTKeys } from '../../translations/keys';
import { getAnalytics, Events } from "../../clients/analytics";
import { useTranslation } from "../../translations/hooks";
import { getServer } from "../../clients/server";
import { getSentry, getService } from '../../clients';
//shared folder imports
import { getAssetInquiryText } from "@hermes/shared/src/business/functions/getShareMsg";
import { countriesArray, langAbbrMap, Language, Owner } from "@hermes/schema";
//antd imports
import { Modal, Form, Row, Col, Input, Select, notification, Alert } from "antd";
//other library imports
import PhoneInput from 'react-phone-input-2';
import { get } from 'https';
import firebase from 'firebase';

interface ModalProps {
  visible: boolean,
  closeModal: () => void;
  owner: Owner,
  assetName: string,
  assetId: string,
}

const { Option } = Select;

const AddInquiryModal: React.FunctionComponent<ModalProps> = ({ visible, closeModal, owner, assetName, assetId }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [name, setName] = useState<string>();
  const [desc, setDesc] = useState<string>('');
  const [error, setError] = useState({ key: '', value: false });
  const [phone_number, setPhone] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const sendNotification = (form: { name, phone_number, desc }) => {
    if (owner.phone_number && owner.phone_number.startsWith("+62")) {
      i18n.changeLanguage(langAbbrMap[Language.Indonesian]);
    } else if (owner.phone_number && owner.phone_number.startsWith("+966")) {
      i18n.changeLanguage(langAbbrMap[Language.Arabic]);
    } else if (owner.phone_number && owner.phone_number.startsWith("+971")) {
      i18n.changeLanguage(langAbbrMap[Language.English]);
    } else if (owner.phone_number && owner.phone_number.startsWith("+91")) {
      i18n.changeLanguage(langAbbrMap[Language.Hindi]);
    } else {
      i18n.changeLanguage(langAbbrMap[Language.English]);
    }

    getServer().createInquiryNotification({
      contact_details: {
        name: form.name,
        phone: form.phone_number,
      },
      description: form.desc ? form.desc : '',
      user_ref: {
        id: owner.id? owner.id: '',
        label: owner.label? owner.label: '',
      },
      asset_ref: {
        id: assetId ? assetId : assetId,
        label: assetName ? assetName : ''
      }
    }).then(() => {
      let config = {
        message: "Success",
        description: `${i18n.t(ShareModalTKeys.smsSuccess)} ${owner.phone_number}`
      };
      notification.success(config);
      setLoading(false)
      closeModal()
    }).then(async() => {
      await sendSms();
    }).catch((err) => {
      alert(`error occured ${err.message}`);
      console.log({ err: err.message });
      getSentry().captureException(err.message);
      let config = {
        message: i18n.t(CommonTKeys.error),
        description: i18n.t(ShareModalTKeys.smsError)
      };
      notification.error(config);
      setLoading(false)
    });
  };

  const sendSms = async() => {
    if (!owner) return;
      if (owner && owner.phone_number && owner.phone_number.startsWith("+62")) {
          i18n.changeLanguage(langAbbrMap[Language.Indonesian]);
      } else if (owner && owner.phone_number && owner.phone_number.startsWith("+966")) {
          i18n.changeLanguage(langAbbrMap[Language.Arabic]);
      } else if (owner && owner.phone_number && owner.phone_number.startsWith("+971")) {
          i18n.changeLanguage(langAbbrMap[Language.English]);
      } else if (owner && owner.phone_number && owner.phone_number.startsWith("+91")) {
          i18n.changeLanguage(langAbbrMap[Language.Hindi]);
      } else {
         i18n.changeLanguage(langAbbrMap[Language.English]);
      }
      const sms = i18n.t(ShareModalTKeys.inquiryText2);
      await getServer().sendSms({phone: owner && owner.phone_number? owner.phone_number: '', sms: `RENTBOOK: ${sms}`})
  }

  const handleOk = async () => {
    setLoading(true)
    if (name && phone_number) {
      getAnalytics().track(Events.ClickSendInquiry, {
        name,
        phone_number,
        desc,
      });
      sendNotification({ name, phone_number, desc });
      // sendSms()
    }
    else {
      setError({ key: "Please make sure you have entered your name and phone number correctly", value: true });
      setLoading(false)
    }
  }

  const closeError = () => {
    setError({ key: '', value: false });
  }

  return (
    <>
      <Modal
        closable={false}
        className="add-inquiry-modal"
        title={"Send Inquiry"}
        visible={visible}
        onOk={handleOk}
        onCancel={closeModal}
        okText={"Send Inquiry"}
        okButtonProps={{ loading: loading }}
        cancelText={i18n.t(CommonTKeys.cancel)}
      >
        <Form layout="horizontal" className="form" labelCol={{ span: 5 }} labelAlign="left">
          <Row gutter={[10, 10]}>
            {error.value ? (
              <Alert
                className="errMsg"
                message={i18n.t(error.key)}
                type="error"
                closable
                onClose={() => closeError()}
              />
            ) : null}
            <Col span={24}>
              <Form.Item
                label={"Full Name"}
              >
                <Input
                  value={name}
                  className="input-item"
                  name="name"
                  placeholder={"Please Enter Your Full Name"}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={i18n.t(AddPersonModalTKeys.phoneNumberLabel)}
              >
                <PhoneInput
                  value={phone_number}
                  country={"ae"}
                  preferredCountries={countriesArray}
                  inputProps={{
                    name: "phone",
                  }}
                  onChange={(e) => {
                    setPhone(`+${e}`);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={"Description"}
              >
                <Input.TextArea
                  value={desc}
                  className="input-item"
                  name="desc"
                  placeholder={"Please Enter Description (Optional)"}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}

export default AddInquiryModal;