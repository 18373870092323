import { createSelector } from 'reselect';
import { StoreState } from '../store/types';
import { PaymentType, RateTypes } from '@hermes/schema';
import * as moment from 'moment';

const getPaymentsMap = (state: StoreState) => state.payments;

export const getPaymentsList = createSelector(
  [getPaymentsMap],
  (paymentsMap) => {
    const payments = Object.values(paymentsMap);
    payments.sort((a, b) => b.doc.internal.created_at.seconds - a.doc.internal.created_at.seconds)
    return payments;
  }
);

export const getAssetPayments = (assetId: string) => createSelector(
  [getPaymentsMap],
  (paymentsMap) => {
    const paymentsValues = Object.values(paymentsMap);
    const payments = (paymentsValues || []).filter((v) => v.doc.asset_ref && v.doc.asset_ref.id === assetId)
    .sort((a, b) => b.doc.internal.created_at.seconds - a.doc.internal.created_at.seconds)
    return payments;
  }
);

export const getContactPayments = (contactId: string) => createSelector(
  [getPaymentsMap],
  (paymentsMap) => {
    const paymentsValues = Object.values(paymentsMap);
    const payments = (paymentsValues || [])
    .filter((v) => v.doc.people_ref && v.doc.people_ref.id === contactId)
    .sort((a, b) => b.doc.internal.created_at.seconds - a.doc.internal.created_at.seconds)
    return payments;
  }
);

export const calculateAmount = (rate, duration, rate_type, start_date, end_date, ongoing) => {
  // let amount;
  // start_date = moment(start_date.toDate())
  // if (!ongoing && end_date) {
  //     end_date = moment(end_date.toDate())
  // }
  // else {
  //     end_date = moment()
  // }

  // if (rate_type === RateTypes.Daily) {
  //     let days = end_date.diff(start_date, 'days')
  //     amount = Number(rate * days)

  // }
  // else if (rate_type === RateTypes.Weekly) {
  //     let weeks = end_date.diff(start_date, 'weeks')
  //     amount = Number(rate * weeks)

  // }
  // else if (rate_type === RateTypes.Monthly) {
  //     let months = end_date.diff(start_date, 'months')
  //     amount = Number(rate * months)

  // }
  // else if (rate_type === RateTypes.One_time) {
  //     amount = Number(rate)

  // }
  // return Number(amount);
  return Number(rate) * Number(duration);

}

export const getTotals = createSelector(
  [getPaymentsMap],
  (paymentsMap) => {
    const payments = Object.values(paymentsMap);
    console.log({payments});
    let totalEarned = 0;
        let totalSpent = 0;
        payments.forEach((payment) => {
            let doc = payment.doc
            console.log({doc});
            if (doc.type == PaymentType.Income) {
                totalEarned += isNaN(calculateAmount(doc.data.rate, doc.data.duration, doc.data.rate_type, doc.data.start_date, doc.data.end_date, doc.data.on_going))? 0: Number(calculateAmount(doc.data.rate, doc.data.duration, doc.data.rate_type, doc.data.start_date, doc.data.end_date, doc.data.on_going));
            }
            else if (doc.type == PaymentType.Expense) {
                totalSpent += isNaN(Number(calculateAmount(doc.data.rate, doc.data.duration, doc.data.rate_type, doc.data.start_date, doc.data.end_date, doc.data.on_going)))? 0: Number(calculateAmount(doc.data.rate, doc.data.duration, doc.data.rate_type, doc.data.start_date, doc.data.end_date, doc.data.on_going))
            }
        })
    let totalProfit = totalEarned - totalSpent
    return {
      totalEarned,
      totalSpent,
      totalProfit
    }
  }
);

export const activeAssets = createSelector(
  [getPaymentsMap],
  (paymentsMap) => {
    const payments = Object.values(paymentsMap);
    let active = 0;
    let assets:string[] = [];
    payments.forEach((payment) => {
        let doc = payment.doc
        if(doc.asset_ref && !assets.includes(doc.asset_ref.id) ){
          let end_date = doc.data.end_date ? moment(doc.data.end_date.toDate()) : moment();
          console.log(end_date, moment)
          if(end_date.isSameOrAfter(moment(), 'day')){
            active = active + 1;
            assets.push(doc.asset_ref.id);
          }
        }
    })
    return ({active, assets})
  }
);

