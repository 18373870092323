import { action } from "typesafe-actions";
import { ActionTypes } from "./constants";
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { FirestoreDocument, Payment } from "@hermes/schema";
import { StoreState } from "../store/types";
import { Service } from "../../business";



export const getPayments = () => action(ActionTypes.GET_PAYMENTS_REQUEST);
export const getPaymentsSuccess = (payload: {[id: string]: FirestoreDocument<Payment>;}) => action(ActionTypes.GET_PAYMENTS_SUCCESS, payload);
export const getPaymentsFailure = (message: string) => action(ActionTypes.GET_PAYMENTS_FAILURE, message);

export const getPayment = () => action(ActionTypes.GET_PAYMENT_REQUEST);
export const getPaymentFailure = (message: string) => action(ActionTypes.GET_PAYMENT_FAILURE, message);
export const getPaymentSuccess = (payload: {[id: string]: FirestoreDocument<Payment>;}) => action(ActionTypes.GET_PAYMENT_SUCCESS, payload);

export const addPaymentFailure = (message: string) => action(ActionTypes.ADD_PAYMENT_FAILURE, message);
export const addPaymentSuccess = (payload: {[id: string]: FirestoreDocument<Payment>;}) => action(ActionTypes.ADD_PAYMENT_SUCCESS, payload);
export const addPaymentRequest = () => action(ActionTypes.ADD_PAYMENT_REQUEST);

export const updatePaymentSuccess = (doc: FirestoreDocument<Partial<Payment>>) => action(ActionTypes.UPDATE_PAYMENT_SUCCESS, doc);
export const deletePaymentSuccess = (id: string) => action(ActionTypes.DELETE_PAYMENT_SUCCESS, id);

export const getAssetPaymentSuccess = (payload: {[id: string]: FirestoreDocument<Payment>}) => action(ActionTypes.GET_ASSET_PAYMENT_SUCCESS, payload);
export const getAssetPaymentFailure = (message: string) => action(ActionTypes.GET_ASSET_PAYMENT_FAILURE, message);



export const loadPayments = (): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getPayments());
        const user = getState().user.user;
        return service.paymentQuery.getAll(user.id).then((payments) => {
            const responseMap: {
                [id: string]: FirestoreDocument<Payment>;
            } = {};
            payments.forEach((response) => {
                responseMap[response.id] = {
                    id: response.id,
                    doc: response.data(),
                };
            });
            dispatch(getPaymentsSuccess(responseMap));
        }).catch((err) => {
            dispatch(getPaymentsFailure(err.message));
        });
    };
};

export const loadPaymentById = (paymentId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getPayment());
        const user = getState().user.user;
        const responseMap: {
            [id: string]: FirestoreDocument<Payment>;
        } = {};
        return service.paymentQuery.get(user.id, paymentId).then((paymentData) => {
            responseMap[paymentData.id] = {
                id: paymentData.id,
                doc: paymentData.data()
            }
            dispatch(getPaymentSuccess(responseMap));
        })
        .catch((err) => {
            dispatch(getPaymentFailure(err.message));
        })
    }
};

export const loadAssetPayments = (assetId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getPayments());
        const user = getState().user.user;
        return service.paymentQuery.getAssetPayments(user.id, assetId).then((payments) => {
            const responseMap: {
                [id: string]: FirestoreDocument<Payment>;
            } = {};
            payments.forEach((response) => {
                responseMap[response.id] = {
                    id: response.id,
                    doc: response.data(),
                };
            });
            dispatch(getAssetPaymentSuccess(responseMap));
        })
        .catch((err) => {
            dispatch(getAssetPaymentFailure(err.message));
        })
    }
};

export const addPayment = (data: Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >): ThunkAction<Promise<string>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(addPaymentRequest());
        const user = getState().user.user;
        const dataObj: Payment = {
            user_ref: {
                id: user.id,
                label: user.doc.name,
            },
            ...data,
            internal: {
                created_at: service.firebase.fromDate(new Date()),
                updated_at: service.firebase.fromDate(new Date()),
            },
            ended: false,
        };
        return service.paymentQuery.create(user.id, dataObj).then((res) => {
            dispatch(addPaymentSuccess({
                [res.id]: { id: res.id, doc: dataObj }
            }));
            return res.id;
        }).catch((err) => {
            dispatch(addPaymentFailure(err.message));
            throw err;
        });
    };
};

export const updatePayment = (id: string, doc: Partial<Payment>): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        const user = getState().user.user;
        return service.paymentQuery.update(user.id, { id, doc }).then((res) => {
            dispatch(updatePaymentSuccess({ id: id, doc: { ...doc }}));
        }).catch((err) => {
            throw err;
        });
    };
};

export const deletePayment = (paymentId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        const user = getState().user.user;
        return service.paymentQuery.delete(user.id, paymentId).then((res) => {
            dispatch(deletePaymentSuccess(paymentId));
        }).catch((err) => {
            throw err;
        });
    };
};
