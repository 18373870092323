import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//imported components within webapp
import './AddAssetModal.css';
import { getWebFirebaseApp } from '../../clients/firebase';
import { useTranslation } from "../../translations/hooks";
import { AddAssetModalTKeys } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import { getSentry } from '../../clients';
//shared folder imports
import { Status } from "@hermes/schema";
import { addAsset } from '@hermes/shared/src/redux/assets/actions';
import { StoreState } from "@hermes/shared/src/redux/store/types";
//antd imports
import { Modal, Form, Row, Col, Input, Upload, Select,  notification, Alert} from "antd";
import { PlusOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

interface ModalProps {
    visible: boolean,
    closeModal: () => void;
}

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => {
            reject(error)
            console.log(error)
            getSentry().captureException(error);
        };
    });
}
const AddAssetModal: React.FunctionComponent<ModalProps> = ({ visible, closeModal }) => {
    const user = useSelector((state: StoreState) => state.user);
    const uuidv4 = require('uuid/v4');
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const [assetName, setAssetName] = useState('');
    const [assetId, setAssetId] = useState('');
    const [assetStatus, setAssetStatus] = useState<Status>(Status.IDLE);
    const [error, setError] = useState({ key:'', value:false});
    const [desc, setDesc] = useState('');
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);
    const [fileList, setFileList] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const storageRef = getWebFirebaseApp().storage().ref();
    let imagesRef = storageRef.child('assets-images').child(user.user.id);

    const uploadButton = (
        <div>
            <PlusOutlined />
    <div className="ant-upload-text">{i18n.t(AddAssetModalTKeys.upload)}</div>
        </div>
    );
    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
        setPreviewVisible(true);
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList)
    };

    const handleOk = async () => {
        setLoading(true)
        if (assetName) {
            let gallery: { image: string | any }[] = []
            for (let i = 0; i < fileList.length; i++) {
                let file = fileList[i].originFileObj;
                let imageRef = imagesRef.child(uuidv4());
                let url = await imageRef.put(file).then(function (snapshot) {
                    return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
                })
                    .then(downloadURL => {
                        return (downloadURL)
                        // console.log(downloadURL)
                    })
                console.log(url)
                gallery.push({
                    image: url
                });
            }

            dispatch(addAsset({
                name: assetName,
                status: assetStatus,
                identifier:assetId,
                description: desc,
                gallery: gallery,
            })).then((res) => {
                let config = {
                    message: i18n.t(AddAssetModalTKeys.assetAddedTitle),
                    description: i18n.t(AddAssetModalTKeys.assetAddedDescription),
                }
                notification.success(config)
                closeModal()
            });

            getAnalytics().track(Events.ClickSaveAsset, { 
              name: assetName, 
              state: assetStatus,
              description: desc,
            });
        }
        else {
            setError({key:AddAssetModalTKeys.nameError, value:true});
        }
        setLoading(false)
    }

    const closeError = () => {
        setError({key:'', value:false});
    }


    return (
      <>
        <Modal
          closable={false}
          className="add-asset-modal"
          title={i18n.t(AddAssetModalTKeys.heading)}
          visible={visible}
          onOk={handleOk}
          onCancel={closeModal}
          okText={i18n.t(AddAssetModalTKeys.modalOkText)}
          confirmLoading={loading}
          cancelText={i18n.t(AddAssetModalTKeys.cancel)}
        >
          <Form
            layout="horizontal"
            className="form"
            labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Row gutter={[10, 10]}>
            {error.value ? (
                <Col span={24}>
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                </Col>
              ) : null}
              <Col span={24}>
                <Form.Item label={i18n.t(AddAssetModalTKeys.assetNameLabel)}>
                  <Input
                    name="name"
                    placeholder={i18n.t(
                      AddAssetModalTKeys.assetNamePlaceholder
                    )}
                    onChange={(e) => {
                      setAssetName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={i18n.t(AddAssetModalTKeys.assetIdLabel)}>
                  <Input
                    name="identifier"
                    placeholder={i18n.t(
                      AddAssetModalTKeys.assetIdPlaceholder
                    )}
                    onChange={(e) => {
                      setAssetId(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={i18n.t(AddAssetModalTKeys.assetDescriptionLabel)}
                >
                  <TextArea
                    name="description"
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder={i18n.t(
                      AddAssetModalTKeys.assetDescriptionPlaceholder
                    )}
                    onChange={(e) => {
                      console.log(e.target.value);
                      setDesc(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={i18n.t(AddAssetModalTKeys.imagesLabel)}>
                  {previewImage.length ? (
                    <img
                      alt="example"
                      style={{ width: "100%" }}
                      src={previewImage}
                    />
                  ) : null}

                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    accept=".jpeg,.jpg,.png"
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
}

export default AddAssetModal;