import { createSelector } from 'reselect';
import { StoreState } from '../store/types';


const getShareMap = (state: StoreState) => state.shareReceipt;

export const getShareReceipt = createSelector(
  [getShareMap],
  (shareMap) => {
    const share = Object.values(shareMap);
    return share;
  }
);