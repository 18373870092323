import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as firebase from 'firebase';
//imported components within webapp
import './AddReceiptModal.css';
import { getWebFirebaseApp } from '../../clients/firebase';
import { useTranslation } from "../../translations/hooks";
import { RentalsPageTKeys, CommonTKeys, ShareModalTKeys  } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import { getServer } from "../../clients/server";
import { getSentry } from '../../clients';
//shared folder imports
import { getTextShareMsg } from "@hermes/shared/src/business/functions/getShareMsg";
import { getPersonById } from "@hermes/shared/src/redux/people/selector";
import { generateShortLink } from "@hermes/shared/src/business/functions/generateShortLink";
import { FileType } from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
    loadPayments,
    updatePayment
  } from "@hermes/shared/src/redux/payments/actions";
import { Receipt } from "@hermes/schema";
//antd imports
import { Modal, Form, Row, Col, Input, Upload, Select,  notification, Alert, Avatar} from "antd";
import { PlusOutlined, CloseOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
//other imports
import NumberFormat from 'react-number-format';
import { ConsoleView } from 'react-device-detect';

const { TextArea } = Input;

interface ModalProps {
    personId?:string,
    receipt?: Receipt,
    visible: boolean,
    closeModal: () => void;
    edit?: boolean,
    index: number
}

const AddReceiptModal: React.FunctionComponent<ModalProps> = ({ personId, index, edit, visible, closeModal, receipt }) => {
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const uuidv4 = require('uuid/v4');
    const paymentDetail = useSelector((state: StoreState) => state.payments[state.ui.activePayment || ""]);
    const person = useSelector(getPersonById(personId || ''));
    const [amount, setAmount] = useState<string>('');
    const [desc, setDesc] = useState<string>('');
    const [docs, setDocs] = useState<{ name?: string, url:string, type:FileType}[]>();
    const [previewImage, setPreviewImage] = useState('');
    const [loading, setLoading] = useState<boolean>(false)
    const [fileList, setFileList] = useState<any[]>([]);
    const [url, setUrl] = useState<string>('');

    const user = useSelector((state: StoreState) => state.user);
    const uniqueDocId = uuidv4();
    const storageRef = getWebFirebaseApp().storage().ref();
    let imagesRef = storageRef.child('receipt-images').child(user.user.id);
    const uploadButton = (
        <div>
            <PlusOutlined />
    <div className="ant-upload-text">{"Upload Image"}</div>
        </div>
    );
    const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    const getTextShareMsg = () => {
        let text = t(CommonTKeys.textMessageForReceipt, {
            __AMOUNT_INFO__: `${(user.user.doc.currency)} ${amount} `,
            __LINK_INFO__: `${url}`,
            __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`
        });
        return text
      }

    const sendSms = async (url, amount) => {
        let sms = await getTextShareMsg()
        if(person){
            getServer().sendSms({phone: person.doc.phone_number, sms: `RENTBOOK: ${sms}`}).then(()=>{
              let config = {
                message: "Success",
                description: `${i18n.t(ShareModalTKeys.smsSuccess)} ${person.doc.phone_number}` 
              };
              
              notification.success(config);
              getAnalytics().track(Events.ClickShareReceipt, {});
            })
            .catch((err) => {
              getSentry().captureException(err.message);
              let config = {
                message: i18n.t(CommonTKeys.error),
                description: i18n.t(ShareModalTKeys.smsError)
              };
              
              notification.error(config);
            })
    
          }
    }
    
    const getShareUrl = (rentalId, receipt:Receipt) => {
        getServer()
          .shareReceipt({ rentalId, receipt })
          .then((response) => {
            console.log(response, "response")
            let link = `https://app.rentbook.com/share/${response.id}/${i18n.language}`
            generateShortLink(link)
            .then((res) => {
              if(typeof(res)==='string'){
                setUrl(res)
                sendSms(res, receipt.amount)
              }
            })
          })
          .catch((err) => {
            getSentry().captureException(err.message);
            console.log(err);
            
          })
    
      }

    const afterSave = () => {
        setLoading(false);
        dispatch(loadPayments());
        setAmount('0');
        setDesc("");
        closeModal()
    };

    const handleOk = async () => {
        setLoading(true)
        let documents: { name?: string, url:string, type:FileType}[] = []
            for (let i = 0; i < fileList.length; i++) {
                let file = fileList[i].originFileObj;
                if(Object.values(FileType).includes(file.type)){
                    let imageRef = imagesRef.child(uuidv4());
                    let url = await imageRef.put(file).then(function (snapshot) {
                        return snapshot.ref.getDownloadURL(); // Will return a promise with the download link
                    })
                        .then(downloadURL => {
                            return (downloadURL)
                            // console.log(downloadURL)
                        })
                    console.log(url)
                    documents.push({
                        url: url,
                        type: file.type
                    });
                }
                else{
                    console.log("make sure its an image or pdf", file.type)
                }
                
            }
        if(docs&& docs.length){
            documents = documents.concat(docs)
        }
        let receipt_new =  { 
            documents: documents, 
            description:desc,
            amount: parseInt(amount), 
            created_at: firebase.firestore.Timestamp.fromDate(new Date()),
        }
        const paymentObj = {
            data: {
              ...paymentDetail.doc.data,
              receipts: [
                receipt_new,...(paymentDetail.doc.data.receipts || [])],
          }
        };

        if (edit && paymentDetail.doc && paymentDetail.doc.data.receipts) {
            let config = {
                message: i18n.t(RentalsPageTKeys.receiptEdited),
                description: i18n.t(RentalsPageTKeys.receiptEditedDesc),
            }
            paymentDetail.doc.data.receipts[index].amount = receipt_new.amount;
            paymentDetail.doc.data.receipts[index].description = receipt_new.description;
            paymentDetail.doc.data.receipts[index].created_at = receipt_new.created_at;
            paymentDetail.doc.data.receipts[index].documents = receipt_new.documents;
            dispatch(updatePayment(paymentDetail.id, {
                data: {
                  ...paymentDetail.doc.data,
                  receipts: [...(paymentDetail.doc.data.receipts || [])]
                }
              }) as any).then(() => {
                notification.success(config)
                afterSave();
              }).catch((err: any) => {
                console.log({err});
                setLoading(false);
                getSentry().captureException(err.message);
              });
        }
        //new 
        else {
            dispatch(updatePayment(paymentDetail.id, paymentObj) as any).then(() => {
                if(!receipt){
                    let config = {
                        message: i18n.t(RentalsPageTKeys.receiptAdded),
                        description: i18n.t(RentalsPageTKeys.receiptAddedDesc),
                    }
                    notification.success(config)
                    let audio = new Audio("/assets/register.mp3");
                    audio.play()
                    getShareUrl(paymentDetail.id, receipt_new)
                }      
                afterSave();
              }).catch((err: any) => {
                console.log({err});
                setLoading(false);
                getSentry().captureException(err.message);
              });
        }

        
    }

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => {
                reject(error)
                console.log(error)
                getSentry().captureException(error);
            };
        });
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const handleChange = ({ fileList }) => {
        setFileList(fileList)
    };

    const deleteDoc = (doc) => {
        let docus = docs && docs.filter(item => item !== doc);
        setDocs(docus)
    }

    useEffect(() => {
        if(receipt){
            setAmount(receipt.amount.toString());
            setDesc(receipt.description?receipt.description:'');
            setDocs(receipt.documents)
        }
    },[receipt])


    return (
        <>
        <Modal
          className="add-receipt"
          closable={false}
          title={receipt? i18n.t(RentalsPageTKeys.editReceiptModal):i18n.t(RentalsPageTKeys.addReceiptModal)}
          visible={visible}
          onOk={handleOk}
          onCancel={closeModal}
          okText={receipt? i18n.t(RentalsPageTKeys.editReceiptModalButton):i18n.t(RentalsPageTKeys.addReceiptModalButton)}
          okButtonProps={{loading}}
          cancelText={i18n.t(RentalsPageTKeys.cancel)}
        >
            <Form
            layout="horizontal"
            labelCol={{ span: 6 }}
            labelAlign="left"
            >
                 <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <Form.Item label={i18n.t(RentalsPageTKeys.amountReceiptModal)}>
                            <NumberFormat 
                                className="amount"
                                thousandSeparator={true} 
                                value={amount}
                                onValueChange={(values) => {
                                const { value } = values;
                                if(value && value.length){
                                    setAmount(value);
                                }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={i18n.t(RentalsPageTKeys.descReceiptModal)}>
                            <TextArea
                                value={desc}
                                name="desc"
                                placeholder={i18n.t(RentalsPageTKeys.descPlaceholderReceiptModal)}
                                onChange={(e) => {
                                    setDesc(e.target.value)
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label={<div>{i18n.t(RentalsPageTKeys.receiptImages)}</div>}>
                    <div style={{display:'flex'}}>
                    {docs && docs.map((doc) =>{
                        return(
                            
                            <div className="images">
                                {/* <CloseOutlined className="close-icon" onClick={() =>deleteDoc(doc)}/> */}
                                <Avatar
                                    style={{margin:'0px 10px 10px 0px'}}
                                    shape="square"
                                    className="image"
                                    size={100}
                                    src={
                                        doc.type === "application/pdf"
                                        ? "/assets/pdf.png"
                                        : doc.url
                                    }
                                />
                                <div className="overlay">
                                    <div><a href={doc.url} target="_blank"><EyeOutlined /></a> <DeleteOutlined onClick={() =>deleteDoc(doc)} /></div>
                                </div>
                            </div>
                            
                        )
                    })}
                    </div>
                    {/* {previewImage.length ? (
                        <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                        />
                    ) : null} */}

                    <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        // onPreview={handlePreview}
                        onChange={handleChange}
                        accept=".jpeg,.jpg,.png"
                    >
                        {uploadButton}
                    </Upload>
                    </Form.Item>
                    </Col>

                 </Row>

            </Form>

        </Modal>
        </>
    )

}

export default AddReceiptModal;