import i18next from 'i18next';
import Common from './pages/Common.json';
import CreateAccountPage from './pages/CreateAccountPage.json';
import AddAssetModal from './pages/AddAssetModal.json';
import AddPersonModal from './pages/AddPersonModal.json';
import EditPersonModal from './pages/EditPersonModal.json';
import RentalWarningModal from './pages/RentalWarningModal.json';
import PaymentModal from './pages/PaymentModal.json';
import SettingsPage from './pages/SettingsPage.json';
import LoginPage from './pages/LoginPage.json';
import RentalsPage from './pages/RentalsPage.json';
import AssetsPage from './pages/AssetsPage.json';
import ContactsPage from './pages/ContactsPage.json';
import ShareModal from './pages/ShareModal.json';
import SideBar from './pages/SideBar.json';
import CurrencySelectorModal from './pages/CurrencySelectorModal.json';
import SharedFileViewerPage from './pages/SharedFileViewerPage.json';
import SharedAssetViewer from './pages/SharedAssetViewer.json';
import SharedReceiptView from './pages/SharedReceiptView.json';
import { initReactI18next } from "react-i18next";

const languages = ['en', 'ar', 'hi', 'ur', 'id'];
const languageMap: { [lang: string]: { [page: string]: { [key: string]: string }} } = {};
languages.forEach((lang) => {
    languageMap[lang] = {};
});
const pages: { page: string, translations: any }[] = [
    {
        page: 'AddAssetModal',
        translations: AddAssetModal,
    },
    {
        page: 'CreateAccountPage',
        translations: CreateAccountPage,
    },
    {
        page: 'Common',
        translations: Common,
    },
    {
        page: 'LoginPage',
        translations: LoginPage,
    },
    {
        page: 'PaymentModal',
        translations: PaymentModal,
    },
    {
        page:'AddPersonModal',
        translations: AddPersonModal
    },
    {
        page:'EditPersonModal',
        translations: EditPersonModal
    },
    {
        page:'SettingsPage',
        translations: SettingsPage
    },
    {
        page:'RentalsPage',
        translations: RentalsPage
    },
    {
        page:'AssetsPage',
        translations: AssetsPage
    },
    {
        page:'ContactsPage',
        translations: ContactsPage
    },
    {
        page:'SideBar',
        translations: SideBar
    },
    {
        page:'ShareModal',
        translations: ShareModal
    },
    {
        page:'CurrencySelectorModal',
        translations: CurrencySelectorModal
    },
    {
        page: 'RentalWarningModal',
        translations: RentalWarningModal,
    },
    {
        page: 'SharedFileViewerPage',
        translations: SharedFileViewerPage,
    },
    {
        page: 'SharedAssetViewer',
        translations: SharedAssetViewer
    },
    {
        page: 'SharedReceiptView',
        translations: SharedReceiptView
    }
];

pages.forEach((page) => {
    languages.forEach((lang) => {
        languageMap[lang][page.page] = {};
    });
    Object.keys(page.translations).forEach((key) => {
        languages.forEach((lang) => {
            if (page.translations[key][lang]) {
                languageMap[lang][page.page][key] = page.translations[key][lang] || "";
            } else {
                languageMap[lang][page.page][key] = page.translations[key]['en'] || "";
            }
        });
    });
});

const newInstance = i18next.createInstance();
newInstance
.use(initReactI18next)
.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',
    defaultNS: "common",                             // language to use
    resources: {
        en: {
            common: languageMap['en'],               // 'common' is our custom namespace
        },
        ar: {
            common: languageMap['ar'],
        },
        hi: {
            common: languageMap['hi'],
        },
        ur: {
            common: languageMap['ur'],
        },
        id: {
            common: languageMap['id'],
        },
    },
});

export const i18n = newInstance;