import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import ReceiptModal from "../AddReceiptModal/AddReceiptModal";
import ShareModal from "../ShareModal/ShareModal";
import { getServer } from "../../clients/server";
import "./ReceiptHistory.css";
import { useTranslation } from "../../translations/hooks";
import { CommonTKeys, ShareModalTKeys  } from "../../translations/keys";
import { RentalsPageTKeys } from "../../translations/keys";
import { getSentry } from '../../clients';
import { getAnalytics, Events } from "../../clients/analytics";
//shared folder imports
import { generateShortLink } from "@hermes/shared/src/business/functions/generateShortLink";
import { Receipt } from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
//antd imports
import { Card, Button, Timeline, Row, Col, Empty, Modal, notification, Collapse } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
//other imports
import moment from "moment";

interface Props {
  personId?:string;
  rentalId: string,
  receipts: Receipt[];
  showReceipt: () => void;
  currentReceipt: (record:Receipt) => void;
}

const ReceiptHistory: React.FunctionComponent<Props> = ({personId, rentalId, receipts, showReceipt, currentReceipt}) => {
  const confirm = Modal.confirm;
  const {i18n, t} = useTranslation();
  const locale = i18n.language;
  const user = useSelector((state: StoreState) => state.user);
  let currency = user.user.doc.currency;
  const [url, setUrl] = useState<string>('');
  const[modalVisible, setModalVisible] = useState<boolean>(false)
  const[addReceipt, setAddReceipt] = useState<boolean>(false)
  const[editReceipt, setEditReceipt] = useState<boolean>(false)
  const[shareLoading, setShareLoading] = useState<boolean>(false)
  const [shareModal, setShareModal] = useState(false)
  const[receipt, setReceipt] = useState<Receipt|undefined>()
  const [receiptIndex, setReceiptIndex] = useState(0);

  const getShareUrl = (receipt1?:Receipt) => {
    console.log("inside get share url")
    getServer()
      .shareReceipt({ rentalId, receipt })
      .then((response) => {
        console.log(response, "response")
        let link = `https://app.rentbook.com/share/${response.id}/${i18n.language}`
        generateShortLink(link)
        .then((res) => {
          console.log(res)
          if(typeof(res)==='string'){
            setUrl(res)
            setShareLoading(false)
          }
        })
      })
      .catch((err) => {
        getSentry().captureException(err.message);
        console.log(err);
        let config = {
          message: i18n.t(CommonTKeys.error),
          description: i18n.t(ShareModalTKeys.assetShareError)
        };

        notification.error(config);
      })

  }

  const handleDelete = (deleteId) => {
    console.log("deleted")
  };

  const showDeleteConfirm = (deleteId) => {
    confirm({
      title: "Delete Receipt?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleDelete(deleteId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      {addReceipt && modalVisible? 
       <ReceiptModal 
       index={0}
       personId={personId}
       visible={modalVisible} 
       closeModal={() => { 
          setModalVisible(false)
          setAddReceipt(false)
        }}/>
       :
       null
      }
      {editReceipt && modalVisible? 
       <ReceiptModal visible={modalVisible} receipt={receipt} 
       edit={editReceipt}
       index={receiptIndex}
       closeModal={() => {
         setModalVisible(false)
         setEditReceipt(false)
        }}/>
       :
       null
      }
       <ShareModal
          loading={shareLoading}
          visible={shareModal}
          closeModal={() => setShareModal(false)}
          setLoading={(bool) => setShareLoading(bool)}
          setVisible={(bool) => setShareModal(bool)}
          type='receipt'
          url={url}
          amountInfo={receipt? receipt.amount.toString():undefined}
          receiptCount={receipts.length}
        />
    
      <Card
        className={"receipt-history"}
        title={"Receipt History"}
        extra={
          <div className="button-container">
            <Button
                icon={<ShareAltOutlined />}
                onClick={() => {
                  setReceipt(undefined)
                  setShareLoading(true)
                  getShareUrl()
                  setShareModal(true)
                  getAnalytics().track(Events.ClickShareReceipt, {});
                }}
              >
                {i18n.t(RentalsPageTKeys.shareAllReceipts)}
              </Button>
            
            <Button
              type="primary"
              onClick={() => {
                setModalVisible(true)
                setAddReceipt(true)
              }}
            >
              {i18n.t(RentalsPageTKeys.addReceiptButton)}
            </Button>
          </div>
        }
        bordered>

        {receipts && receipts.length ? (
          <Timeline>
            {receipts.map((receipt1, index) => {
              return (
                <Timeline.Item>
                <Row gutter={[0, 0]}>
                  <Col span={24}>
                    <Row
                      gutter={[0, 0]}
                      className="receipt"
                    >
                      <Col span={10}>
                        <Row>  
                          <div>{ moment(receipt1.created_at.toDate()).locale(locale).format("Do MMM YYYY")}</div>
                          <Col span={24}>
                            <div className="desc">{receipt1.description?receipt1.description:''}</div>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <Row>
                          <b
                            className="amount"
                          >
                            {`${currency} `+ receipt1.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </b>
                        </Row>
                        
                      </Col>
                      <Col span={10} className="action-container">
                       
                        {/* <Button
                          icon={<DeleteOutlined />}
                          danger
                          type="text"
                          onClick={() => {
                            showDeleteConfirm("id");
                          }}
                        >
                           Delete
                        </Button> */}
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          icon={<ShareAltOutlined />}
                          onClick={() => {
                            setReceipt(receipt1)
                            setShareLoading(true)
                            setShareModal(true)
                            getShareUrl(receipt1)
                            getAnalytics().track(Events.ClickShareReceipt, {});
                          }}
                        >
                          {i18n.t(RentalsPageTKeys.shareReceipt)}
                        </Button>
                        <Button
                          type="primary"
                          shape="round"
                          ghost
                          onClick={() => {
                            setModalVisible(true)
                            setEditReceipt(true)
                            setReceipt(receipt1)
                            setReceiptIndex(index)
                          }}
                        >
                           {i18n.t(RentalsPageTKeys.editDetails)}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              
              </Timeline.Item>
              );
            })}
          </Timeline>
        ) : (
        
           
            <Empty
              image="/assets/empty-rentals.svg"
              description={<span>{i18n.t(RentalsPageTKeys.noReceipts)}</span>}
            ></Empty>
           
        )}
      </Card>
    </>
  );

}

export default ReceiptHistory;