import React, { FunctionComponent, ChangeEvent, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
  Link,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase";
import {isMobile} from "react-device-detect";
import * as Sentry from '@sentry/react';
import ReactGA from "react-ga";
//imported components within webapp
import ChatSupport from "./components/ChatSupport/ChatSupport";
import HeaderBar from "./components/Header/Header";
import SideBar from "./components/SideBar/SideBar";
import LoginPage from "./LoginPage/LoginPage";
import AssetsPage from "./AssetPage/AssetPage";
import SettingsPage from "./SettingsPage/SettingsPage";
import PeoplePage from "./ContactsPage/ContactsPage";
import RentalsPage from "./RentalsPage/RentalsPage";
import SplashScreen from "./SplashScreen/SplashScreen";
import DownloadPage from "./DownloadPage/DownloadPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import SharedFileViewerPage from "./SharedFileViewerPage/SharedFileViewerPage";
import StoreViewPage from "./StoreViewPage/StoreViewPage";
import { getService } from "./clients";
import { useTranslation } from "./translations/hooks";
import { init as initAnalytics, getAnalytics } from './clients/analytics';
import { AnalyticsMixpanel } from "./clients/analytics/AnalyticsMixpanel";
import { store } from './clients/store';
import "./App.css";
//antd imports
import 'antd/dist/antd.less'; // Import Ant Design styles by less entry
import './theme.less'; // variables to override above
import { ConfigProvider } from "antd";
import { Layout } from "antd";
//shared folder imports
import { changeLanguage } from '@hermes/shared/src/redux/user/actions';
import { withLanguage } from "@hermes/shared/src/ui/components/withLanguage";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import * as userActions from "@hermes/shared/src/redux/user/actions";
import { Language, langAbbrMap } from "@hermes/schema";

const { Header, Footer, Sider, Content } = Layout;

export enum ListType {
  Assets = "assets",
  Payments = "payments",
  People= "people"
}

if(isMobile){
  ReactGA.initialize("UA-123826692-4");
}
else{
  ReactGA.initialize("UA-123826692-2");
  ReactGA.set({ viewId: 228960998});
}
ReactGA.pageview(window.location.pathname + window.location.search);

initAnalytics(new AnalyticsMixpanel());
Sentry.init({
  dsn:
    "https://9b9c44cc74064290829433df52a9cc85@o345212.ingest.sentry.io/5312808",
  environment:
    window.location.hostname === "localhost" ? "development" : "production",
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
  ],
});

const App: React.FunctionComponent = () => {
  const pathname = window.location.pathname
  const {i18n, t} = useTranslation();
  const [loggedInUser, setLoggedIn] = useState(false);
  const [checkedLogin, setcheckedLogin] = useState(false);
  const [direction, setDirection] = useState<'ltr' | 'rtl' | undefined>();
  let url = encodeURIComponent(window.location.href);

  const user = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    // Update the document title using the browser API
    firebase.auth().onAuthStateChanged(async (user) => {
      let userData;
      if (user) {
        userData = await getService().query.getUser(user.uid);
        getAnalytics().setUserId(user.uid)
      }
      if (userData && userData.doc) {
        getAnalytics().setUserProps({
          name: userData.doc.name,
          phone_number: userData.doc.phone_number,
        });
        dispatch(userActions.login(userData));
        let lang = userData.doc.language
        i18n.changeLanguage(langAbbrMap[lang]);
        getAnalytics().setUserAttribute('language', userData.doc.language);
        store.dispatch(changeLanguage(lang as Language));  
        if(lang === Language.Arabic || lang === Language.Urdu){
          setDirection('rtl')
        }
        else{
          setDirection('ltr')
        }
        setLoggedIn(true);
        setcheckedLogin(true);
      } else {
        setLoggedIn(false);
        setcheckedLogin(true);
      }
    });
  },[]);

  useEffect(() => {
    // Update the document title using the browser API
    if(user){
      if(user.user &&user.user.doc && user.user.doc.language){
        let lang = user.user.doc.language;
        // i18n.changeLanguage(langAbbrMap[lang]);
        console.log(lang)
        if(lang === Language.Arabic || lang === Language.Urdu){
          setDirection('rtl')
        }
        else{
          setDirection('ltr')
        }
      }
    }
  },[user]);


  if (checkedLogin) {
    return (
      <ConfigProvider direction={direction}>
        <Router>
          {window.location.href.indexOf("/share/")> -1 ? null: <ChatSupport />}
          <Switch>
            {/* Access universal */}
            <Route
              path="/download"
              exact
              render={(props) => <DownloadPage {...props} />}
            />
            <Route
              path="/not-found"
              exact
              render={(props) => <NotFoundPage {...props} />}
            />
            <Route
              path="/loading"
              exact
              render={(props) => <SplashScreen {...props} />}
            />
            <Route
              path="/share/:shareId/:lang"
              exact
              render={(props) => <SharedFileViewerPage {...props} />}
            />
            <Route
              path="/share/:shareId"
              exact
              render={(props) => <SharedFileViewerPage {...props} />}
            />
             <Route
              path="/store/:storeName/:lang"
              exact
              render={(props) => <StoreViewPage {...props} />}
            />
            <Route
              path="/store/:storeName"
              exact
              render={(props) => <StoreViewPage {...props} />}
            />
            {/* Only accessible if logged In */}
            {loggedInUser && !isMobile && (
              <Layout>
                <Route path="/" render={(props) => <SideBar {...props} />} />
                <Content>
                  <Switch>
                    <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
                    {/* <Route
                    path="/assets"
                    exact
                    render={(props) => <HomePage {...props} />}
                  /> */}

                    <Route
                      path="/assets"
                      exact
                      render={(props) => <AssetsPage {...props} />}
                    />

                    <Route
                      path="/assets/:assetId"
                      exact
                      render={(props) => <AssetsPage {...props} />}
                    />

                    <Route
                      path="/people"
                      exact
                      render={(props) => <PeoplePage {...props} />}
                    />

                    <Route
                      path="/people/:personId"
                      exact
                      render={(props) => <PeoplePage {...props} />}
                    />

                    <Route
                      path="/rentals"
                      exact
                      render={(props) => <RentalsPage {...props} />}
                    />

                    <Route
                      path="/rentals/:rentalId"
                      exact
                      render={(props) => <RentalsPage {...props} />}
                    />

                    <Route
                      path="/settings"
                      exact
                      render={(props) => <SettingsPage {...props} />}
                    />

                    <Route
                      render={(props) => <Redirect to={`/rentals`} {...props} />}
                    />
                  </Switch>
                </Content>
              </Layout>
            )}
            {/* Only accessible if not legged In */}
            {!loggedInUser && !isMobile && (
              <Switch>
                <Route
                  path="/login"
                  exact
                  render={(props) => <LoginPage {...props} />}
                />
                <Route
                  path="/login/:phoneNumber"
                  exact
                  render={(props) => <LoginPage {...props} />}
                />
                <Route
                  render={() => <Redirect to={`/login?redirect=${url}`} />}
                />
              </Switch>
            )}
            {isMobile ? (
              <Route render={() => <Redirect to={`/download`} />} />
            ) : (
              <Route render={() => <Redirect to={`/not-found`} />} />
            )}
          </Switch>
        </Router>
      </ConfigProvider>
    );
  } else {
    return <SplashScreen />;
  }
};

export default withLanguage(App);
