import { Shared, SharedReceipt, FirestoreDocument } from "@hermes/schema";
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action } from "typesafe-actions";
import { Service } from "../../business";
import { StoreState } from "../store/types";
import { ActionTypes } from "./constants";

export const getSharedReceiptSuccess = (payload: {[id: string]: FirestoreDocument<SharedReceipt>;}) => action(ActionTypes.GET_SHARED_RECEIPT_SUCCESS, payload);
export const getShared = () => action(ActionTypes.GET_SHARED_REQUEST);
export const getSharedFailure = (message: string) => action(ActionTypes.GET_SHARED_FAILURE, message);

type SharedReceiptMap = {
    [id: string]: FirestoreDocument<SharedReceipt>;
};


export const loadShareReceiptById = (shareId: string): ThunkAction<Promise<SharedReceiptMap>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getShared());
        return service.sharedQuery.getSharedReceipt(shareId).then((shareData) => {
            const responseMap: SharedReceiptMap  = {};
            shareData.forEach((response) => {
                responseMap[response.id] = {
                    id: response.id,
                    doc: response.data(),
                };
            });
            dispatch(getSharedReceiptSuccess(responseMap));
            return responseMap
        })
        .catch((err) => {
            console.log("comes here", err)
            dispatch(getSharedFailure(err.message));
            return {}
        })
    }
};
