import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import AddAssetModal from "../components/AddAssetModal/AddAssetModal";
import AssetsTable from "../components/AssetsTable/AssetsTable";
import AssetDetails from "../components/AssetDetails/AssetDetails";
import CurrencySelectorModal from "../components/CurrenySelectorModal/CurrencySelectorModal";
import ShareModal from "../components/ShareModal/ShareModal"
import Footer from "../components/Footer/Footer";
import { useTranslation } from "../translations/hooks";
import { AssetsPageTKeys, CommonTKeys, ShareModalTKeys} from "../translations/keys";
import { getAnalytics, Events } from "../clients/analytics";
import { getServer } from "../clients/server";
import { getSentry } from '../clients';
import "./AssetPage.css";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
  getAssetsList
} from "@hermes/shared/src/redux/assets/selectors";
import {
  loadAssets
} from "@hermes/shared/src/redux/assets/actions";
import {
  Assets,
  FirestoreDocument,
  Currency
} from "@hermes/schema";
import { generateShortLink } from "@hermes/shared/src/business/functions/generateShortLink";
//antd imports
import {
  Button,
  Modal,
  Select,
  notification
} from "antd";
import {
  ShareAltOutlined
} from "@ant-design/icons";

const info = Modal.info;
const { Option } = Select;

const AssetsPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const assets= useSelector(getAssetsList);
  const {i18n, t} = useTranslation();
  let { assetId } = useParams();
  const user = useSelector((state: StoreState) => state.user);
  const [showAddAssetModal, setShowAddAssetModal] = useState(false);
  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [shareLoading, setShareLoading] = useState(false);
  const [url, setUrl] = useState<string>('');

  const [selectedAsset, setSelectedAsset] = useState<FirestoreDocument<Assets>>();

  const getShareUrl = () => {
    const assetIds = assets.map((a) => a.id);
    getServer()
      .shareAsset({ assetIds: assetIds })
      .then((response) => {
        console.log(response, "response")
        let link = `https://app.rentbook.com/share/${response.id}/${i18n.language}`
        generateShortLink(link)
        .then((res) => {
          console.log(res)
          if(typeof(res)==='string'){
            setUrl(res)
            setShareLoading(false)
          }
        })
      })
      .catch((err) => {
        getSentry().captureException(err.message);
        console.log(err);
        let config = {
          message: i18n.t(CommonTKeys.error),
          description: i18n.t(ShareModalTKeys.assetShareError)
        };

        notification.error(config);
      })

  }

  useEffect(() => {
    dispatch(loadAssets());
    if(user && user.user.doc && !user.user.doc.currency){
      setShowCurrencySelector(true)
    }
    else{
      setShowCurrencySelector(false)
    }
  }, []);

  // useEffect(() => {
  //   assets.forEach((asset) => {
  //     console.log(asset);
  //     if(asset.id === assetId){
  //       setSelectedAsset(asset);
  //       return;
  //     }
  //   });
  // }, [assets]);

  return (
    <>
    {/* {shareModal ? */}
        <ShareModal
          assetCount={assets.length}
          loading={shareLoading}
          visible={shareModal}
          closeModal={() => setShareModal(false)}
          setLoading={(bool) => setShareLoading(bool)}
          setVisible={(bool) => setShareModal(bool)}
          type='asset'
          url={url}
        />
        {/* : null} */}
      <div className="asset-page">
        {showCurrencySelector?
          <CurrencySelectorModal
            visible={showCurrencySelector}
            closeModal={() => {
              setShowCurrencySelector(false);
            }}
          />
          :
          null
        }
        {showAddAssetModal ? (
          <AddAssetModal
            visible={showAddAssetModal}
            closeModal={() => {
              setShowAddAssetModal(false);
            }}
          />
        ) : null}

        <div className="page-content">
          {!assetId && (
            <div className="heading">
              <h3>{i18n.t(AssetsPageTKeys.assetsHeading)}</h3>
              <div className="button-container">
                <Button
                  type="primary"
                  onClick={() => {
                    setShowAddAssetModal(true);
                    getAnalytics().track(Events.ClickNewEquipment, {});
                  }}
                >
                  {i18n.t(AssetsPageTKeys.newAssetButton)}
                </Button>
                  <Button
                icon={<ShareAltOutlined />}
                onClick={() => {
                  setShareLoading(true)
                  setShareModal(true)
                  getShareUrl()
                  getAnalytics().track(Events.ClickShareAllAssets, {});
                }}
              >
                {i18n.t(AssetsPageTKeys.shareAllAssets)}
              </Button>
              </div>
            </div>
          )}

          {!assetId && (
             <AssetsTable assets={assets} showModal={()=> setShowAddAssetModal(true) } setSelectedAsset={(asset) => setSelectedAsset(asset)}/>
          )}

          {assetId && selectedAsset && selectedAsset.id && (
            <AssetDetails active={selectedAsset} />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AssetsPage;
