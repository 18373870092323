import { Payment, FirestoreDocument } from "@hermes/schema";

export const checkIfDatesOverLap = (rentals: FirestoreDocument<Payment>[],obj:Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" > ) => {
    let overlap:FirestoreDocument<Payment>[]=[];
    rentals.forEach((rental) =>{
      let rental_startDate = rental.doc.data.start_date.toDate()
      let rental_endDate = rental.doc.data.end_date? rental.doc.data.end_date.toDate() : new Date()
      let current_startDate = obj.data.start_date.toDate()
      let current_endDate = obj.data.end_date? obj.data.end_date.toDate() : new Date()
      if((rental_startDate <= current_endDate) && (current_startDate <= rental_endDate)){
        console.log("dates overlap")
        overlap.push(rental)
      }
    })
    return(overlap)
}

export const checkIfRentalExists = (obj:Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >, payments: FirestoreDocument<Payment>[]) => {
    if(obj.asset_ref && payments.length){
      const rentals = payments.filter(payment => payment.doc.asset_ref?.id === obj.asset_ref?.id);
      if(rentals.length){       
        let overlap = checkIfDatesOverLap(rentals,obj)
        if(overlap){
            return(overlap)
        }
        else{
            return([])
        }
      }
      else{
        return([])
      }

    }
    else{
      return([])
    }
}

  