


interface String {

    toArabic(): string;
    toArabicDigits(): string
    toHindi(): string;
    toUrdu(): string;
}

String.prototype.toArabic = function() {
    return this.replace(/\d/g, (d: any) =>  '٠١٢٣٤٥٦٧٨٩'[d]);
  }

String.prototype.toArabicDigits = function(){
const id = ['٠','١','٢','٣','٤','٥','٦','٧','٨','٩'];
return this.replace(/[0-9]/g, function(w){
    return id[+w];
    });
};

String.prototype.toHindi = function(){
const id = ['०', '१', '२', '३', '४', '५', '६', '७', '८', '९'];
return this.replace(/[0-9]/g, function(w){
    return id[+w];
    });
};

String.prototype.toUrdu = function(){
    const id = [
        '٩',
        '٨',
        '٧',
        '٦',
        '٥',
        '٤',
        '٣',
        '٢',
        '١',
        '٠', ]
return this.replace(/[0-9]/g, function(w){
    return id[+w];
    });
};
