import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import './CurrencySelectorModal.css';
import { useTranslation } from "../../translations/hooks";
import { CurrencySelectorModalTKeys } from "../../translations/keys";
//shared folder imports
import { updateUser, changeLanguage } from "@hermes/shared/src/redux/user/actions";
import { Currency, currenciesArray } from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
//antd imports
import { Modal, Row, Col, Form, Select } from "antd";

interface Props {
    visible: boolean,
    closeModal: () => void;
}

const { Option } = Select;

const CurrencySelectorModal: React.FunctionComponent<Props> = ({ visible, closeModal }) => {
    const {i18n, t} = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.user);

    const updateUserCurrency = () => {
        if (currency) {
            let obj = {
                currency
            };

            dispatch(updateUser(user.user.id, obj)).then(() => {
                closeModal()
            });
        }
    }
   


    const [currency, setCurrency] = useState<Currency>(Currency.AED);

    return (
        <>
            <Modal
                className='currency-modal'
                closable={false}
                title={i18n.t(CurrencySelectorModalTKeys.title)}
                visible={visible}
                onOk={updateUserCurrency}
                onCancel={closeModal}
                okText={i18n.t(CurrencySelectorModalTKeys.okText)}
                cancelButtonProps={{ style: { display: 'none' } }}
                cancelText={i18n.t(CurrencySelectorModalTKeys.cancel)}
            >
                <Form
                    layout="vertical"
                    className="form"
                    // labelCol={{ span: 5 }}
                    labelAlign="left"
                >
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <Form.Item label={i18n.t(CurrencySelectorModalTKeys.selectCurr)}>
                                <Select
                                    id="currency"
                                    value={currency}
                                    onChange={(value: Currency) => {
                                        setCurrency(value);
                                    }}
                                    style={{marginTop:'5px'}}
                                >
                                    {currenciesArray.map((currency) => {
                                        return (
                                            <Option value={currency.value}>
                                            {currency.label}
                                            </Option>
                                        );
                                    })}
                                   
                                </Select>
                            </Form.Item>

                        </Col>
                    </Row>

                </Form>

            </Modal>
        </>
    )
}

export default CurrencySelectorModal