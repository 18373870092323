import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
//imported components within webapp
import "./SharedReceiptView.css";
import { useTranslation } from "../../translations/hooks";
import { SharedFileViewerPageTKeys, SharedReceiptViewTKeys, SharedAssetViewerTKeys } from "../../translations/keys";
import { toDataURL } from "../../SharedFileViewerPage/SharedFileViewerPage";
import SharedFileHeader from "../../SharedFileViewerPage/SharedFileHeader";
//shared folder imports
import { loadShareReceiptById } from "@hermes/shared/src/redux/sharedReceipt/actions";
import { getShareReceipt } from "@hermes/shared/src/redux/sharedReceipt/selectors";
import { Owner, langCodeArray, Language } from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
    FirestoreDocument,
    Payment,
    Receipt,
    RateTypes,
    SharedReceipt,
    Reference
  } from "@hermes/schema";
//antd imports
import { Card, Button, Row, Col, Layout, Modal, Divider, Avatar, Menu, Collapse, List, notification, ConfigProvider } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
//Other imports
import { isMobileOnly } from "react-device-detect";
const appLink = "https://app.rentbook.com";

const { Panel } = Collapse;

interface Props {
    shareId: string
    owner: Reference
} 

const ReceiptView: React.FunctionComponent<Props> = ({ shareId, owner }) => {
    let { lang } = useParams();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const receipts = useSelector(getShareReceipt);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [direction, setDirection] = useState<'ltr' | 'rtl' | undefined>();
    console.log(receipts)
   

    const formatDate = (d: firebase.firestore.Timestamp)=>{
        let date = new Date(d.seconds*1000+d.nanoseconds/1000)
        const formatted_date = ('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth()+1)).slice(-2)+"/"+ date.getFullYear().toString()
        return formatted_date
    }

      const download = async (url) => {
        setDownloading(true);
        const a = document.createElement("a");
        a.href = await toDataURL(url);
        a.download = "download";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownloading(false);
        let config = {
          message: "Downloaded",
          description: "File has been downloaded",
        };
        notification.success(config);
      };

    const calcDuration = (receipt:SharedReceipt) => {
        const type: RateTypes = receipt.rate_type
        let duration =''
        switch(type){
            case RateTypes.Daily:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Daily, {
                  __DURATION__: `${receipt.duration}`
                  })
                break;
            case RateTypes.Monthly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Monthly, {
                  __DURATION__: `${receipt.duration}`
                  })
                break;
            case RateTypes.Weekly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Weekly, {
                  __DURATION__: `${receipt.duration}`
                  })
                break;
            case RateTypes.Yearly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Yearly, {
                  __DURATION__: `${receipt.duration}`
                  })
                break;
            case RateTypes.One_time:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_OneTime)
                break;
        }
        return duration
    }

    useEffect(()=>{
        dispatch(loadShareReceiptById(shareId)).then((response) => {
            console.log(response);
          }
        );
        if(isMobileOnly){
          let url = window.location.href;
          window.location.href = `rentbook://rentbook.com?shareLink=`+url;
        }
        i18n.changeLanguage(lang?lang:'en');   
        if(langCodeArray.includes(lang)){
          i18n.changeLanguage(lang);   
          if(lang === "ar" || lang ==="ur"){
            setDirection('rtl')
          }
          else{
            setDirection('ltr')
          }
        }
        else{
          i18n.changeLanguage('en');   
        }   
    }, [])
    
    return(
    <ConfigProvider direction={direction}>
    <div className="ant-layout receipt-view">
      <SharedFileHeader/>
      {downloading ? (
        <Modal
          className="share-modal"
          title={i18n.t(SharedFileViewerPageTKeys.fileDownload)}
          visible={downloading}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>{i18n.t(SharedFileViewerPageTKeys.downloading)}</div>
        </Modal>
      ) : null}
     <Row gutter={[30,10]} className="container">
     {receipts.map((receipt, index) => {
          return (
        <Col xs={24} md={12} lg={12} xl={8}>
            <Card>
                <Col span={24}  className='heading-container'>
                    <h1 className="heading">{i18n.t(SharedReceiptViewTKeys.receipt)}</h1>
                    <div className="amount">
                      <div className="label">{i18n.t(SharedReceiptViewTKeys.amountReceived)}</div>
                      <div className="value">{receipt.doc.currency} {receipt.doc.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                    </div>

                </Col>
                <Col span={24}>
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.billedTo)}</div>
                    <div className="value">{receipt.doc.people_ref?.label}</div>
                </Col>
                <Col span={24}>
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.receiptDate)}</div>
                    <div className="value">{formatDate(receipt.doc.receipt_date)}</div>
                </Col>
                <Col span={24} className="attachments">
                    {/* <div className="label">Attachments</div> */}
                    {/* {receipt.doc.documents && receipt.doc.documents.length ?
                    <div>
                    {receipt.doc.documents.map((doc) =>{
                        return(
                            
                            <a className="images" href={doc.url} target="_blank">
                                <Avatar
                                    shape="square"
                                    size={60}
                                    src={
                                        doc.type === "application/pdf"
                                        ? "/assets/pdf.png"
                                        : doc.url
                                    }
                                    />
                            </a>
                            
                        )
                    })}
                    </div>
                    :
                    <div>No attachments</div>
                   } */}
                   {receipts.length && receipt.doc.documents && receipt.doc.documents.length ?
                    (

                      <Collapse className='collapse' bordered={false} expandIconPosition={'right'} defaultActiveKey={['1']}>
                      <Panel 
                      header={
                        <p style={{color:"#E88247", fontWeight:600, marginBottom:'0px'}}>
                          {i18n.t(SharedAssetViewerTKeys.documentsAvailable, {
                          __DOC_COUNT__: `${receipt.doc.documents.length}`
                          })}
                        </p>
                        
                      } 
                      key="1">
                        
                      <List
                        className="images-list"
                        itemLayout="horizontal"
                        dataSource={
                            receipt.doc.documents.length ? receipt.doc.documents : []
                        }
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <a href={item.url} target="_blank">
                                <Avatar
                                  shape="square"
                                  size={60}
                                  src={
                                    item.type === "application/pdf"
                                      ? "/assets/pdf.png"
                                      : item.url
                                  }
                                />
                                </a>
                              
                              }
                              description={
                                <div className="doc-desc">         
                                  <Button 
                                    ghost 
                                    icon={<DownloadOutlined />} 
                                    size={"large"}
                                    onClick={() => {
                                      download(item.url);
                                    }}
                                  />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                      </Panel>
                      </Collapse>
                      
                   
                  ) : 
                  null
                  }
                </Col>
                <Divider dashed={true} />
                <Col span={24} className="asset-detail asset">
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.asset)}</div>
                    <div className="value">{receipt.doc.asset_ref?.label}</div>
                    {/* <div className="value identifier">{asset?.doc.identifier||''}</div> */}
                </Col>
                <Col span={24} className="asset-detail">
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.rate)}</div>
                    <div className="value">{receipt.doc.rate}</div>
                </Col>
                <Col span={24} className="asset-detail duration">
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.duration)}</div>
                    <div className="value">{calcDuration(receipt.doc)}</div>
                </Col>
                <Divider dashed={true} />
                {receipt.doc.description?
                <Col span={24}>
                    <div className="label desc">{i18n.t(SharedReceiptViewTKeys.description)}</div>
                    <div className="value desc">{receipt.doc.description.charAt(0).toUpperCase()+ receipt.doc.description.slice(1)}</div>
                </Col>
                :
                null
                }
                <Col span={24}>
                    <div className="label">{i18n.t(SharedReceiptViewTKeys.preparedBy)}</div>
                    <div className="value">{owner.label}</div>
                </Col>
                
            </Card>
        </Col>
    
          )}
    )}
    
    </Row>  



    </div>
    </ConfigProvider>
    )
}

export default ReceiptView;
  