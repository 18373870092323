import * as React from 'react';
//imported components within webapp
import { CommonTKeys, RentalWarningModalTKeys } from '../../translations/keys';
import { useTranslation } from "../../translations/hooks";
//antd imports
import { Modal } from "antd";


interface ModalProps {
    visible: boolean,
    closeModal: () => void;
    handleOk: () => void;
}

const RentalWarningModal: React.FunctionComponent<ModalProps> = ({ visible, closeModal, handleOk }) => {
    const {i18n, t} = useTranslation();

    return (
        <>
        <Modal 
            visible={visible}
            title={i18n.t(RentalWarningModalTKeys.warningTitle)}
            onCancel={closeModal}
            cancelText={i18n.t(CommonTKeys.cancel)}
            onOk={handleOk}
            okText={i18n.t(CommonTKeys.yes)}
        >
            <p>{i18n.t(RentalWarningModalTKeys.warningMsg)}</p>
        </Modal>
        </>
      );
}

export default RentalWarningModal;