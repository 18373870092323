import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
//imported components within webapp
import { CommonTKeys, ContactsPageTKeys } from "../../translations/keys";
import { useTranslation } from "../../translations/hooks";
import { getAnalytics, Events } from "../../clients/analytics";
import "./ContactsTable.css";
//shared folder imports
import { People, FirestoreDocument } from "@hermes/schema";
//antd imports
import { Button, Table, Empty, Avatar, Tag, Row, Col } from "antd";
import { RightOutlined } from "@ant-design/icons";

interface Props {
  contacts: FirestoreDocument<People>[];
  showModal: () => void;
  setSelectedContact: (record: FirestoreDocument<People>) => void;
}

const { Column } = Table;

const ContactsTable: React.FunctionComponent<Props> = ({
  contacts,
  showModal,
  setSelectedContact,
}) => {
  let history = useHistory();
  const {i18n, t} = useTranslation();
  const getAcronym = (str) => {
    if (str && str.length) {
      let matches = str.match(/\b(\w)/g); // ['J','S','O','N']
      let acronym = matches.join(""); // JSON
      return acronym;
    } else {
      return "X";
    }
  };

  const emptyPeople = (
    <Empty
      className="empty-large"
      image="/assets/empty-contact.svg"
      description={
        <p>
          {i18n.t(ContactsPageTKeys.emptyPeopleDesc)} {" "} <br />
          <a
            onClick={() => {
              showModal();
              getAnalytics().track(Events.ClickAddNewContact, {})
            }}
          >
            {i18n.t(ContactsPageTKeys.emptyPeopleLink)}
          </a>
        </p>
      }
    />
  );

  return (
    <div className="contacts-table">
      <Table
        dataSource={contacts}
        locale={{
          emptyText: emptyPeople,
        }}
        rowKey="id"
        rowClassName="custom-table-row"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          total: contacts.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        <Column
          title={i18n.t(CommonTKeys.nameLabel)}
          dataIndex={["doc", "name"]}
          sorter={(
            a: FirestoreDocument<People>,
            b: FirestoreDocument<People>
          ) =>
            a.doc.name && b.doc.name ? a.doc.name.localeCompare(b.doc.name) : 0
          }
          sortDirections={["descend", "ascend"]}
          render={(text, record: FirestoreDocument<People>) => {
            if (!text || !text.length) text = "--";
            return (
              <Row justify="center" align="middle" gutter={[20, 0]}>
                <Col span={8}>
                  <Avatar shape="circle">{getAcronym(text)}</Avatar>
                </Col>
                <Col span={16}>
                  <span>{text}</span>
                </Col>
              </Row>
            );
          }}
        />
        <Column
          title={i18n.t(ContactsPageTKeys.phoneNumber)}
          dataIndex={["doc", "phone_number"]}
          render={(text, record: FirestoreDocument<People>) => {
            if (!text || !text.length) text = "--";
            return <span>{text.toString().replace(/(\d{3})(\d{4})(\d{4})(\d{3})/, '$1 $2 $3 $4')}</span>;
          }}
        />

        <Column
          title={i18n.t(CommonTKeys.actionLabel)}
          render={(text, record: FirestoreDocument<People>) => {
            return (
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setSelectedContact(record);
                  console.log(record);
                  history.push(`/people/${record.id}`);
                  getAnalytics().track(Events.ClickContactDetails, { 
                    name: record.doc.name, 
                    phone_number: record.doc.phone_number

                  })
                }}
              >
                {i18n.t(CommonTKeys.viewDetailsButton)}
                <RightOutlined />
              </Button>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default ContactsTable;
