import * as React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase";
//imported components within webapp
import ReceiptHistory from '../ReceiptHistory/ReceiptHistory';
import {
  PaymentModalTKeys,
  RentalsPageTKeys,
  CommonTKeys,
} from "../../translations/keys";
import { useTranslation } from "../../translations/hooks";
import { store } from "../../clients/store";
import AddAssetModal from "../AddAssetModal/AddAssetModal";
import AddContactModal from "../AddContactModal/AddContactModal";
import RentalWarningModal from "../RentalWarningModal/RentalWarningModal";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { getAnalytics, Events } from "../../clients/analytics";
import "./RentalDetails.css";
//shared folder imports
import { loadAssets, updateAsset } from "@hermes/shared/src/redux/assets/actions";
import { loadPeople } from "@hermes/shared/src/redux/people/actions";
import { activeAssets, getPaymentsList } from "@hermes/shared/src/redux/payments/selector";
import { checkIfRentalExists } from "@hermes/shared/src/business/functions/checkForOverlap";
import {
  updatePayment,
  addPayment,
  deletePayment,
} from "@hermes/shared/src/redux/payments/actions";
import { getPeopleList } from "@hermes/shared/src/redux/people/selector";
import { getAssetsList } from "@hermes/shared/src/redux/assets/selectors";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { changeLanguage } from "@hermes/shared/src/redux/user/actions";
import { setActivePayment, setActivePaymentType } from "@hermes/shared/src/redux/ui/actions";
import {
  Payment,
  FirestoreDocument,
  PaymentType,
  Reference,
  RateTypes,
  Language,
  langAbbrMap,
  Status,
  Receipt
} from "@hermes/schema";
//antd imports
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  notification,
  DatePicker,
  Checkbox,
  Alert,
  Divider,
  Button,
  Card
} from "antd";
import { DeleteOutlined, PlusOutlined, ArrowLeftOutlined  } from "@ant-design/icons";
//other library imports
import NumberFormat from "react-number-format";
import moment from "moment";

interface Props {
  record: FirestoreDocument<Payment>;
  type: PaymentType | undefined;
}

const { Option } = Select;

const confirm = Modal.confirm;

const { TextArea } = Input;

const RentalDetails: React.FunctionComponent<Props> = ({ record, type }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const assets = useSelector(getAssetsList);
  const people = useSelector(getPeopleList);
  const payments = useSelector(getPaymentsList);
  const active_assets = useSelector(activeAssets);
  const user = useSelector((state: StoreState) => state.user);
  const { i18n, t } = useTranslation();
  const [assetRef, setAssetRef] = useState<Reference>();
  const [peopleRef, setPeopleRef] = useState<Reference>();
  const [rate, setRate] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const [rateType, setRateType] = useState<RateTypes>();
  const [startDate, setStartDate] = useState<firebase.firestore.Timestamp>();
  const [endDate, setEndDate] = useState<firebase.firestore.Timestamp>();
  const [onGoing, setOnGoing] = useState<boolean | undefined>(
    record ? undefined : false
  );
  const [disabled, setDisabled] = useState<boolean>(true);
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [error, setError] = useState({ key: "", value: false });
  const [showAddAssetModal, setShowAddAssetModal] = useState(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [desc, setDesc] = useState<string>();
  const [firstAssets, setFirstAssets] = useState<boolean>(true);
  const [firstPeople, setFirstPeople] = useState<boolean>(true);
  const [colClass, setColClass] = useState<"hidden" | "visible">();
  const [showReceipt, setShowReceipt] = useState<boolean>(false);
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [receipt, setReceipt] = useState<Receipt>();
  const[curr_rental, setCurr_Rental] = useState<Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >>()
  // const [phone_number, setPhone] = useState<string>('');
  // const [type, setType] = useState<PeopleType>()
  const [loading, setLoading] = useState<boolean>(false);

  const updateAssetStatus = () =>  {
    let end_date = endDate ? endDate : moment();
    
    if(startDate && moment(startDate.toDate()).isSameOrBefore(moment(), 'day') && end_date && moment(end_date.toDate()).isSameOrAfter(moment(), 'day')){
      for(let i=0; i < assets.length; i++){
        
        dispatch(
          updateAsset(assets[i].id, {
            status: Status.RENTED_OUT
          })
        )
      }
    }
  }

  const updPayment = (obj:Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >) =>{
    dispatch(updatePayment(record.id, obj)).then((res) => {
      let config = {
        message: i18n.t(PaymentModalTKeys.paymentUpdatedTitle),
        description: i18n.t(PaymentModalTKeys.paymentUpdatedDescription),
      };
      notification.success(config);
      setLoading(false);
      getAnalytics().track(Events.UpdatePayment, { id: record.id, obj });
      updateAssetStatus();
      setShowWarningModal(false)
    });
  }
  const calculateEndDate = (startDate, duration, rateType) => {
    let endDate;
    if (rateType === RateTypes.Daily) {
      endDate = moment(startDate.toDate()).add(duration, "days");
    } else if (rateType === RateTypes.Weekly) {
      endDate = moment(startDate.toDate()).add(duration, "weeks");
    } else if (rateType === RateTypes.Monthly) {
      endDate = moment(startDate.toDate()).add(duration, "months");
    } else if (rateType === RateTypes.Yearly) {
      endDate = moment(startDate.toDate()).add(duration, "years");
    } else if (rateType === RateTypes.One_time) {
      endDate = startDate;
    }
    return endDate;
  };

  const displayErrorMsg = () => {
    if(!assetRef){
      setError({key:PaymentModalTKeys.assetErrorMsg, value:true});
    }
    else if(!rate){
      setError({key:PaymentModalTKeys.rateErrorMsg, value:true});
    }
    else if(!rateType){
      setError({key:PaymentModalTKeys.rateTypeErrorMsg, value:true});
    }
    else if(!startDate){
      setError({key:PaymentModalTKeys.startDateErrorMsg, value:true});
    }
    else if(!duration){
      setError({key:PaymentModalTKeys.durationErrorMsg, value:true});
    }
}

  const handleOk = async (
    asset_ref = assetRef,
    people_ref = peopleRef,
    rate_type = rateType,
    start_date = startDate,
    payment_type = paymentType,
    on_going = onGoing
  ) => {
    if (
      asset_ref &&
      rate &&
      rate_type &&
      start_date &&
      duration 
    ) {
      setError({ key: "", value: false });
      let obj;
      obj = {
        ...(asset_ref && { asset_ref }),
        ...(people_ref && { people_ref}),
        type: payment_type ? payment_type : type,
        data: {
          name: "",
          rate: rate,
          rate_type,
          start_date,
          duration,
          on_going,
          ...(desc && { description: desc }),
          ...(record.doc.data.receipts && {receipts: record.doc.data.receipts})
        },
      };

      if (!onGoing) {
        let end_date = calculateEndDate(start_date, duration, rate_type);
        obj.data.end_date = firebase.firestore.Timestamp.fromDate(
          end_date.toDate()
        );
      }
      console.log(obj);
      let checkForOverlap = checkIfRentalExists(obj, payments)
      checkForOverlap = checkForOverlap.filter(payment => payment.id !== record.id)
      if (checkForOverlap.length){
        setCurr_Rental(obj)
        setShowWarningModal(true)
       }
      else{
        updPayment(obj)
      }
    } else {
      displayErrorMsg()
      // setError({ key: PaymentModalTKeys.errorMsg, value: true });
    }
  };

  const closeError = () => {
    setError({ key: "", value: false });
  };

  const disabledDate = (current) => {
    if (startDate) {
      if (rateType === RateTypes.Daily) {
        return current && current < moment(startDate.toDate());
      } else if (rateType === RateTypes.Weekly) {
        let date = moment(startDate.toDate()).add(1, "weeks");
        return current && current < moment(date);
      } else if (rateType === RateTypes.Monthly) {
        let date = moment(startDate.toDate()).add(1, "months");
        return current && current < moment(date);
      }
      // return current && current < moment(startDate.toDate());
    }
    return false;
  };

  const checkValidEndDate = () => {
    if (startDate) {
      console.log(RateTypes.One_time, rateType);
      if (rateType === RateTypes.One_time) {
        setDuration(1);
      } else {
        setDisabled(false);
        setColClass("visible");
      }
    } else {
      setDisabled(true);
      setColClass("hidden");
    }
    if (rateType === RateTypes.One_time || onGoing) {
      setDisabled(true);
      setColClass("hidden");
    }
  };

  const handleDelete = (deleteId) => {
    getAnalytics().track(Events.ClickDeletePayment, {});
    dispatch(deletePayment(deleteId));
    history.push(`/rentals`);
  };

  const showDeleteConfirm = (deleteId) => {
    confirm({
      title: i18n.t(RentalsPageTKeys.deleteRentalConfirmation),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText: i18n.t(CommonTKeys.no),
      onOk() {
        handleDelete(deleteId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    checkValidEndDate();
  }, [rateType]);

  useEffect(() => {
    checkValidEndDate();
  }, [startDate]);

  useEffect(() => {
    if (assets.length) {
      if (!firstAssets) {
        let asset = {
          id: assets[0].id,
          label: assets[0].doc.name,
        };
        console.log(asset);
        setAssetRef(asset);
      } else {
        setFirstAssets(false);
      }
    }
  }, [assets.length]);

  useEffect(() => {
    if (people.length) {
      if (!firstPeople) {
        let person = {
          id: people[0].id,
          label: people[0].doc.name,
        };
        setPeopleRef(person);
      } else {
        setFirstPeople(false);
      }
    }
  }, [people.length]);

  useEffect(() => {
    let lang = user.user.doc.language;
    i18n.changeLanguage(langAbbrMap[lang]);
    store.dispatch(changeLanguage(lang as Language));
    dispatch(setActivePayment(record.id));
    dispatch(setActivePaymentType(record.doc.type))

    if (assets.length === 0) {
      dispatch(loadAssets());
    }
    if (people.length === 0) {
      dispatch(loadPeople());
    }

    if (record) {
      if (!assetRef && record.doc.asset_ref) {
        setAssetRef(record.doc.asset_ref);
      }
      if (!peopleRef && record.doc.people_ref) {
        setPeopleRef(record.doc.people_ref);
      }
      if (!rate) {
        setRate(record.doc.data.rate);
      }
      if (!rateType) {
        setRateType(record.doc.data.rate_type);
      }
      if (!startDate) {
        setStartDate(record.doc.data.start_date);
      }
      if (!endDate && record.doc.data.end_date) {
        setEndDate(record.doc.data.end_date);
      }
      if (!duration && record.doc.data.duration) {
        setDuration(record.doc.data.duration);
      }
      if (onGoing === undefined) {
        setOnGoing(record.doc.data.on_going);
      }
      if (!paymentType) {
        setPaymentType(record.doc.type);
      }
      if (!desc && record.doc.data.description) {
        setDesc(record.doc.data.description);
      }
    }
  }, []);

  return (
    <div className="rental-details">
      {curr_rental && showWarningModal?
      <RentalWarningModal
        visible={showWarningModal}
        closeModal={()=>setShowWarningModal(false)}
        handleOk={() => updPayment(curr_rental)}
      />
      :
      null
      }
      {showAddAssetModal ? (
        <AddAssetModal
          visible={showAddAssetModal}
          closeModal={() => {
            setShowAddAssetModal(false);
          }}
        />
      ) : null}

      {showAddPersonModal ? (
        <AddContactModal
          visible={showAddPersonModal}
          closeModal={() => {
            setShowAddPersonModal(false);
          }}
          record={undefined}
        />
      ) : null}
      <Row justify="start" className="heading">
        <Col span={24}>
        { !showReceipt ?
          <BreadCrumb
            link="/rentals"
            heading={i18n.t(RentalsPageTKeys.allRentals)}
            name={record.id}
          />
          :
          <a onClick={() => setShowReceipt(false)} className="back">
            <ArrowLeftOutlined /> Back to rental details
          </a>
        }
        </Col>
      </Row>
      <Row justify="end" gutter={[0, 15]}>
        <Col span={24}>
          <Card title={i18n.t(PaymentModalTKeys.rentalDetailsHeading)}>
            <Form
              layout="vertical"
              className="form"
              // labelCol={{ span: 5 }}
              labelAlign="left"
            >
              <Row gutter={[15, 0]}>
                <Col span={24}>
                  {error.value ? (
                    <Alert
                      className="errMsg"
                      message={i18n.t(error.key)}
                      type="error"
                      closable
                      onClose={() => closeError()}
                    />
                  ) : null}
                </Col>

                <Col span={12}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.assetLabel)}>
                    <Select
                      showSearch
                      className="input-item"
                      placeholder={i18n.t(PaymentModalTKeys.selectAsset)}
                      value={assetRef ? assetRef.label : undefined}
                      onChange={(value, e) => {
                        let keys = Object.keys(e);
                        console.log(e[keys[0]], e[keys[1]]);
                        let obj = {
                          id: e[keys[0]],
                          label: e[keys[1]],
                        };
                        setAssetRef(obj);
                        handleOk(obj);
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            {/* <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} /> */}
                            <a
                              style={{
                                flex: "none",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowAddAssetModal(true);
                                getAnalytics().track(
                                  Events.ClickNewEquipment,
                                  {}
                                );
                              }}
                            >
                              <PlusOutlined />{" "}
                              {i18n.t(PaymentModalTKeys.addAsset)}
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {assets.map((asset) => {
                        return (
                          <Option key={asset.id} value={asset.doc.name}>
                            {asset.doc.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.personLabel)}>
                    <Select
                      showSearch
                      className="input-item"
                      placeholder={i18n.t(PaymentModalTKeys.selectPerson)}
                      value={peopleRef ? peopleRef.label : undefined}
                      onChange={(value, e) => {
                        let keys = Object.keys(e);
                        console.log(e[keys[0]], e[keys[1]]);
                        let obj = {
                          id: e[keys[0]],
                          label: e[keys[1]],
                        };
                        setPeopleRef(obj);
                        handleOk(assetRef, obj);
                      }}
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              padding: 8,
                            }}
                          >
                            {/* <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} /> */}
                            <a
                              style={{
                                flex: "none",
                                display: "block",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowAddPersonModal(true);
                                getAnalytics().track(
                                  Events.ClickAddNewContact,
                                  {}
                                );
                              }}
                            >
                              <PlusOutlined />{" "}
                              {i18n.t(PaymentModalTKeys.addPerson)}
                            </a>
                          </div>
                        </div>
                      )}
                    >
                      {people.map((person) => {
                        return (
                          <Option key={person.id} value={person.doc.name}>
                            {person.doc.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.descriptionLabel)}>
                    <TextArea
                      name="description"
                      value={desc}
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={i18n.t(
                        PaymentModalTKeys.descriptionPlaceholder
                      )}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setDesc(e.target.value);
                      }}
                      onBlur={() => {
                        handleOk();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.rateLabel)}>
                    <NumberFormat
                      className="input-item rate"
                      thousandSeparator={true}
                      placeholder={i18n.t(PaymentModalTKeys.ratePlaceholder)}
                      value={rate}
                      onValueChange={(values) => {
                        const { value } = values;
                        if (value && value.length) {
                          console.log("coming here", value);
                          setRate(parseInt(value));
                        }
                      }}
                      onBlur={() => {
                        handleOk();
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.rateTypeLabel)}>
                    <Select
                      showSearch
                      className="input-item"
                      placeholder={i18n.t(
                        PaymentModalTKeys.rateTypePlaceholder
                      )}
                      value={rateType}
                      onChange={(value: RateTypes) => {
                        setRateType(value);
                        handleOk(assetRef, peopleRef, value);
                      }}
                    >
                      <Option value={RateTypes.One_time}>
                        {i18n.t(PaymentModalTKeys.oneTimeRate)}
                      </Option>
                      <Option value={RateTypes.Monthly}>
                        {i18n.t(PaymentModalTKeys.monthlyRate)}
                      </Option>
                      <Option value={RateTypes.Daily}>
                        {i18n.t(PaymentModalTKeys.dailyRate)}
                      </Option>
                      <Option value={RateTypes.Weekly}>
                        {i18n.t(PaymentModalTKeys.weeklyRate)}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} className="date-picker">
                  <Form.Item label={i18n.t(PaymentModalTKeys.startDateLabel)}>
                    <DatePicker
                      className="input-item"
                      value={startDate ? moment(startDate.toDate()) : undefined}
                      format={"DD/MM/YYYY"}
                      onChange={(date, dateString) => {
                        let start_Date = date
                          ? firebase.firestore.Timestamp.fromDate(date.toDate())
                          : null;
                        if (start_Date) {
                          setStartDate(start_Date);
                          handleOk(assetRef, peopleRef, rateType, start_Date);
                        }
                      }}
                      placeholder={i18n.t(
                        PaymentModalTKeys.startDatePlaceholder
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} className={`date-picker ${colClass}`}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.durationLabel)}>
                    <Input
                      type="number"
                      disabled={disabled}
                      value={duration}
                      onChange={(e) => {
                        if (e.target.value) {
                          setDuration(parseInt(e.target.value));
                        } else {
                          setDuration(undefined);
                        }
                      }}
                      onBlur={(e) => {
                        handleOk();
                      }}
                      placeholder={i18n.t(
                        PaymentModalTKeys.durationPlaceholder
                      )}
                    />
                  </Form.Item>
                </Col>
                {record ? (
                  <Col span={12}>
                    <Form.Item
                      label={i18n.t(PaymentModalTKeys.paymentTypeLabel)}
                    >
                      <Select
                        showSearch
                        className="input-item"
                        placeholder={i18n.t(
                          PaymentModalTKeys.paymentTypePlaceholder
                        )}
                        value={paymentType}
                        disabled
                        // onChange={(value: PaymentType) => {
                        //   setPaymentType(value);
                        //   handleOk(assetRef, peopleRef, rateType, startDate, endDate, value)
                        // }}
                      >
                        <Option value={PaymentType.Expense}>
                          {i18n.t(PaymentModalTKeys.expenseOption)}
                        </Option>
                        <Option value={PaymentType.Income}>
                          {i18n.t(PaymentModalTKeys.incomeOption)}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                ) : null}
                <Col
                  span={12}
                  className={
                    rateType === RateTypes.One_time ? "hidden" : "visible"
                  }
                >
                  <Form.Item label={" "}>
                    <Checkbox
                      disabled={rateType === RateTypes.One_time ? true : false}
                      checked={onGoing}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDisabled(true);
                          setColClass("hidden");
                        } else {
                          setDisabled(false);
                          setColClass("visible");
                        }
                        setOnGoing(e.target.checked);
                        console.log(e.target.checked);
                        handleOk(
                          assetRef,
                          peopleRef,
                          rateType,
                          startDate,
                          paymentType,
                          e.target.checked
                        );
                      }}
                    >
                      {i18n.t(PaymentModalTKeys.onGoingDesc)}
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col span={24}>
           <ReceiptHistory personId={record.doc.people_ref?.id} rentalId={record.id} receipts={record.doc.data.receipts? record.doc.data.receipts: []} showReceipt={() => setShowReceipt(true)} currentReceipt={(record) => setReceipt(record)}/>
        </Col>
        <Col span={8}>
          <Button
            icon={<DeleteOutlined />}
            danger
            block
            type="primary"
            onClick={() => {
              showDeleteConfirm(record ? record.id : "");
            }}
          >
            {i18n.t(PaymentModalTKeys.deleteRental)}
          </Button>
        </Col>
      </Row>
      
    </div>
  );
};

export default RentalDetails;
