import { Payment, PaymentType, RateTypes } from "@hermes/schema";
//@ts-ignore
import numeral from 'numeral';
//@ts-ignore
import moment from 'moment'

export interface UseTemplateForm {
    templateType: "income" | "expense" | "both"
    payments: Payment[],
    columnTypeText: string,
    columnAssetText: string,
    personColumnText: string,
    descriptionColumnText: string
    startColumnText: string,
    endColumnText: string,
    rateTypeColumnText: string,
    rateColumnText: string,
    totalColumnText: string,
    totalIncomeText: string,
    totalExpensesText: string,
    grandTotalText: string,
    incomeText: string,
    expenseText: string,
    lang: string,
    durationText: string,
    ongoingText: string,
    dailyText: string,
    monthlyText: string,
    yearlyText: string,
    oneText: string,
    weeklyText: string,
    onTimeText: string,
    dayText: string,
    daysText: string,
    monthText: string,
    monthsText: string,
    weekText: string,
    weeksText: string,
    yearText: string,
    yearsText: string,
    amountEarned: number,
    amountSpent: number,
}
export const useTemplate = (form: UseTemplateForm) => {

const durationMap = (type: string, duration: number) => {
    if (type === RateTypes.One_time) return form.onTimeText;
    if (type === RateTypes.Daily) {
        if (duration.toString() == "1" || duration.toString() == "") {
            return form.dayText;
        }
        else {
            return form.daysText;
        }
    }
    if (type === RateTypes.Weekly) {
        if (duration.toString() == "1" || duration.toString() == "") {
            return form.weekText;
        }
        else {
            return form.weeksText;
        }
    }
    if (type === RateTypes.Monthly) {
        if (duration.toString() == "1" || duration.toString() == "") {
            return form.monthText;
        }
        else {
            return form.monthsText;
        }
    };
    if (type === RateTypes.Yearly) {
        if (duration.toString() == "1" || duration.toString() == "") {
            return form.yearText;
        }
        else {
            return form.yearsText;
        }
    }
};
const dateConv = (d: any) => {
    if (form.lang === "ar" || form.lang === "ur") {
        const day = d.format("Do,").split(" ").reverse().join(" ");
        const year = d.format("YYYY,").split(" ").reverse().join(" ");
        const month = d.format("MMM").split(" ").reverse().join(" ");
        return year+" "+day+" "+month;
    }
    else {
        return d.format("MMM Do, YYYY");
    }
};
const numberConv = (num: string) => {
    if (form.lang === "ar") {
        return num.toArabic().split("").reverse().join("");
    }
    else if (form.lang === "hi") {
        return num.toHindi();
    }
    else if (form.lang === "ur") {
        return num.toUrdu().split("").reverse().join("");
    }
    else if (form.lang === "en" || form.lang === "id") {
        return num;
    }
};
const RateTypeMap = {
    "Daily": form.dailyText,
    "Monthly": form.monthlyText,
    "Yearly": form.yearlyText,
    "One time": form.oneText,
    "One_time": form.oneText,
    "Weekly": form.weeklyText,
};
const directionMap: {[lang: string]: string} = {
    en: "left",
    hi: "left",
    ur: "right",
    ar: "right",
    id: "left"
};

const paymentsMap = form.payments.map((v) => {
    return {
        ...v,
        netTotal: v.data.rate * v.data.duration,
        incomeRate: v.type === PaymentType.Income? v.data.rate.toString(): "",
        incomeDuration: v.type === PaymentType.Income? v.data.duration.toString(): "",
        expenseRate: v.type === PaymentType.Expense? v.data.rate.toString(): "",
        expenseDuration: v.type === PaymentType.Expense? v.data.duration.toString(): "",
    }
});

const mapFunction = (a: any) => {
    if (form.templateType == "expense") {
         return [
             a.type === "income" ? form.incomeText : form.expenseText,
             a.asset_ref? a.asset_ref.label: "",
             a.people_ref? a.people_ref.label: "",
             a.data.description? a.data.description: "",
             dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
             a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
             RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
             a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
             numberConv(numeral(a.data.rate).format("0,0")),
             a.expenseRate.toString() == "" || a.expenseDuration == ""? "": numberConv(numeral(parseInt(a.expenseRate) * parseInt(a.expenseDuration)).format("0,0")),
         ]
     }
     else if (form.templateType == "income") {
         return [
             a.type === "income" ? form.incomeText : form.expenseText,
             a.asset_ref? a.asset_ref.label: "",
             a.people_ref? a.people_ref.label: "",
             a.data.description? a.data.description: "",
             dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
             a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
             RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
             a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
             numberConv(numeral(a.data.rate).format("0,0")),
             a.incomeRate.toString() == "" || a.incomeDuration.toString() == ""? "" : numberConv(numeral(parseInt(a.incomeRate) * parseInt(a.incomeDuration)).format("0,0")),
         ]
     }
     else {
         return [
             a.type === "income" ? form.incomeText : form.expenseText,
             a.asset_ref? a.asset_ref.label: "",
             a.people_ref? a.people_ref.label: "",
             a.data.description? a.data.description: "",
             dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
             a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
             RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
             a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
             numberConv(numeral(a.data.rate).format("0,0")),
             a.incomeRate.toString() == "" || a.incomeDuration.toString() == ""? "" : numberConv(numeral(parseInt(a.incomeRate) * parseInt(a.incomeDuration)).format("0,0")),
             a.expenseRate.toString() == "" || a.expenseDuration == ""? "": numberConv(numeral(parseInt(a.expenseRate) * parseInt(a.expenseDuration)).format("0,0")),
         ]
     }
 };


const firstArrayPart = () => {
    if (form.templateType == "income") {
        return [
            {
                text: `${form.columnTypeText}`,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.columnAssetText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.personColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.descriptionColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: `${form.startColumnText}`,
                fillColor: "#eee",
                color: "#000",
                alignment: directionMap[form.lang],
                bold: true,
            },
            {
                text: form.endColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.rateTypeColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.durationText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.rateColumnText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.totalIncomeText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
        ]
    } else if (form.templateType == "expense") {
        return [
            {
                text: `${form.columnTypeText}`,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.columnAssetText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.personColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.descriptionColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: `${form.startColumnText}`,
                fillColor: "#eee",
                color: "#000",
                alignment: directionMap[form.lang],
                bold: true,
            },
            {
                text: form.endColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.rateTypeColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.durationText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.rateColumnText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.totalExpensesText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
        ]
    } else {
        return [
            {
                text: `${form.columnTypeText}`,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.columnAssetText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.personColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.descriptionColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: `${form.startColumnText}`,
                fillColor: "#eee",
                color: "#000",
                alignment: directionMap[form.lang],
                bold: true,
            },
            {
                text: form.endColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.rateTypeColumnText,
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                color: "#000",
                bold: true,
            },
            {
                text: form.durationText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.rateColumnText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.totalIncomeText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
            {
                text: form.totalExpensesText,
                alignment: directionMap[form.lang],
                fillColor: "#eee",
                color: "#000",
                bold: true,
            },
        ]
    }
};

const secondPartArray = () => {
    if (form.templateType == "income") {
        return [
            { text: "", colSpan: 7 },
            "",
            "",
            "",
            "",
            "",
            "",
            {
                text: form.grandTotalText,
                color: "#000",
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                colSpan: 1,
            },
            {
                text: numberConv(numeral(form.amountEarned).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
            {
                text: numberConv(numeral(form.amountSpent).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
        ]
    }
    else if (form.templateType == "expense") {
        return [
            { text: "", colSpan: 7 },
            "",
            "",
            "",
            "",
            "",
            "",
            {
                text: form.grandTotalText,
                color: "#000",
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                colSpan: 1,
            },
            {
                text: numberConv(numeral(form.amountEarned).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
            {
                text: numberConv(numeral(form.amountSpent).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
        ]
    }
    else {
        return [
            { text: "", colSpan: 8 },
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            {
                text: form.grandTotalText,
                color: "#000",
                fillColor: "#eee",
                alignment: directionMap[form.lang],
                colSpan: 1,
            },
            {
                text: numberConv(numeral(form.amountEarned).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
            {
                text: numberConv(numeral(form.amountSpent).format("0,0")), 
                alignment: directionMap[form.lang],
                colSpan: 1,
                width: "auto"
            },
        ]
    }
};

const defaultWidth = [40, 80, 90, "auto", 70, 70, 70, 40, 'auto', 'auto', 'auto'];


if (form.templateType == "income") {
    //console.log({width: defaultWidth.slice(0, defaultWidth.length - 1)}),
    //console.log({body});
    return {
       width: defaultWidth.slice(0, defaultWidth.length - 1),
       body: [
           firstArrayPart(),
           ...paymentsMap.map((a) => [
            a.type === "income" ? form.incomeText : form.expenseText,
            a.asset_ref? a.asset_ref.label: "",
            a.people_ref? a.people_ref.label: "",
            a.data.description? a.data.description: "",
            dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
            a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
            RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
            a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
            numberConv(numeral(a.data.rate).format("0,0")),
            a.incomeRate.toString() == "" || a.incomeDuration == ""? "": numberConv(numeral(parseInt(a.incomeRate) * parseInt(a.incomeDuration)).format("0,0")),
        ]),
           secondPartArray(),
       ],
    }
} else if (form.templateType == "expense") {
    return {
        width: defaultWidth.slice(0, defaultWidth.length - 1),
        body: [
            firstArrayPart(),
            ...paymentsMap.map((a) => [
                a.type === "income" ? form.incomeText : form.expenseText,
                a.asset_ref? a.asset_ref.label: "",
                a.people_ref? a.people_ref.label: "",
                a.data.description? a.data.description: "",
                dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
                a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
                RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
                a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
                numberConv(numeral(a.data.rate).format("0,0")),
                a.expenseRate.toString() == "" || a.expenseDuration == ""? "": numberConv(numeral(parseInt(a.expenseRate) * parseInt(a.expenseDuration)).format("0,0")),
            ]),
            secondPartArray(),
        ],
     }
} else {
    return {
        width: defaultWidth,
        body: [
            firstArrayPart(),
            ...paymentsMap.map((a) => [
                a.type === "income" ? form.incomeText : form.expenseText,
                a.asset_ref? a.asset_ref.label: "",
                a.people_ref? a.people_ref.label: "",
                a.data.description? a.data.description: "",
                dateConv(moment(a.data.start_date.toDate()).locale(form.lang)),
                a.data.end_date? dateConv(moment(a.data.end_date.toDate()).locale(form.lang)): form.ongoingText,
                RateTypeMap[a.data.rate_type]? RateTypeMap[a.data.rate_type]: "",
                a.data.rate_type === RateTypes.One_time ? RateTypeMap[a.data.rate_type]: a.data.duration + " " + durationMap(a.data.rate_type, a.data.duration),
                numberConv(numeral(a.data.rate).format("0,0")),
                a.incomeRate.toString() == "" || a.incomeDuration == ""? "": numberConv(numeral(parseInt(a.incomeRate) * parseInt(a.incomeDuration)).format("0,0")),
                a.expenseRate.toString() == "" || a.expenseDuration == ""? "": numberConv(numeral(parseInt(a.expenseRate) * parseInt(a.expenseDuration)).format("0,0")),
            ]),
            secondPartArray(),
        ],
     }
}

};