import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as firebase from 'firebase';
//imported components within webapp
import './AddRentalModal.css';
import AddAssetModal from "../AddAssetModal/AddAssetModal";
import AddContactModal from "../AddContactModal/AddContactModal";
import RentalWarningModal from "../RentalWarningModal/RentalWarningModal";
import { PaymentModalTKeys, CommonTKeys } from '../../translations/keys';
import { useTranslation } from "../../translations/hooks";
import { store } from '../../clients/store';
import { getAnalytics, Events } from "../../clients/analytics";
//shared folder imports
import { loadAssets, updateAsset } from '@hermes/shared/src/redux/assets/actions';
import { loadPeople } from '@hermes/shared/src/redux/people/actions';
import { updatePayment, addPayment } from '@hermes/shared/src/redux/payments/actions';
import { getPeopleList } from '@hermes/shared/src/redux/people/selector';
import { getAssetsList } from '@hermes/shared/src/redux/assets/selectors';
import { getPaymentsList } from '@hermes/shared/src/redux/payments/selector';
import { checkIfRentalExists } from "@hermes/shared/src/business/functions/checkForOverlap";
import {
  activeAssets
} from "@hermes/shared/src/redux/payments/selector";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { changeLanguage } from '@hermes/shared/src/redux/user/actions';
//antd imports
import { Modal, Form, Row, Col, Input, Select, notification, DatePicker, Checkbox, Alert, InputNumber, Divider} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Payment, FirestoreDocument, PaymentType, Reference, RateTypes, Language, langAbbrMap, Status, Assets } from "@hermes/schema";
//other library imports
import NumberFormat from 'react-number-format';
import moment from 'moment';

interface ModalProps {
    visible: boolean,
    closeModal: () => void,
    record?: FirestoreDocument<Payment>,
    type?: PaymentType,
    asset?: Reference,
    person?: Reference
}

const { Option } = Select;

const { TextArea } = Input;

const AddRentalModal: React.FunctionComponent<ModalProps> = ({ visible, closeModal, record, type, asset, person }) => {
    const dispatch = useDispatch();
    const assets = useSelector(getAssetsList);
    const people = useSelector(getPeopleList);
    const payments = useSelector(getPaymentsList);
    const active_assets = useSelector(activeAssets);
    const user =  useSelector((state: StoreState) => state.user);
    const {i18n, t} = useTranslation();

    const [assetRef, setAssetRef] = useState<Reference|undefined>(asset);
    const [peopleRef, setPeopleRef] = useState<Reference|undefined>(person);
    const [rate, setRate] = useState<number>();
    const [duration, setDuration] = useState<number>(1);
    const [rateType, setRateType] = useState<RateTypes>();
    const [startDate, setStartDate] = useState<firebase.firestore.Timestamp>();
    const [endDate, setEndDate] = useState();
    const [onGoing, setOnGoing] = useState<boolean | undefined>(record ? undefined : false);
    const [disabled, setDisabled] = useState<boolean>(true)
    const [paymentType, setPaymentType] = useState<PaymentType>()
    const [error, setError] = useState({ key:'', value:false});
    const [showAddAssetModal, setShowAddAssetModal] = useState<boolean>(false);
    const [showAddPersonModal, setShowAddPersonModal] = useState<boolean>(false);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
    const [desc, setDesc] = useState<string>();
    const [firstAssets, setFirstAssets] = useState<boolean>(true);
    const [firstPeople, setFirstPeople] = useState<boolean>(true);
    const [colClass, setColClass]= useState<'hidden'|'visible'>();
    const[curr_rental, setCurr_Rental] = useState<Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >>()
    // const [phone_number, setPhone] = useState<string>('');
    // const [type, setType] = useState<PeopleType>()
    const [loading, setLoading] = useState<boolean>(false)

    const updateAssetStatus = () =>  {
      let end_date = endDate ? endDate : moment();
      if(startDate && moment(startDate.toDate()).isSameOrBefore(moment(), 'day') && end_date &&  moment(end_date.toDate()).isSameOrAfter(moment(), 'day')){
        for(let i=0; i < assets.length; i++){
          
          dispatch(
            updateAsset(assets[i].id, {
              status: Status.RENTED_OUT
            })
          )
        }
      }
    }

    const handleDurationChange = (value: string) => {
      if (value == "" || value == " " || value == "0" || parseInt(value) < 1) {
        setError({key:PaymentModalTKeys.durationEmptyMessage, value:true});
        setDuration(1);
        return false;
      } else if ((rateType === RateTypes.Daily) && parseInt(value) > 1000) {
          setError({key:PaymentModalTKeys.dailyDurationErrorMessage, value:true});
          setDuration(1000);
          return false;
      }
      else if ((rateType === RateTypes.Monthly) && parseInt(value) > 50) {
          setError({key:PaymentModalTKeys.monthlyDurationErrorMessage, value:true});
          setDuration(50);
          return false;
      }
      else if ((rateType === RateTypes.Yearly) && parseInt(value) > 20) {
          setError({key:PaymentModalTKeys.yearlyDurationErrorMessage, value:true});
          setDuration(20);
          return false;
      }
      setDuration(Number(value));
      return true;
    };

    const handleRateChange = (value: string) => {
      if (value.toString() == "" || value.toString() == " " || value.toString() == "0" || parseInt(value) < 1) {
        setError({key:PaymentModalTKeys.amountZeroError, value:true});
        return false;
      }
      else {
        setRate(parseInt(value));
        return true;
      }

    };

    const addOrUpdatePayment = (obj:Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" > ) => {
      if (record) {
                dispatch(updatePayment(record.id, obj)).then((res) => {
                    let config = {
                        message: i18n.t(PaymentModalTKeys.paymentUpdatedTitle),
                        description: i18n.t(PaymentModalTKeys.paymentUpdatedDescription),
                    }
                    notification.success(config)
                    setLoading(false)
                    closeModal()
                });
                getAnalytics().track(Events.UpdatePayment, { id: record.id, obj });
                updateAssetStatus()
            }
            else {
                dispatch(addPayment(obj)).then((res) => {
                    let config = {
                        message: i18n.t(PaymentModalTKeys.paymentAddedTitle),
                        description: i18n.t(PaymentModalTKeys.paymentAddedDescription),
                    }
                    let audio = new Audio("/assets/bell.wav");
                    audio.play()
                    notification.success(config)
                    setLoading(false)
                    closeModal()
                    
                });
                getAnalytics().track(Events.ClickSavePayment, {obj});
                updateAssetStatus()
            }
    }

    const calculateEndDate = (startDate, duration, rateType) => {
      let endDate;
      if(rateType === RateTypes.Daily){
        endDate = moment(startDate.toDate()).add(duration, 'days');
      }
      else if(rateType === RateTypes.Weekly){
        endDate = moment(startDate.toDate()).add(duration, 'weeks');
      }
      else if(rateType === RateTypes.Monthly){
        endDate = moment(startDate.toDate()).add(duration, 'months');
      }
      else if(rateType === RateTypes.Yearly){
        endDate = moment(startDate.toDate()).add(duration, 'years');
      }
      else if(rateType === RateTypes.One_time){
        endDate = startDate
      }
      setEndDate(endDate)
      return endDate
    }

    const displayErrorMsg = () => {
        if(!assetRef){
          setError({key:PaymentModalTKeys.assetErrorMsg, value:true});
        }
        else if(!rate){
          setError({key:PaymentModalTKeys.rateErrorMsg, value:true});
        }
        else if(!rateType){
          setError({key:PaymentModalTKeys.rateTypeErrorMsg, value:true});
        }
        else if(!startDate){
          setError({key:PaymentModalTKeys.startDateErrorMsg, value:true});
        }
        else if(!duration){
          setError({key:PaymentModalTKeys.durationErrorMsg, value:true});
        }
    }

    const handleOk = async () => {
        if (assetRef && rate && rateType && startDate && duration) {
            let obj:Pick<Payment, "asset_ref" | "people_ref" | "type" | "data" >;
            obj = {
                ...assetRef && { asset_ref: assetRef },
                ...peopleRef && { people_ref: peopleRef },
                type: paymentType ? paymentType : type ? type : PaymentType.Income,
                data: {
                    rate: rate,
                    rate_type: rateType,
                    start_date: startDate,
                    on_going: onGoing? onGoing: false,
                    ...desc && { description : desc},
                    duration
                }
            }

            if (!onGoing) { 
              let end_date = calculateEndDate(startDate,duration,rateType);
              obj.data.end_date = firebase.firestore.Timestamp.fromDate(end_date.toDate()) 
            }
            let checkForOverlap = checkIfRentalExists(obj, payments)
            if(record){
              checkForOverlap = checkForOverlap.filter(payment => payment.id !== record.id)
            }
            if (checkForOverlap.length){
              setCurr_Rental(obj)
              setShowWarningModal(true)
            }
            else{
              addOrUpdatePayment(obj)
            }

        }
        else {
            displayErrorMsg()
        }


    }

    const closeError = () => {
        setError({key:'', value:false});
    }

    const checkValidEndDate = () => {
      if(startDate){
        if(rateType === RateTypes.One_time){
          setDuration(1)
        }
        else{
          setDisabled(false);
          setColClass('visible');
        }
       
      }
      else{
        setDisabled(true);
        setColClass('hidden');
      }
      if(rateType === RateTypes.One_time || onGoing ){
        setDisabled(true);
        setColClass('hidden');
      }

    }

    useEffect(() => {
      checkValidEndDate()
    }, [rateType, startDate]);

    useEffect(() => {
      if(assets.length){
        if(!firstAssets){
          let asset = {
            id: assets[0].id,
            label: assets[0].doc.name
          }
          setAssetRef(asset);
        }
        else{
          setFirstAssets(false)
        }
      }
     
    }, [assets.length]);

    useEffect(() => {
      if(people.length){
        if(!firstPeople){
          let person = {
            id: people[0].id,
            label: people[0].doc.name
          }
          setPeopleRef(person)
        }
        else{
          setFirstPeople(false)
        }
      }
     
    }, [people.length]);

    useEffect(() => {
        let lang = user.user.doc.language
        i18n.changeLanguage(langAbbrMap[lang]);
        store.dispatch(changeLanguage(lang as Language));  

        if (assets.length === 0) {
            dispatch(loadAssets());
        }
        if (people.length === 0) {
            dispatch(loadPeople());
        }

        if (record) {
            if (!assetRef && record.doc.asset_ref) {
                setAssetRef(record.doc.asset_ref)
            }
            if (!peopleRef && record.doc.people_ref) {
                setPeopleRef(record.doc.people_ref)
            }
            if (!rate) {
                setRate(record.doc.data.rate)
            }
            if (!rateType) {
                setRateType(record.doc.data.rate_type)
            }
            if (!startDate) {
                setStartDate(record.doc.data.start_date)

            }
            if (onGoing === undefined) {
                setOnGoing(record.doc.data.on_going)
            }
            if (!paymentType) {
                setPaymentType(record.doc.type)
            }
            if (!duration) {
              setDuration(record.doc.data.duration)
            }
            if (!desc && record.doc.data.description) {
              setDesc(record.doc.data.description)
            }
        }

    }, []);


    return (
      <>
      {curr_rental && showWarningModal?
      <RentalWarningModal
        visible={showWarningModal}
        closeModal={()=>setShowWarningModal(false)}
        handleOk={() =>  addOrUpdatePayment(curr_rental)}
      />
      :
      null
      }
      {showAddAssetModal ? (
        <AddAssetModal
          visible={showAddAssetModal}
          closeModal={() => {
            setShowAddAssetModal(false);
          }}
        />
      ) : null}

      {showAddPersonModal ? (
        <AddContactModal
        visible={showAddPersonModal}
        closeModal={() => {
          setShowAddPersonModal(false);
        }}
        record={undefined}
      />
      ) : null}

        <Modal
          closable={false}
          maskClosable={false}
          className="add-rental-modal"
          title={
            record
              ? i18n.t(PaymentModalTKeys.editPaymentHeading)
              : i18n.t(PaymentModalTKeys.addPaymentHeading)
          }
          visible={visible}
          onOk={handleOk}
          onCancel={closeModal}
          okText={
            record
              ? i18n.t(PaymentModalTKeys.editOkText)
              : i18n.t(PaymentModalTKeys.addPaymentHeading)
          }
          okButtonProps={{ loading: loading }}
          cancelText={i18n.t(CommonTKeys.cancel)}
        >
          <Form
            layout="vertical"
            className="form"
            // labelCol={{ span: 5 }}
            labelAlign="left"
          >
            <Row gutter={[10, 10]}>
              {error.value ? (
                <Alert
                  className="errMsg"
                  message={i18n.t(error.key)}
                  type="error"
                  closable
                  onClose={() => closeError()}
                />
              ) : null}
              {!asset?
              <Col span={12}>
                <Form.Item label={i18n.t(PaymentModalTKeys.assetLabel)}>
                  <Select
                    showSearch
                    className="input-item"
                    placeholder={i18n.t(PaymentModalTKeys.selectAsset)}
                    value={assetRef ? assetRef.label : undefined}
                    onChange={(value, e) => {
                      let keys = Object.keys(e);
                      let obj = {
                        id: e[keys[0]],
                        label: e[keys[1]],
                      };
                      setAssetRef(obj);
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          {/* <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} /> */}
                          <a
                            style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                            onClick={() => setShowAddAssetModal(true)}
                          >
                            <PlusOutlined /> {i18n.t(PaymentModalTKeys.addAsset)}
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {assets.map((asset) => {
                      return (
                        <Option key={asset.id} value={asset.doc.name}>
                          {asset.doc.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              :
              null}
              {!person?
              <Col span={12}>
                <Form.Item label={i18n.t(PaymentModalTKeys.personLabel)}>
                  <Select
                    showSearch
                    className="input-item"
                    placeholder={i18n.t(PaymentModalTKeys.selectPerson)}
                    value={peopleRef ? peopleRef.label : undefined}
                    onChange={(value, e) => {
                      let keys = Object.keys(e);
                      let obj = {
                        id: e[keys[0]],
                        label: e[keys[1]],
                      };
                      setPeopleRef(obj);
                    }}
                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          {/* <Input style={{ flex: 'auto' }} value={name} onChange={this.onNameChange} /> */}
                          <a
                            style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                            onClick={() => setShowAddPersonModal(true)}
                          >
                            <PlusOutlined /> {i18n.t(PaymentModalTKeys.addPerson)}
                          </a>
                        </div>
                      </div>
                    )}
                  >
                    {people.map((person) => {
                      return (
                        <Option key={person.id} value={person.doc.name}>
                          {person.doc.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              :null}
              <Col span={24}>
                <Form.Item
                  label={i18n.t(PaymentModalTKeys.descriptionLabel)}
                >
                  <TextArea
                    name="description"
                    value={desc}
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    placeholder={i18n.t(
                      PaymentModalTKeys.descriptionPlaceholder
                    )}
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={i18n.t(PaymentModalTKeys.rateLabel)}>
                  <NumberFormat 
                    className="input-item rate"
                    thousandSeparator={true} 
                    placeholder={i18n.t(PaymentModalTKeys.ratePlaceholder)}
                    defaultValue={rate}
                    onValueChange={(values) => {
                      const { value } = values;
                      handleRateChange(value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={i18n.t(PaymentModalTKeys.rateTypeLabel)}>
                  <Select
                    showSearch
                    className="input-item"
                    placeholder={i18n.t(PaymentModalTKeys.rateTypePlaceholder)}
                    value={rateType}
                    onChange={(value: RateTypes) => {
                      setRateType(value);
                    }}
                  >
                    <Option value={RateTypes.One_time}>{i18n.t(PaymentModalTKeys.oneTimeRate)}</Option>
                    <Option value={RateTypes.Monthly}>{i18n.t(PaymentModalTKeys.monthlyRate)}</Option>
                    <Option value={RateTypes.Daily}>{i18n.t(PaymentModalTKeys.dailyRate)}</Option>
                    <Option value={RateTypes.Weekly}>{i18n.t(PaymentModalTKeys.weeklyRate)}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} className="date-picker">
                <Form.Item label={i18n.t(PaymentModalTKeys.startDateLabel)}>
                  <DatePicker
                    className="input-item"
                    value={startDate ? moment(startDate.toDate()) : undefined}
                    format={"DD/MM/YYYY"}
                    onChange={(date, dateString) => {
                      let start_Date = date
                        ? firebase.firestore.Timestamp.fromDate(date.toDate())
                        : null;
                      if (start_Date) {
                        setStartDate(start_Date);
                      }
                    }}
                    placeholder={i18n.t(PaymentModalTKeys.startDatePlaceholder)}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className={`date-picker ${colClass}`}>
                <Form.Item label={i18n.t(PaymentModalTKeys.durationLabel)}>
                  <Input
                    type="number"
                    disabled={disabled}
                    value={duration? duration: 1}
                    onChange={(e) => {
                      handleDurationChange(e.target.value);
                    }}
                    placeholder={i18n.t(PaymentModalTKeys.durationPlaceholder)}
                  />
                  {/* <DatePicker
                    className="input-item"
                    disabled={disabled}
                    disabledDate={disabledDate}
                    format={"DD/MM/YYYY"}
                    value={endDate ? moment(endDate.toDate()) : undefined}
                    onChange={(date, dateString) => {
                      let end_Date = date
                        ? firebase.firestore.Timestamp.fromDate(date.toDate())
                        : null;
                      if (end_Date) {
                        setEndDate(end_Date);
                      }
                    }}
                    placeholder={i18n.t(PaymentModalTKeys.endDatePlaceholder)}
                  /> */}
                </Form.Item>
              </Col>
              {/* {record ? (
                <Col span={24}>
                  <Form.Item label={i18n.t(PaymentModalTKeys.paymentTypeLabel)}>
                    <Select
                      showSearch
                      className="input-item"
                      placeholder={i18n.t(
                        PaymentModalTKeys.paymentTypePlaceholder
                      )}
                      value={paymentType}
                      onChange={(value: PaymentType) => {
                        setPaymentType(paymentType);
                      }}
                    >
                      <Option value={PaymentType.Expense}>
                        {i18n.t(PaymentModalTKeys.expenseOption)}
                      </Option>
                      <Option value={PaymentType.Income}>
                        {i18n.t(PaymentModalTKeys.incomeOption)}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : null} */}
              <Col span={24} className={rateType===RateTypes.One_time?'hidden':'visible'}>
                <Form.Item>
                  <Checkbox
                    disabled={rateType===RateTypes.One_time?true:false}
                    checked={onGoing}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDisabled(true);
                        setColClass('hidden');
                      }
                      else{
                        setDisabled(false);
                        setColClass('visible');
                      }
                      setOnGoing(e.target.checked);
                    }}
                  >
                    {i18n.t(PaymentModalTKeys.onGoingDesc)}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );

}

export default AddRentalModal;