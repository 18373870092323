import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import "./ConnectedRental.css";
import AddRentalModal from "../AddRentalModal/AddRentalModal";
import { getAnalytics, Events } from "../../clients/analytics";
import { PaymentModalTKeys, RentalsPageTKeys, CommonTKeys } from '../../translations/keys';
import { useTranslation } from "../../translations/hooks";
//shared folder imports
import { Assets, FirestoreDocument, Payment, People } from "@hermes/schema";
import {
  deletePayment
} from "@hermes/shared/src/redux/payments/actions";
import {
  getAssetPayments,
  getContactPayments
} from "@hermes/shared/src/redux/payments/selector";
import {
  PaymentType
} from "@hermes/schema";
//antd imports
import { Card, Button, Timeline, Row, Col, Empty, Modal } from "antd";
import { EditOutlined, DeleteOutlined} from "@ant-design/icons";


interface Props {
  assetActive: FirestoreDocument<Assets>| { id: '', doc: undefined },
  personActive: FirestoreDocument<People>| { id: '', doc: undefined }
}

const ConnectedRental: React.FunctionComponent<Props> = ({ assetActive, personActive }) => {
  console.log(assetActive, personActive)
  const dispatch = useDispatch();
  const confirm = Modal.confirm;
  const {i18n, t} = useTranslation();
  const assetsPayments = useSelector(getAssetPayments(assetActive.id));
  const contactPayments = useSelector(getContactPayments(personActive.id));
  console.log(contactPayments)
  let payments;
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [editRecord, setEditRecord] = useState<FirestoreDocument<Payment>>();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const handleDelete = (deleteId) => {
    dispatch(deletePayment(deleteId));
  };

  const showDeleteConfirm = (deleteId) => {
    confirm({
      title: i18n.t(RentalsPageTKeys.deleteRentalConfirmation),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText:  i18n.t(CommonTKeys.no),
      onOk() {
        handleDelete(deleteId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  if(assetsPayments.length){
    payments = assetsPayments
  }
  else if(contactPayments.length){
    payments = contactPayments
  }
  console.log(payments, assetActive.id)
  return (
    <>
      {showAddPaymentModal && paymentType ? (
        <AddRentalModal
          visible={showAddPaymentModal}
          closeModal={() => {
            setShowAddPaymentModal(false);
          }}
          record={undefined}
          type={paymentType}
          asset={assetActive.doc? { id: assetActive.id, label: assetActive.doc.name }: undefined}
          person= {personActive.doc? { id: personActive.id, label: personActive.doc.name }: undefined}
        />
      ) : null}

        {showEditModal && editRecord ? (
          <AddRentalModal
            visible={showEditModal}
            closeModal={() => {
              setShowEditModal(false);
            }}
            record={editRecord}
            type={undefined}
            asset={undefined}
            person={undefined}
          />
        ) : null}

      <Card
        className="connected-rental"
        title={i18n.t(RentalsPageTKeys.rentalHistory)}
        extra={
          <div className="button-container">
            <Button
              type="primary"
              onClick={() => {
                setPaymentType(PaymentType.Income);
                setShowAddPaymentModal(true);
                getAnalytics().track(Events.ClickNewRental, {});
              }}
              className="rental"
            >
              {i18n.t(RentalsPageTKeys.rentalButton)}
              </Button>
            <Button
              type="primary"
              onClick={() => {
                setPaymentType(PaymentType.Expense);
                setShowAddPaymentModal(true);
                getAnalytics().track(Events.ClickNewExpense, {});
              }}
              className="expense"
            >
              {i18n.t(RentalsPageTKeys.expenseButton)}
                    </Button>
          </div>

        }
        bordered>

        {payments && payments.length ? (
          <Timeline>
            {payments.map((payment) => {
              return (
                <Timeline.Item>
                  <Row gutter={[0, 0]}>
                    <Col span={24}>
                      <Row
                        gutter={[0, 0]}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col span={12}>
                          <Row>
                            <b>
                              {payment.doc.people_ref
                                ? payment.doc.people_ref.label
                                : "--"}
                            </b>
                          </Row>
                          <Row>
                            {payment.doc.data.start_date
                              .toDate()
                              .toDateString()}{" "}
                            -{" "}
                            {payment.doc.data.end_date
                              ? payment.doc.data.end_date
                                  .toDate()
                                  .toDateString()
                              : new Date().toDateString()}
                          </Row>
                        </Col>
                        <Col span={6} style={{ textAlign: "center" }}>
                          <Row style={{ fontSize: "20px" }}>
                            {payment.doc.data.rate
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </Row>
                          <Row>
                            {payment.doc.data.rate_type} ({payment.doc.type})
                          </Row>
                        </Col>
                        <Col span={6} className="action-container">
                          <Button
                            icon={<EditOutlined />}
                            type="dashed"
                            // type="text"
                            onClick={() => {
                              setEditRecord(payment);
                              setShowEditModal(true);
                            }}
                          >
                            {i18n.t(CommonTKeys.edit)}
                          </Button>
                          <Button
                            icon={<DeleteOutlined />}
                            danger
                            type="text"
                            onClick={() => {
                              showDeleteConfirm(payment.id);
                            }}
                          >
                             {i18n.t(CommonTKeys.delete)}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* <div>
                            <div>{payment.people_ref? payment.people_ref.label : "Not Mentioned"}</div>
                            <div>{payment.start_date.toDateString()} - {payment.end_date.toDateString()}</div>
                            </div>
                            <div>
                                <div></div>
                                <div></div>
                            </div> */}
                </Timeline.Item>
              );
            })}
          </Timeline>
        ) : (
            <Empty
              image="/assets/empty-rentals.svg"
              description={<span>{i18n.t(RentalsPageTKeys.emptyConnectedRentals)}</span>}
            ></Empty>
          )}
      </Card>
    </>
  );

}

export default ConnectedRental;