import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isMobileOnly, isAndroid, isIOS } from "react-device-detect";

import "./SharedFileViewerPage.css";
import SharedAssetViewer from "../components/SharedAssetViewer/SharedAssetViewer";
import SharedReceiptViewer from "../components/SharedReceiptView/SharedReceiptView";
import SharedInvoiceViewer from "../components/SharedInvoiceViewer/SharedInvoiceViewer";
import { useTranslation } from "../translations/hooks";
import { SharedFileViewerPageTKeys } from "../translations/keys";

import { Shared, SharedType } from "@hermes/schema";
import { loadShareById } from "@hermes/shared/src/redux/shared/actions";
import { loadShareReceiptById } from "@hermes/shared/src/redux/sharedReceipt/actions";
import { getShare } from "@hermes/shared/src/redux/shared/selectors";


import { PageHeader } from "antd";

interface Props {
  match: {
    params: {
      shareId: string;
    };
  };
}

export const toDataURL = (url) => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

const SharedFileViewerPage: React.FunctionComponent<Props> = (props) => {
  console.log(props.match.params.shareId);
  const dispatch = useDispatch();

  const share = useSelector(getShare);
  const {i18n, t} = useTranslation();
  const [shareType, setShareType] = useState<SharedType>();
  const [shareId, setShareId] = useState<string>('')
  const [shareObj, setShareObj] = useState<Shared>();
  const [url, setUrl] = useState<string>()
  const handleClose = () => {
    console.log("button clicked")
    let banner = document.getElementById('banner');
    if(banner){
      banner.style.display="none"
    }
  }

  const getAppUrl = () => {
    setTimeout(function () {
      console.log("reaches here")
      window.location.href = `rentbook://rentbook.com?shareLink=`+url;
    }, 25);

    if(isAndroid){
      window.location.href = "https://play.google.com/store/apps/details?id=com.tenderd.rentbook";
    } else if(isIOS){
      window.location.href ="https://apps.apple.com/us/app/id1520161034";
    } 
  }

  useEffect(() => {
    dispatch(loadShareById(props.match.params.shareId)).then(
      (response) => {
        let id = Object.keys(response)[0];
        console.log(response, id, response[id].doc)
        let shareObj = response[id].doc
        setShareObj(shareObj)
        setShareType(shareObj.type)
        setShareId(id)
        if(shareObj.type === SharedType.Receipt){
          dispatch(loadShareReceiptById(id)).then((response) =>{
            console.log(response)
          })
        }
      }
    );
    if(isMobileOnly){
      let url = window.location.href;
      setUrl(url)
      window.location.href = `rentbook://rentbook.com?shareLink=`+url;
    }

  }, []);

  console.log(share);
  return(
    <div className='shared-file-viewer-page'>
      {shareType=== SharedType.Asset && shareId.length? <SharedAssetViewer shareId={shareId}/> : null}
      {shareObj && shareType === SharedType.Receipt && shareId.length? <SharedReceiptViewer shareId={shareId} owner={shareObj.shared_by} /> : null}
      {shareObj && shareType === SharedType.Invoice && shareId.length? <SharedInvoiceViewer shareId={shareId} owner={shareObj.shared_by} />: null}
      {isMobileOnly ? (

      <div className={`banner-prompt app`} id="banner">
        <div
          style={{
            width: "100%",
            backgroundColor: "rgba(255,255,255,0.75)"
          }}
        >
          <PageHeader
            ghost={true}
            title={[
              <div className="row">
                <div
                  onClick={() => {
                    getAppUrl();
                  }}
                >
                   {i18n.t(SharedFileViewerPageTKeys.openInApp)}
                </div>
                <div
                  onClick={() => {
                    handleClose();
                  }}
                >
                  x
                </div>
              </div>
            ]}
          ></PageHeader>
        </div>
      </div>
      ) : null}
    </div>
  )
  
};

export default SharedFileViewerPage;
