import * as React from 'react';
import { useState, useEffect } from 'react';
import { Input, Select, Radio, Button, Alert, Row, Col, Card } from "antd";
import './SplashScreen.css';



const SplashScreen: React.FunctionComponent<{}> = ({ }) => {

  return (
    <Row className="splash-screen" justify="center" align="middle">
      <Col md={8} xs={20}>
        <img className="bg" src="/assets/logo.svg" />
      </Col>
    </Row>
  );
};

export default SplashScreen;