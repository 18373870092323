import { Assets, FirestoreDocument } from "@hermes/schema";
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action } from "typesafe-actions";
import { Service } from "../../business";
import { StoreState } from "../store/types";
import { ActionTypes } from "./constants";
import { setActiveAsset } from "../ui/actions";

export const getAssetSuccess = (payload: {[id: string]: FirestoreDocument<Assets>;}) => action(ActionTypes.GET_ASSET_SUCCESS, payload);
export const getAsset = () => action(ActionTypes.GET_ASSET_REQUEST);
export const getAssets = () => action(ActionTypes.GET_ASSETS_REQUEST);
export const getAssetsSuccess = (payload: {[id: string]: FirestoreDocument<Assets>;}) => action(ActionTypes.GET_ASSETS_SUCCESS, payload);
export const getAssetsFailure = (message: string) => action(ActionTypes.GET_ASSETS_FAILURE, message);

export const addAssetRequest = () => action(ActionTypes.ADD_ASSET_REQUEST);
export const addAssetSuccess = (payload: {[id: string]: FirestoreDocument<Assets>;}) => action(ActionTypes.ADD_ASSET_SUCCESS, payload);
export const addAssetFailure = (message: string) => action(ActionTypes.ADD_ASSET_FAILURE, message);

export const updateAssetSuccess = (doc: FirestoreDocument<Partial<Assets>>) => action(ActionTypes.UPDATE_ASSET_SUCCESS, doc);
export const deleteAssetSuccess = (id: string) => action(ActionTypes.DELETE_ASSET_SUCCESS, id);

type AssetMap = {
    [id: string]: FirestoreDocument<Assets>;
};

export const loadAssets = (): ThunkAction<Promise<AssetMap>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getAssets());
        const user = getState().user.user;
        return service.assetQuery.getAll(user.id).then((assets) => {
            const responseMap: AssetMap = {};
            assets.forEach((response) => {
                responseMap[response.id] = {
                    id: response.id,
                    doc: response.data(),
                };
            });
            dispatch(getAssetsSuccess(responseMap));
            return responseMap;
        }).catch((err) => {
            dispatch(getAssetsFailure(err.message));
            return {};
        });
    };
};


export const loadAssetById = (assetId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getAsset());
        const user = getState().user.user;
        const responseMap: {
            [id: string]: FirestoreDocument<Assets>;
        } = {};
        return service.assetQuery.get(user.id, assetId).then((assetData) => {
            responseMap[assetData.id] = {
                id: assetData.id,
                doc: assetData.data()
            }
            dispatch(getAssetSuccess(responseMap));
        })
        .catch((err) => {
            dispatch(getAssetsFailure(err.message));
        })
    }
};

export const addAsset = (data: Pick<Assets, "name" | "description" | "gallery" | "status" | "document" | "identifier" >): ThunkAction<Promise<FirestoreDocument<Assets>>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(addAssetRequest());
        const user = getState().user.user;
        const dataObj: Assets = {
            user_ref: {
                id: user.id,
                label: user.doc.name,
            },
            ...data,
            internal: {
                created_at: service.firebase.fromDate(new Date()),
                updated_at: service.firebase.fromDate(new Date()),
            }
        };
        return service.assetQuery.create(user.id, dataObj).then((res) => {
            const responseMap = { id: res.id, doc: dataObj};
            dispatch(addAssetSuccess({
                [res.id]: { id: res.id, doc: dataObj },
            }));
            return responseMap;
        }).catch((err) => {
            dispatch(addAssetFailure(err.message));
            throw err;
        });
    };
};

export const updateAsset = (id: string, doc: Partial<Assets>): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        // dispatch(addAssetRequest());
        const user = getState().user.user;
        return service.assetQuery.update(user.id, { id, doc }).then((res) => {
            dispatch(updateAssetSuccess({ id: id, doc: { ...doc }}));
        }).catch((err) => {
            // dispatch(addAssetFailure(err.message));
            throw err;
        });
    };
};

export const deleteAsset = (assetId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        // dispatch(addAssetRequest());
        const user = getState().user.user;
        return service.assetQuery.delete(user.id, assetId).then((res) => {
            dispatch(deleteAssetSuccess(assetId));
        }).catch((err) => {
            // dispatch(addAssetFailure(err.message));
            throw err;
        });
    };
};