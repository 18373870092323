import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//imported components within webapp
import { useTranslation } from "../../translations/hooks";
import { CommonTKeys, ContactsPageTKeys,  EditPersonModalTKeys, AddPersonModalTKeys, PaymentModalTKeys } from "../../translations/keys";
import ConnectedRental from "../ConnectedRental/ConnectedRental";
import BreadCrumb from "../BreadCrumb/BreadCrumb"
import { getAnalytics, Events } from "../../clients/analytics";
import "./ContactDetails.css";
//shared folder imports
import {
  updatePeople,
  loadPeople,
  addPeople,
  deletePeople,
} from "@hermes/shared/src/redux/people/actions";
import { updatePayment } from '@hermes/shared/src/redux/payments/actions';
import {
  People,
  FirestoreDocument,
  Language,
  langAbbrMap,
  countriesArray,
} from "@hermes/schema";
import { getContactPayments } from "@hermes/shared/src/redux/payments/selector";
import { loadPayments } from "@hermes/shared/src/redux/payments/actions";
//antd imports
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  notification,
  Alert,
  Card,
  Button,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
//other library imports
import PhoneInput from "react-phone-input-2";

const info = Modal.info;

interface Props {
  record: FirestoreDocument<People>;
}

const { Option } = Select;
const confirm = Modal.confirm;

const ContactDetails: React.FunctionComponent<Props> = ({ record }) => {
  let history = useHistory();
  const {i18n, t} = useTranslation();
  const dispatch = useDispatch();
  const payments = useSelector(getContactPayments(record.id));
  const [contactName, setContactName] = useState<string>();
  const [error, setError] = useState({ key: "", value: false });
  const [phone_number, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const displayErrorMsg = () => {
    if(!contactName){
      setError({key:AddPersonModalTKeys.nameErrMsg, value:true});
    }
    else if(!phone_number){
      setError({key:AddPersonModalTKeys.phoneErrMsg, value:true});
    }
    setLoading(false)
  }


  const handleOk = async () => {
    setLoading(true);
    if (contactName && phone_number ) {
      let obj = {
        name: contactName,
        phone_number,
      };

      dispatch(updatePeople(record.id, obj)).then((res) => {
        let config = {
          message: i18n.t(EditPersonModalTKeys.personUpdatedTitle),
          description: i18n.t(EditPersonModalTKeys.personUpdatedDescription),
        };
        notification.success(config);
        setLoading(false);
        dispatch(loadPeople());
        getAnalytics().track(Events.UpdateContact, {id: record.id, obj});
        if(contactName && contactName !== record.doc.name){
          for(let i=0; i< payments.length; i++){
            let contactRef = {
              id: record.id,
              label: contactName
            }
            let obj = {
              ...contactRef && { people_ref: contactRef },
            }
            dispatch(updatePayment(payments[i].id, obj)).then((res) => {
              let config = {
                  message: i18n.t(PaymentModalTKeys.paymentUpdatedTitle),
                  description: i18n.t(PaymentModalTKeys.paymentUpdatedDescription),
              }
              notification.success(config)
            });
    
          }
  
        }
      });

    } else {
      displayErrorMsg()
    }
  };

  const closeError = () => {
    setError({ key: "", value: false });
  };

  const handleDelete = (deleteId) => {
    getAnalytics().track(Events.ClickDeleteContact, { })
    dispatch(deletePeople(deleteId));
    history.push(`/people`);
  };

  const showDeleteConfirm = (deleteId) => {
    confirm({
      title: i18n.t(ContactsPageTKeys.deleteContactConfirm),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText:i18n.t(CommonTKeys.no),
      onOk() {
        handleDelete(deleteId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showInfo = () => {
    info({
      title: i18n.t(ContactsPageTKeys.paymentsLinkedHeading),
      content: i18n.t(ContactsPageTKeys.paymentsLinkedDesc),
      onOk() {},
    });
  };

  useEffect(() => {
    dispatch(loadPayments());
    if (record) {
      if (!contactName) {
        setContactName(record.doc.name);
      }
      if (!phone_number) {
        setPhone(record.doc.phone_number);
      }
    }
  }, []);

  return (
    <Row gutter={[0, 15]} justify="end">
      <Col span={24}>
        <BreadCrumb link="/people" heading={i18n.t(ContactsPageTKeys.allPeople)} name={record.doc.name}/>
      </Col>
      <Col span={24}>
        <Card className="contact-details" title={i18n.t(ContactsPageTKeys.basicDetails)} bordered>
          <Form layout="horizontal" labelCol={{ span: 5 }} labelAlign="left">
            <Row gutter={[15, 15]} justify="space-between">
              <Col span={24}>
                {error.value ? (
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                ) : null}
              </Col>
              <Col span={12}>
                <Form.Item label={i18n.t(AddPersonModalTKeys.contactNameLabel)}>
                  <Input
                    value={contactName}
                    className="input-item"
                    name={i18n.t(CommonTKeys.nameLabel)}
                    placeholder={i18n.t(
                      EditPersonModalTKeys.contactNamePlaceholder
                    )}
                    onChange={(e) => {
                      setContactName(e.target.value);
                    }}
                    onBlur={() => {
                      handleOk();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={i18n.t(AddPersonModalTKeys.phoneNumberLabel)}>
                  <PhoneInput
                    value={phone_number}
                    country={"ae"}
                    preferredCountries={countriesArray}
                    inputProps={{
                      name: "phone",
                    }}
                    onChange={(e) => {
                      setPhone(`+${e}`);
                    }}
                    onBlur={() => handleOk()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col span={24}>
        <ConnectedRental
          assetActive={{ id: "", doc: undefined }}
          personActive={record}
        />
      </Col>
      <Col span={8}>
        <Button
          icon={<DeleteOutlined />}
          danger
          block
          type="primary"
          onClick={() => {
            if (payments.length) {
              showInfo();
            } else {
              showDeleteConfirm(record.id);
            }
          }}
        >
          {i18n.t(ContactsPageTKeys.deleteContact)}
        </Button>
      </Col>
    </Row>
  );
};

export default ContactDetails;
