export const generateShortLink = async (link: string, customDynamicLink?: string): Promise<string> => {
    const shortlink: string= await fetch(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCudr3ZvJ5iRAGYKfgD3Ku12a85vutY03s`, {
      mode: 'cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',

      },
      body: JSON.stringify({
        "dynamicLinkInfo": {
          "dynamicLinkDomain": customDynamicLink? customDynamicLink: "share.rentbook.com",
          "link": link,
        },
        "suffix": {
          "option": "SHORT"
        }
      })
    })
      .then(res => res.json())
      .then((res) => {
        return(res.shortLink);
      })
      
    return new Promise ((resolve, reject) => {
        resolve(shortlink);
    });

  }