import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ConfigProvider
} from "antd";
import { isMobileOnly } from "react-device-detect";
import "./SharedAssetViewer.css";
import SharedFileHeader from "../../SharedFileViewerPage/SharedFileHeader";
import AssetDetailsComponent from "./AssetDetailsComponent";
import { useTranslation } from "../../translations/hooks";

import { loadShareAssetById } from "@hermes/shared/src/redux/sharedAsset/actions";
import { getShareAsset } from "@hermes/shared/src/redux/sharedAsset/selectors";
import { langCodeArray } from "@hermes/schema";

interface Props {
  shareId: string
}

const SharedAssetViewer: React.FunctionComponent<Props> = ({shareId}) => {
  const dispatch = useDispatch();

  let { lang } = useParams();
  const shareAssets = useSelector(getShareAsset);
  const {i18n, t} = useTranslation();
  const [direction, setDirection] = useState<'ltr' | 'rtl' | undefined>();

  useEffect(() => {
    dispatch(loadShareAssetById(shareId)).then((response) => {
        console.log(response);
      }
    );
    if(isMobileOnly){
      let url = window.location.href;
      window.location.href = `rentbook://rentbook.com?shareLink=`+url;
    }

    if(langCodeArray.includes(lang)){
      i18n.changeLanguage(lang);   
      if(lang === "ar" || lang ==="ur"){
        setDirection('rtl')
      }
      else{
        setDirection('ltr')
      }
    }
    else{
      i18n.changeLanguage('en');   
    }
  }, []);

  console.log(shareAssets)

  return (
    <ConfigProvider direction={direction}>
    <div className="shared-asset-viewer-page ant-layout">
      <SharedFileHeader/>
      <AssetDetailsComponent store={false} assets={shareAssets}/>
    </div>
    </ConfigProvider>
  );
};

export default SharedAssetViewer;
