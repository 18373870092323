import * as React from "react";
//imported components within webapp
import { useTranslation } from "../translations/hooks";
import { SharedFileViewerPageTKeys } from "../translations/keys";
//antd imports
import { Button, Layout, Menu} from "antd";
//Other imports
import { isMobileOnly } from "react-device-detect";
const appLink = "https://app.rentbook.com";



const SharedFileHeader: React.FunctionComponent = () => {
    const {i18n, t} = useTranslation();
    return(
        <Layout.Header className={"app-header"}>
        <div className="header-container">
          <div className="logo">
            <img src="/assets/logo-dark.svg" />
          </div>
          <Menu mode="horizontal">
            <Button type="primary" size="large" href={appLink}>
              {isMobileOnly ? i18n.t(SharedFileViewerPageTKeys.tryRentbook) : i18n.t(SharedFileViewerPageTKeys.tryOutRentbook) }
            </Button>
          </Menu>
        </div>
      </Layout.Header>
    )
}

export default SharedFileHeader;
  