import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import { ColorCode } from "../../constants";
import { useTranslation } from "../../translations/hooks";
import { AssetsPageTKeys, CommonTKeys } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import "./AssetsTable.css";
//shared folder imports
import { Assets, FirestoreDocument, Status } from "@hermes/schema";
import {
  updateAsset
} from "@hermes/shared/src/redux/assets/actions";
import {
  activeAssets
} from "@hermes/shared/src/redux/payments/selector";
//antd imports
import { Button, Table, Row, Col, Tag, Avatar, Empty } from "antd";
import { RightOutlined } from "@ant-design/icons";

interface Props {
  assets: FirestoreDocument<Assets>[];
  showModal: () => void;
  setSelectedAsset: (record: FirestoreDocument<Assets>) => void;
}

const { Column } = Table;

const AssetsTable: React.FunctionComponent<Props> = ({
  assets,
  showModal,
  setSelectedAsset,
}) => {
  let history = useHistory();
  const {i18n, t} = useTranslation();
  const dispatch = useDispatch();
  const active_assets = useSelector(activeAssets);
  const emptyAssets = (
    <Empty
      className="empty-large"
      image="/assets/empty-asset.svg"
      description={
        <p>
          {i18n.t(AssetsPageTKeys.emptyAssets)}
          <br />
          <a
            onClick={() => {
              showModal();
              getAnalytics().track(Events.ClickNewEquipment, { })
            }}
          >
            {i18n.t(AssetsPageTKeys.emptyAssetsLink)}
          </a>
        </p>
      }
    />
  );

  // useEffect(() => {
  //   console.log("comes here", assets)
  //   for(let i=0; i < assets.length; i++){
  //     console.log("comes here")
  //     if(active_assets.assets.length){
  //       console.log(active_assets.assets)
  //       if(active_assets.assets.includes(assets[i].id)){
  //         dispatch(
  //           updateAsset(assets[i].id, {
  //             status: Status.RENTED_OUT
  //           })
  //         )
  //       }
  //       else{
  //         dispatch(
  //           updateAsset(assets[i].id, {
  //             status: Status.IDLE
  //           })
  //         )
  //       }

  //     }
  //     else{
  //       dispatch(
  //         updateAsset(assets[i].id, {
  //           status: Status.IDLE
  //         })
  //       )
  //     }

  //   }
    
  // }, [assets.length]);

  return (
    <div className="assets-table">
      <Table
        dataSource={assets}
        locale={{
          emptyText: emptyAssets,
        }}
        rowKey="id"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          total: assets.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        {/* <Column title="SL No" dataIndex="id" render={(text, record: FirestoreDocument<Payment>) => payments.indexOf(record) + 1} /> */}
        <Column
          title={i18n.t(CommonTKeys.nameLabel)}
          dataIndex={["doc", "name"]}
          sorter={(
            a: FirestoreDocument<Assets>,
            b: FirestoreDocument<Assets>
          ) =>
            a.doc.name && b.doc.name ? a.doc.name.localeCompare(b.doc.name) : 0
          }
          sortDirections={["descend", "ascend"]}
          width={280}
          render={(text, record: FirestoreDocument<Assets>) => {
            if (!text || !text.length) text = "--";
            return (
              <Row justify="center" align="middle" gutter={[20, 0]}>
                <Col span={12}>
                  <Avatar
                    shape="square"
                    size={80}
                    src={
                      record.doc.gallery && record.doc.gallery.length
                        ? record.doc.gallery[0].image
                        : "/assets/placeholder.svg"
                    }
                  />
                </Col>
                <Col span={12}>
                  <p>{text}</p>
                  <Tag style={{backgroundColor: ColorCode.getEquipmentStatusColor(record.doc.status), color: '#fff'}}>{record.doc.status}</Tag>
                </Col>
              </Row>
            );
          }}
        />
        <Column
          title={i18n.t(CommonTKeys.descLabel)}
          dataIndex={["doc", "description"]}
          render={(text, record: FirestoreDocument<Assets>) => {
            if (!text || !text.length) text = "--";
            return text;
          }}
        />

        <Column
          title={i18n.t(CommonTKeys.actionLabel)}
          render={(text, record: FirestoreDocument<Assets>) => {
            return (
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setSelectedAsset(record);
                  console.log(record);
                  history.push(`/assets/${record.id}`);
                  getAnalytics().track(Events.ClickAssetDetails, {
                    status: record.doc.status, 
                    name: record.doc.name,
                    images: record.doc.gallery, 
                    description: record.doc.description
                  })
                }}
              >
                {i18n.t(CommonTKeys.viewDetailsButton)}
                <RightOutlined />
              </Button>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default AssetsTable;
