import * as React from "react";
import { useState, useEffect } from "react";
import { FirestoreDocument, Assets, Owner, User } from "@hermes/schema";
import {
    Row,
    Col,
    Card,
    ConfigProvider,
    Empty
  } from "antd";
import { getServer } from "../clients/server";
import { useTranslation } from "../translations/hooks";
import SharedFileHeader from "../SharedFileViewerPage/SharedFileHeader";
import AssetDetailsComponent from "../components/SharedAssetViewer/AssetDetailsComponent";
import './StoreViewPage.css'
interface Props {
  match: {
    params: {
      storeName: string;
    };
  };
}

const StoreViewPage: React.FunctionComponent<Props> = (props) => {
    console.log(props.match.params.storeName);
    // const user = useSelector((state: StoreState) => state.user);
    const {i18n, t} = useTranslation();
    let storeName= props.match.params.storeName;

    const [user, setUser] = useState<FirestoreDocument<User>>();
    const [assets, setAssets] = useState<FirestoreDocument<Assets>[]>();
    const [notFound, setNotFound] = useState<boolean>(false);
    const [timer, setTimer] = useState<number>(5)

      
    useEffect(()=>{
        if(notFound){
            let timeout = setTimeout(()=>{
                let t = timer;
                console.log("timer calling", timer)
                if(timer>1){
                    setTimer(t - 1);
                    // t = t - 1;
                } else {
                    clearTimeout(timeout)
                    window.location.href = "https://www.rentbook.com/" 
                }
            }, 1000)
        }
    },[notFound, timer])

    useEffect(()=>{
       if (storeName) {
           console.log({storeName});
        getServer().getUserByStoreName(storeName.toLowerCase()).then((user1)=>{
            console.log(user1.store.id)
            if(user1.store.id){
                setUser(user1.store)
                console.log({loadedUser: user1.store});
                getServer().getUserAssets(user1.store.id).then((res) => {
                    console.log(res.assets)
                    setAssets(res.assets)
                });
            } else {
                //in this case it's the user id and not storeName
                getServer().getUserAssets(storeName).then((res) => {
                    console.log({res});
                    if (res && res.assets.length > 0) {
                        console.log(res.assets)
                        setAssets(res.assets);
                    } else {
                        console.log("no such store")
                        //alert("not found");
                        setNotFound(true);
                    }
                });
            }     
        }).catch((err) => {
            console.log(err)
        })
       } else {
        console.log("no such store")
        setNotFound(true);
       }

    },[])
 
  return(
    <ConfigProvider>
        <div className="shared-asset-viewer-page ant-layout store">
            <SharedFileHeader/>
            {user && user.id?
                <Row gutter={[20,20]} className="container">
                <Col span={24} className="store-title">
                    <Card>
                        <h1>{user.doc.name}'s Storefront</h1>
                    </Card>
                </Col>
                </Row>
                :
                notFound?
                <Row gutter={[20,20]} className="container">
                <Col span={24} >
                <Empty
                  image="/assets/empty-document.svg"
                  description={<span> No store found 😕. Rerouting to <a href="https://www.rentbook.com/">rentbook.com</a> in {timer} seconds.</span>}
                  style={{
                    marginTop: '200px',
                    fontSize: '18px'
                  }}
                ></Empty>
                </Col>
                </Row>
                :
                null
             }
            {assets?
            <AssetDetailsComponent store={true} assets={assets} owner={{label: user?.doc.name, language: user?.doc.language, phone_number: user?.doc.phone_number, id: user?.id}}/>
            :
            null
            }

        </div>
    </ConfigProvider>
  )
  
};

export default StoreViewPage;
