import * as React from 'react';
import { useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import * as firebase from "firebase";
//imported components within webapp
import { getWebFirebaseApp } from "../../clients/firebase";
import { useTranslation } from "../../translations/hooks";
import { AssetsPageTKeys, CommonTKeys } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import "./AssetDocuments.css";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { Assets, FileType, FirestoreDocument, Document } from "@hermes/schema";
import {
    updateAsset,
} from "@hermes/shared/src/redux/assets/actions";
//antd imports
import {
    Input,
    Empty,
    Button,
    DatePicker,
    notification,
    Form,
    Modal,
    Card,
    Upload,
    Row,
    Col,
  } from "antd";
  import {
    DeleteOutlined,
    UploadOutlined
  } from "@ant-design/icons";
//other library imports
import moment from "moment";
const confirm = Modal.confirm;
const info = Modal.info;

interface Props {
    documents:  Document[] | undefined,
    active: FirestoreDocument<Assets>,
    setDocuments: (documents: Document[]) => void;
    onSave:(status, docs) => void;
}

const AssetDocuments: React.FunctionComponent<Props> = ({documents, active, setDocuments, onSave}) => {
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const user = useSelector((state: StoreState) => state.user);
    const uuidv4 = require('uuid/v4')

    const storageRef = getWebFirebaseApp().storage().ref();
    let docsRef = storageRef.child("assets-documents").child(user.user.id);

    const uploadDocs = async (arr) => {
        console.log(arr);
        if (arr) {
          setLoading(true);
          let docs: Document[] = [];
          // TODO: move this logic to actions if possible
          for (let i = 0; i < arr.length; i++) {
            console.log(arr[i]);
            let name = arr[i].name.length ? arr[i].name : null;
            let type: FileType;
            if (arr[i].type === "image/png") {
              type = FileType.IMAGE_PNG;
            } else if (arr[i].type === "image/jpeg") {
              type = FileType.IMAGE_JPG;
            } else {
              type = FileType.PDF;
            }
            let docRef = docsRef.child(uuidv4());
            let url: string = await docRef
              .put(arr[i])
              .then(function (snapshot) {
                return snapshot.ref.getDownloadURL();
              })
              .then((downloadURL) => {
                return downloadURL;
              });
            let obj = {
              ...(name && { name: name }),
              type,
              url,
            };
            docs.push(obj);
          }
          dispatch(
            updateAsset(active.id, {
              document: [...docs, ...(active.doc.document || [])],
            })
          ).then((res) => {
            let config = {
              message: i18n.t(AssetsPageTKeys.docAddedHeading),
              description: i18n.t(AssetsPageTKeys.docAddedDesc),
            };
            notification.success(config);
            getAnalytics().track(Events.ClickAddAssetDocument, {docs: (docs || [])})
            setLoading(false);
          });
        }
      };

    const props = {
        name: "file",
        multiple: true,
        showUploadList: false,
        action: "//jsonplaceholder.typicode.com/posts/",
        beforeUpload: (file, fileList) => {
          console.log(file, fileList);
          if (file === fileList[fileList.length - 1]) {
            uploadDocs(fileList);
          }
          return true;
        },
      };


  const showDeleteAssetDocumentConfirm = (documents: Document[], index: number) => {
    confirm({
      title: i18n.t(AssetsPageTKeys.deleteImageHeading),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText: i18n.t(CommonTKeys.no),
      onOk() {
        const updated = documents.splice(index, 1);
        setDocuments(updated);
        onSave(undefined,undefined);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

    return(
        <Col span={24}>
          <Card
            bordered
            title={i18n.t(AssetsPageTKeys.docsHeading)}
            extra={
              <Upload {...props}>
                <Button type="primary" shape="round" loading={loading}>
                  <UploadOutlined />
                  {i18n.t(AssetsPageTKeys.uploadDoc)}
                </Button>
              </Upload>
            }
            className="doc-section"
          >
            <div className="doc-container">
              {documents && documents.length > 0 ? (
                <>
                  {documents.map((value: Document, index: number) => {
                    return (
                      <Form layout={"vertical"}>
                        <Row className="doc-row" gutter={[15, 10]}>
                          <Col className="image-container" span={4}>
                            <a target="_blank" href={value.url}>
                              <img
                                src={
                                  value.type === "application/pdf"
                                    ? "/assets/pdf.png"
                                    : value.url
                                }
                              />
                            </a>
                          </Col>
                          <Col span={6} className="input-container">
                            <Form.Item label={i18n.t(CommonTKeys.nameLabel)}>
                              <Input
                                defaultValue={value.name ? value.name : ""}
                                placeholder={i18n.t(AssetsPageTKeys.docNamePlaceholder)}
                                onChange={(e) => {
                                  let arr = documents;
                                  if (arr) {
                                    let name = e.target.value;
                                    let obj = {
                                      ...(name.length && { name: name }),
                                      type: arr[index].type,
                                      ...(arr[index].expiry_date && {
                                        expiry_date: arr[index].expiry_date,
                                      }),
                                      url: arr[index].url,
                                    };
                                    arr[index] = obj;
                                  }
                                  setDocuments(documents);
                                }}
                                onBlur={(e) => {
                                  onSave(undefined,undefined);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5} className="input-container expiry">
                            <Form.Item label={i18n.t(AssetsPageTKeys.docReminder)}>
                              <DatePicker
                                defaultValue={
                                  value.expiry_date
                                    ? moment(value.expiry_date.toDate())
                                    : undefined
                                }
                                onChange={(date, dateString) => {
                                  let arr = active.doc.document;
                                  let expiry_date = date
                                    ? firebase.firestore.Timestamp.fromDate(
                                        date.toDate()
                                      )
                                    : null;
                                  if (arr) {
                                    let obj = {
                                      ...(arr[index].name && {
                                        name: arr[index].name,
                                      }),
                                      type: arr[index].type,
                                      ...(expiry_date && {
                                        expiry_date: expiry_date,
                                      }),
                                      url: arr[index].url,
                                    };
                                    arr[index] = obj;
                                  }
                                  onSave(undefined, arr);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={9} className="action-container">
                            <Form.Item label=" ">
                              <Button
                                icon={<DeleteOutlined />}
                                danger
                                type="text"
                                onClick={() => {
                                  showDeleteAssetDocumentConfirm(documents, index)
                                }}
                              >
                                {i18n.t(CommonTKeys.delete)}
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    );
                  })}
                </>
              ) : (
                <Empty
                  image="/assets/empty-document.svg"
                  description={<span>No documents added yet 😕</span>}
                ></Empty>
              )}
            </div>
          </Card>
        </Col>
        
    )
}

export default AssetDocuments;