import * as React from "react";
import { useEffect, useState } from "react";
import { Tabs, Layout } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import "./Footer.css";

const { TabPane } = Tabs;
const Footer: React.FunctionComponent = () => {
  let history = useHistory();
  const [active, setActive] = useState(history.location.pathname);

  return <Layout.Footer className='custom-footer'>RentBook © 2020 Powered by Tenderd (Version 1.0.13)</Layout.Footer>;
};

export default Footer;
