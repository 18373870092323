
import { Analytics } from './Analytics';

let qObject: Analytics;

export const init = (q: Analytics) => {
    qObject = q;
}


export enum Events {
  ClickNewRental = 'ClickNewRental',
  ClickShareReceipt = 'ClickShareReceipt',
  ClickSavePayment = 'ClickSavePayment',
  ClickNewExpense = 'ClickNewExpense',
  ClickAddNewPayment = "ClickAddNewPayment",
  ClickPaymentDetails = 'ClickPaymentDetails',
  ClickDeletePayment = 'ClickDeletePayment',
  ClickSaveRental = 'ClickSaveRental',
  ClickSaveExpense = 'ClickSaveExpense',
  ClickRentalDetails = 'ClickRentalDetails',
  ClickExpenseDetails = 'ClickExpenseDetails',
  UpdatePayment = 'UpdatePayment',
  UpdateReceipt = 'UpdateReceipt',


  ClickNewEquipment = 'ClickNewEquipment',
  ClickAssetDetails = 'ClickAssetDetails',
  ClickAddAssetImage = 'ClickAddAssetImage',
  ClickAddAssetDocument = 'ClickAddAssetDocument',
  ClickDeleteAsset = 'ClickDeleteAsset',
  ClickSaveAsset = 'ClickSaveAsset',
  UpdateAsset = 'UpdateAsset',
  ClickShareAllAssets = 'ClickShareAllAssets',
  ClickShareAsset = 'ClickShareAsset',

  ClickAddNewContact = 'ClickAddNewContact',
  ClickContactDetails = 'ClickContactDetails',
  ClickSaveContact = 'ClickSaveContact',
  ClickDeleteContact = 'ClickDeleteContact',
  ClickSearchContact = 'ClickSearchContact',
  ClickVerifyOtp = 'Verify OTP',
  InvalidOTP = 'Invalid OTP',
  UpdateContact = 'UpdateContact',

  SignUpComplete = 'SignUp',
  LoginSuccess = 'Login',


  ClickLogout = 'ClickLogout',

  ClickGeneratePDF = 'ClickGeneratePDF',

  Download = 'Download',
  DownloadAndroid = 'DownloadAndroid',
  DownloadiOS = 'DownloadiOS',
  FirstTimeLogin = 'FirstTimeLogin',
  SendOTP = 'Send OTP',
  ClickSendInquiry = "ClickSendInquiry"
}

export const getAnalytics = () => qObject;