import * as React from 'react';
import { useHistory } from "react-router-dom";
//antd imports
import {
    Button,
    Breadcrumb
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

interface Props {
    link: string,
    heading: string,
    name: string
}


const BreadCrumb: React.FunctionComponent<Props> = ({ link, heading, name }) => {
    let history = useHistory();
    return (

        <Breadcrumb>
            <Breadcrumb.Item>
                <Button
                    type="link"
                    size="large"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => {
                        history.push(link);
                    }}
                >
                    {heading}
                </Button>
            </Breadcrumb.Item>
            <Breadcrumb.Item>#{name}</Breadcrumb.Item>
        </Breadcrumb>
    )

}

export default BreadCrumb;