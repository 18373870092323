import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import Zendesk, { ZendeskAPI } from "react-zendesk";

const ZENDESK_KEY = "6fa93d30-46f6-4699-81cc-806ba892c92d";
const setting = {
  position: { horizontal: "right" },
  offset: { vertical: "45px" },
  color: {
    theme: "#000",
    launcher: "#E88247",
    launcherText: "#fff",
  },
  launcher: {
    label: {
      "*": "Help & Support?",
    },
  },
};

// <!-- Start of rentbook Zendesk Widget script -->
// <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6fa93d30-46f6-4699-81cc-806ba892c92d"> </script>
// <!-- End of rentbook Zendesk Widget script -->


// <!-- Start of rentbook Zendesk Widget script -->
// <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=6fa93d30-46f6-4699-81cc-806ba892c92d"> </script>
// <!-- End of rentbook Zendesk Widget script -->

const ChatSupport: React.FunctionComponent = () => {
  const useQuery = () => {
    console.log(useLocation().search);
    return new URLSearchParams(useLocation().search);
  };

  let query = useQuery();
  const user = useSelector((state: StoreState) => state.user);
  const openChat = query.get("openChat") === "true" ? true : false;
  const [first_name, setFirstName] = useState<string>('');
  const [showChat, setShowChat] = useState(false);
  const [last_name, setLastName] = useState<string>('');  
  const [email, setEmail]= useState<string>('');
  const [phone, setPhone]= useState<string>('');
  console.log(openChat);

  useEffect(() => {
    if(user && user.user && user.user.doc){
      let name =  user.user.doc.name.split(" ");
      let firstName = user.user.doc.name.split(" ")[0] || '';
      let last_name = name[name.length-1] || '';
      let email = user.user.doc.email || '';
      let phone = user.user.doc.phone_number || ''
      setFirstName(firstName);
      setLastName(last_name);
      setEmail(email)
      setPhone(phone)
    }
  }, [user]);

  const handleOpenChat = () => {
    setShowChat(true);
    ZendeskAPI("webWidget", "open");
    ZendeskAPI("webWidget", "show");
  }

  // useEffect(() => {
  //   // else {
  //   //   ZendeskAPI("webWidget", "hide");
  //   // }
  // }, [showChat]); <WhatsAppOutlined style={{ color: "#2ecc71" }} />

  useEffect(() => {
    setTimeout(() => {
      ZendeskAPI("webWidget", "open");
      ZendeskAPI("webWidget", "show");
    }, 2000);
  }, []);

  return (
    
    <>
        <Zendesk
        zendeskKey={ZENDESK_KEY}
        {...setting}
        onLoaded={() => {
            ZendeskAPI("webWidget:on", "userEvent", (event: any) => {
              console.log('The widget has been opened!');
                // if (event.action === "Web Widget Minimised") {
                //     alert('minimuzed');
                //     ZendeskAPI("webWidget", "hide");
                //     setShowChat(false);
                // }
            });
            if (user) {
                ZendeskAPI("webWidget", "prefill", {
                    name: { value: first_name },
                });
                ZendeskAPI("webWidget", "prefill", {
                    email: { value: email },
                });
                ZendeskAPI("webWidget", "prefill", {
                    phone: { value: phone },
                });
            }
        }
      }
    />
    </>
  );
};



export default ChatSupport;
