import { Status } from "@hermes/schema";

export let ColorCode = {
  getEquipmentStatusColor : (status:Status) => {    
    var color = '#000';
    switch (status) {
      case Status.IDLE:
        color = '#ff8e00'; break;
      case Status.RENTED_OUT:
        color = '#2fcc71'; break;
      default:
        color = '#000'; break;
    }
    return color;
  }
}
