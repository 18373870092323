import * as React from 'react';
import { useState, useEffect } from 'react';
import { Input, Select, Radio, Button, Alert, Row, Col, Card } from "antd";
import {
  Link,
} from "react-router-dom";
import "./NotFoundPage.css";

const playstoreLink = "https://rentbook.com";
const appstoreLink = "rentbook.com";



const NotFoundPage: React.FunctionComponent<{}> = ({}) => {
  return (
    <div className="not-found-page">
      <Row justify="center" className="app-badges">
        <Col span={14}>
          <img className="bg" src="/assets/logo-dark.svg" />
        </Col>
        <Col span={20}>
          <img className="bg" src="/assets/404.svg" />
        </Col>
        <Col span={20}>
          <h3>
            Oops! Something went wrong. Please check if the link you are trying to visit exists.
          </h3>
        </Col>
      </Row>
    </div>
  );
};

export default NotFoundPage;