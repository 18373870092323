import { action } from "typesafe-actions";
import { ActionTypes } from "./constants";
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux';
import { FirestoreDocument, People } from "@hermes/schema";
import { StoreState } from "../store/types";
import { Service } from "../../business";

export const getPeople = () => action(ActionTypes.GET_PEOPLE_REQUEST);
export const getPeopleSuccess = (payload: {[id: string]: FirestoreDocument<People>;}) => action(ActionTypes.GET_PEOPLE_SUCCESS, payload);
export const getPeopleFailure = (message: string) => action(ActionTypes.GET_PEOPLE_FAILURE, message);

export const addPeopleRequest = () => action(ActionTypes.ADD_PEOPLE_REQUEST);
export const addPeopleSuccess = (payload: {[id: string]: FirestoreDocument<People>;}) => action(ActionTypes.ADD_PEOPLE_SUCCESS, payload);
export const addPeopleFailure = (message: string) => action(ActionTypes.ADD_PEOPLE_FAILURE, message);


export const updatePeopleSuccess = (doc: FirestoreDocument<Partial<People>>) => action(ActionTypes.UPDATE_PEOPLE_SUCCESS, doc);
export const deletePeopleSuccess = (id: string) => action(ActionTypes.DELETE_PEOPLE_SUCCESS, id);


type PeopleMap = {
    [id: string]: FirestoreDocument<People>;
};
export const loadPeople = (): ThunkAction<Promise<PeopleMap>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(getPeople());
        const user = getState().user.user;
        return service.peopleQuery.get(user.id).then((people) => {
            const responseMap: PeopleMap = {};
            people.forEach((response) => {
                responseMap[response.id] = {
                    id: response.id,
                    doc: response.data(),
                };
            });
            dispatch(getPeopleSuccess(responseMap));
            return responseMap;
        }).catch((err) => {
            dispatch(getPeopleFailure(err.message));
            return {};
        });
    };
};

export const addPeople = (data: Pick<People, "name" | "phone_number">): ThunkAction<Promise<FirestoreDocument<People>>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        dispatch(addPeopleRequest());
        const user = getState().user.user;
        const dataObj: People = {
            user_ref: {
                id: user.id,
                label: user.doc.name,
            },
            ...data,
            internal: {
                created_at: service.firebase.fromDate(new Date()),
                updated_at: service.firebase.fromDate(new Date()),
            }
        };
        return service.peopleQuery.create(user.id, dataObj).then((res) => {
            const responseMap = { id: res.id, doc: dataObj};
            dispatch(addPeopleSuccess({
                [res.id]: { id: res.id, doc: dataObj },
            }));
            return responseMap;
        }).catch((err) => {
            dispatch(addPeopleFailure(err.message));
            throw err;
        });
    };
};

export const updatePeople = (id: string, doc: Partial<People>): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        const user = getState().user.user;
        return service.peopleQuery.update(user.id, { id, doc }).then((res) => {
            dispatch(updatePeopleSuccess({ id: id, doc: { ...doc }}));
        }).catch((err) => {
            throw err;
        });
    };
};


export const deletePeople = (peopleId: string): ThunkAction<Promise<void>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        const user = getState().user.user;
        return service.peopleQuery.delete(user.id, peopleId).then((res) => {
            dispatch(deletePeopleSuccess(peopleId));
        }).catch((err) => {
            throw err;
        });
    };
};