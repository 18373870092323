import { Assets, FirestoreDocument } from "@hermes/schema";
import { ActionTypes } from "./constants";
import { action } from "typesafe-actions";

export const setActiveAsset = (assetId: string) => action(ActionTypes.SET_ACTIVE_ASSET, assetId);
export const setActivePaymentType = (type: string) => action(ActionTypes.SET_ACTIVE_TYPE, type);
export const setActiveContact = (contactId: string) => action(ActionTypes.SET_ACTIVE_CONTACT, contactId);
export const setAssetMetaData = (newAssetId: string) => action(ActionTypes.SET_ASSET_METADATA, newAssetId);
export const setContactMetaData = (newContactId: string) => action(ActionTypes.SET_CONTACT_METADATA, newContactId);
export const setActivePayment = (paymentId: string) => action(ActionTypes.SET_ACTIVE_PAYMENT, paymentId);
export const setIsNewPayment = (value: boolean) => action(ActionTypes.SET_IS_NEW_PAYMENT, value);
export const setIsNewAsset = (value: boolean) => action(ActionTypes.SET_IS_NEW_ASSET, value);
export const updateNetworkState = (network: { isConnected: boolean, isReachable: boolean | null | undefined}) => action(ActionTypes.UPDATE_NETWORK_STATE, network);
export const setName = (isSet: boolean) => action(ActionTypes.IS_NAME_SET, isSet);
