import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//imported components within webapp
import './AddContactModal.css';
import { EditPersonModalTKeys, AddPersonModalTKeys, CommonTKeys } from '../../translations/keys';
import { getAnalytics, Events } from "../../clients/analytics";
import { useTranslation } from "../../translations/hooks";
//shared folder imports
import { updatePeople, loadPeople, addPeople } from '@hermes/shared/src/redux/people/actions';
import { People, FirestoreDocument, countriesArray, countryCodes } from "@hermes/schema";
//antd imports
import { Modal, Form, Row, Col, Input, Select, notification, Alert } from "antd";
//other library imports
import PhoneInput from 'react-phone-input-2';
import { StoreState } from '@hermes/shared/src/redux/store/types';


interface ModalProps {
    visible: boolean,
    closeModal: () => void;
    record: FirestoreDocument<People>| undefined
}

const {Option} = Select;

const AddContactModal: React.FunctionComponent<ModalProps> = ({ visible, closeModal, record }) => {
    const dispatch = useDispatch();
    const user = useSelector((state: StoreState) => state.user).user;
    const {i18n, t} = useTranslation();
    const [contactName, setContactName] = useState<string>();
    const [error, setError] = useState({ key:'', value:false});
    const [phone_number, setPhone] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false)

    const displayErrorMsg = () => {
      if(!contactName){
        setError({key:AddPersonModalTKeys.nameErrMsg, value:true});
      }
      else if(!phone_number){
        setError({key:AddPersonModalTKeys.phoneErrMsg, value:true});
      }
      setLoading(false)
  }

  const handleFormatPhone = (data: { phone_number: string }) => {
    let phone_number: string;
    //remove hyphen and whitespaces
    phone_number = data.phone_number.replace(/-/g,'').replace(/\s+/g, '');
    if (phone_number.startsWith("0")) {
        //check currency, remove the leading zero and append country code
        const country_code = countryCodes[user.doc.currency];
        const stripped_phone_number = data.phone_number.substring(1);
        phone_number = country_code+stripped_phone_number;
    }
    data.phone_number = phone_number.trim();
    return data.phone_number;
  };

    const handleOk = async () =>{
        setLoading(true)
        if(contactName && phone_number){
            let obj= {
              name:contactName,
              phone_number
          }
          if (!obj.phone_number.startsWith("+")) {
            alert(t(`${EditPersonModalTKeys.countryCodeErrorMessage}`));
            return;
         }
         obj.phone_number = handleFormatPhone({phone_number: obj.phone_number});
        // //remove whitespaces in between the number
        obj.phone_number = obj.phone_number.replace(/\s+/g, '');
          if(record){
            dispatch(updatePeople(record.id, obj)).then((res) => {
              let config = {
                  message: i18n.t(EditPersonModalTKeys.personUpdatedTitle),
                  description: i18n.t(EditPersonModalTKeys.personUpdatedDescription),
              }
              notification.success(config)
              setLoading(false)
              dispatch(loadPeople());
              closeModal()
            });

          }
          else{
            dispatch(addPeople(obj)).then((res) => {
              let config = {
                  message: i18n.t(AddPersonModalTKeys.personAddedTitle),
                  description: i18n.t(AddPersonModalTKeys.personAddedDescription),
              }
              notification.success(config)
              setLoading(false)
              closeModal()
            });
            getAnalytics().track(Events.ClickSaveContact, { obj });
          }



        }
        else{
          displayErrorMsg()
        }
    }

    const closeError = () => {
        setError({key:'', value:false});
    }

    useEffect(() => {
      if(record){
        if (!contactName) {
           setContactName(record.doc.name)
        }
        if(!phone_number){
            setPhone(record.doc.phone_number)
        }
      }

    }, []);

    return (
      <>
        <Modal
          closable={false}
          className="add-contact-modal"
          title={record? i18n.t(EditPersonModalTKeys.heading) : i18n.t(AddPersonModalTKeys.heading)}
          visible={visible}
          onOk={handleOk}
          onCancel={closeModal}
          okText={record? i18n.t(EditPersonModalTKeys.okText): i18n.t(AddPersonModalTKeys.heading)}
          okButtonProps={{ loading: loading }}
          cancelText={i18n.t(CommonTKeys.cancel)}
        >
          <Form layout="horizontal" className="form" labelCol={{ span: 5 }} labelAlign="left">
            <Row gutter={[10, 10]}>
              {error.value ? (
                <Alert
                  className="errMsg"
                  message={i18n.t(error.key)}
                  type="error"
                  closable
                  onClose={() => closeError()}
                />
              ) : null}
              <Col span={24}>
                <Form.Item
                  label={i18n.t(AddPersonModalTKeys.contactNameLabel)}
                >
                  <Input
                    value={contactName}
                    className="input-item"
                    name="name"
                    placeholder={i18n.t(
                      EditPersonModalTKeys.contactNamePlaceholder
                    )}
                    onChange={(e) => {
                      setContactName(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
             
              <Col span={24}>
                <Form.Item
                  label={i18n.t(AddPersonModalTKeys.phoneNumberLabel)}
                >
                  <PhoneInput
                    value={phone_number}
                    country={"ae"}
                    enableSearch
                    containerStyle={{
                      position: "relative",
                      zIndex: 1,
                    }}
                    dropdownStyle={{
                      position: "absolute",
                      zIndex: 5,
                    }}
                    preferredCountries={countriesArray}
                    inputProps={{
                      name: "phone",
                    }}
                    onChange={(e) => {
                      setPhone(`+${e}`);
                    }}
                  />
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Modal>
      </>
    );

}

export default AddContactModal;