import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import { CommonTKeys, SideBarTKeys } from "../../translations/keys";
import { useTranslation } from "../../translations/hooks";
import "./SideBar.css";
//antd imports
import { Tabs, Layout, Menu, Row, Col, Statistic, Card } from "antd";
import {
  InboxOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
//shared folder imports
import { Status } from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
  getPaymentsList,
  getTotals,
  activeAssets
} from "@hermes/shared/src/redux/payments/selector";
import {
  getAssetsList
} from "@hermes/shared/src/redux/assets/selectors";
import {
  loadPayments
} from "@hermes/shared/src/redux/payments/actions";
import {
  updateAsset,
  loadAssets
} from "@hermes/shared/src/redux/assets/actions";


const { TabPane } = Tabs;
const SideBar: React.FunctionComponent = () => {
  let history = useHistory();
  const {i18n, t} = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: StoreState) => state.user);
  const total = useSelector(getTotals);
  const active_assets = useSelector(activeAssets);
  const payments = useSelector(getPaymentsList);
  const assets= useSelector(getAssetsList);
  let active = history.location.pathname;
  active = `/${active.split("/")[1]}`;

  useEffect(() => {
    dispatch(loadPayments());
    dispatch(loadAssets())
  }, []);

  //alert(total.totalEarned);

  // useEffect(() => {
  //   for(let i=0; i < assets.length; i++){
  //     if(active_assets.assets.length){
  //       console.log(active_assets.assets)
  //       if(active_assets.assets.includes(assets[i].id)){
  //         dispatch(
  //           updateAsset(assets[i].id, {
  //             status: Status.RENTED_OUT
  //           })
  //         )
  //       }
  //       else{
  //         dispatch(
  //           updateAsset(assets[i].id, {
  //             status: Status.IDLE
  //           })
  //         )
  //       }

  //     }
  //     else{
  //       dispatch(
  //         updateAsset(assets[i].id, {
  //           status: Status.IDLE
  //         })
  //       ).then((res) => {
        
  //       })
  //     }

  //   }
    
  // }, []);

  console.log("active assets", active_assets)
  return (
    <Layout.Sider
      className={"sidebar"}
      width={300}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "sticky",
        top: 0,
        left: 0,
      }}
    >
      <div className="sidebar-inner-container">
        <div className="logo-container">
          <img
            src={"/assets/logo-dark.svg"}
            className="sidebar-logo"
            alt="Tenderd"
          />
        </div>
        <div className={"links-container"}>
          <Menu mode="inline" selectedKeys={active}>
            <Menu.Item key="/rentals" icon={<DollarCircleOutlined />}>
            {i18n.t(SideBarTKeys.rentalsLabel)}
              <Link to={`/rentals`} />
            </Menu.Item>
            <Menu.Item key="/assets" icon={<InboxOutlined />}>
            {i18n.t(SideBarTKeys.assetsLabel)}
              <Link to={`/assets`} />
            </Menu.Item>
            <Menu.Item key="/people" icon={<UsergroupAddOutlined />}>
            {i18n.t(SideBarTKeys.peopleLabel)}
              <Link to={`/people`} />
            </Menu.Item>
            <Menu.Item key="/settings" icon={<SettingOutlined />}>
            {i18n.t(SideBarTKeys.settingsLabel)}
              <Link to={`/settings`} />
            </Menu.Item>
          </Menu>
        </div>
        <Row className="stats-container" justify="center" gutter={[0, 10]}>
          <Col span={20}>
            <Card bordered>
              <Statistic
                title={i18n.t(SideBarTKeys.assetsInUse)}
                value={active_assets.active}
                suffix={`/${assets.length}`}
              />
            </Card>
          </Col>
          <Col span={20}>
            <Card bordered>
              <Statistic
                title={i18n.t(SideBarTKeys.rentalRevenue)}
                value={total.totalEarned}
                valueStyle={{ color: "#3f8600" }}
                prefix={user.user.doc.currency}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Layout.Sider>
  );
};

export default SideBar;
