import * as React from "react";
import { useState, useEffect } from "react";
import {isMobile} from 'react-device-detect';
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
// import { CommonTKeys, SideBarTKeys } from "../../translations/keys";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import "./ShareModal.css";
import { getSentry } from '../../clients';
import { getServer } from "../../clients/server";
import { useTranslation } from "../../translations/hooks";
import { CommonTKeys, ShareModalTKeys } from '../../translations/keys';
//antd imports
import {
  notification,
  Modal,
  Form,
  Button
} from "antd";
import {
  WhatsAppOutlined,
  MailOutlined,
  PhoneOutlined,
  FacebookFilled,
  CopyOutlined
} from "@ant-design/icons";
//other library imports
import PhoneInput from "react-phone-input-2";

interface ModalProps {
    visible: boolean,
    assetNames?: string,
    assetCount?: number,
    amountInfo?: string,
    receiptCount?: number,
    loading: boolean,
    type: 'asset' | 'receipt' | 'payment-report'
    closeModal: () => void;
    setLoading: (bool) => void;
    setVisible: (bool) => void;
    url: string
}


const ShareModal: React.FunctionComponent<ModalProps> = ({ visible, assetNames, assetCount, amountInfo, receiptCount, closeModal, loading, setLoading, setVisible, type, url }) => {
  const {i18n, t} = useTranslation();
  const [shareLink, setShareLink] = useState<string>('');
  const [showSMSModal, setShowSMSModal] = useState<boolean>(false);
  const [smsLoading, setSmsLoading] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>();
  const user = useSelector((state: StoreState) => state.user);
  const ellipsize = require('ellipsize');
  const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const getTextShareMsg = (url1=url) => {
    let text=''
    if(type ==='asset'){
      text = t(CommonTKeys.textMessage, {
        _ASSET_INFO_: `${assetNames ? `${assetNames} ${t(CommonTKeys.assetLowerText)}`: `${assetCount} ${assetCount && assetCount > 1 ? t(CommonTKeys.assetsLowerText): t(CommonTKeys.assetLowerText)}`}`,
        _LINK_INFO_: `${url1}`,
        _USERNAME_INFO_: `${capitalizeFirstLetter(user.user.doc.name)}`
      });
    }
    else if(type==='receipt'){
      text = t(CommonTKeys.textMessageForReceipt, {
        __AMOUNT_INFO__: `${amountInfo? `${(user.user.doc.currency)} ${amountInfo} `:  `${receiptCount} ${receiptCount && receiptCount > 1 ? t(CommonTKeys.receiptsLowerText): t(CommonTKeys.receiptLowerText)}`}`,
        __LINK_INFO__: `${url1}`,
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`
    });
    }
    else if(type==='payment-report'){
      text = t(CommonTKeys.textMessageForReport, {
        __LINK_INFO__: `${url1}`,
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`
    });
    }
    return text
  }

  const getEmailShareMsg = (url1:string) => {
    let emailBody = '';
    let emailSubject = '';
    if(type === 'asset'){
      emailBody = t(CommonTKeys.emailBody, {
        _ASSET_INFO_: `${assetNames ? `${assetNames} ${t(CommonTKeys.assetLowerText)}`: `${assetCount} ${assetCount && assetCount > 1 ? t(CommonTKeys.assetsLowerText): t(CommonTKeys.assetLowerText)}`}`,
        _LINK_INFO_: `${url1}`,
        _USERNAME_INFO_: `${capitalizeFirstLetter(user.user.doc.name)}`
      });
      emailSubject = t(CommonTKeys.emailSubject, {
        _USERNAME_INFO_: `${capitalizeFirstLetter(user.user.doc.name)}`,
        _ASSET_INFO_: `${assetNames ? `${assetNames} ${t(CommonTKeys.assetLowerText)}`: `${assetCount} ${assetCount && assetCount > 1 ? t(CommonTKeys.assetsLowerText): t(CommonTKeys.assetLowerText)}`}`,
      });

    }
    else if (type === 'receipt') {
      emailBody = t(CommonTKeys.emailBodyForReceipt, {
        __AMOUNT_INFO__:`${amountInfo? `${(user.user.doc.currency)} ${amountInfo} `:  `${receiptCount} ${receiptCount && receiptCount > 1 ? t(CommonTKeys.receiptsLowerText): t(CommonTKeys.receiptLowerText)}`}`,
        __LINK_INFO__: `${url1}`,
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`
      }); 
      emailSubject = t(CommonTKeys.emailSubjectForReceipt, {
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`,
      });
    }
    else if (type==='payment-report') {
      emailBody = t(CommonTKeys.emailBodyForReport, {
        __LINK_INFO__: `${url1}`,
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`,
      });
      emailSubject = t(CommonTKeys.emailSubjectForReport, {
        __USERNAME_INFO__: `${capitalizeFirstLetter(user.user.doc.name)}`,
      });
    }
    return({
      emailBody,
      emailSubject
    })
  }


    const copylink= () =>{
           
            if(shareLink){
                navigator.clipboard.writeText(shareLink).then(() => {
                    let config = {
                        message: i18n.t(ShareModalTKeys.copiedHeading),
                        description: i18n.t(ShareModalTKeys.copiedDesc),
                      };
                      notification.success(config);
                }, () => {
                      /* clipboard write failed */
                });
            }
    
           
      }

    const sendEmail = async() => {
      let url1 = shareLink.split("%2F").join("%252F");
      url1 = url1.split("&").join("%26");
      let email = '';
      let emailContent = await getEmailShareMsg(url1)
      window.location.href = "mailto:"+email+"?subject="+emailContent.emailSubject+"&body="+emailContent.emailBody;
    }

    const whatsAppShare = async () => {
          let url1 = shareLink.split("%2F").join("%252F");
          url1 = url1.split("&").join("%26");
          let uploadedURL = getTextShareMsg(url1)
          if (isMobile) {
            window.location.href = `whatsapp://send?text=${uploadedURL}`;
          }
          else{
            const a = document.createElement("a");
            a.href =`https://web.whatsapp.com/send?text=${uploadedURL}`;
            a.target = '_blank'
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
    };

    const facebookShare = async () => {
          navigator.clipboard.writeText(shareLink)
          const a = document.createElement("a");
          a.href =  `http://m.me/`;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
  };

    const sendSMS = async () => {
      setSmsLoading(true)
      let sms = getTextShareMsg()
      if(phone){
        getServer().sendSms({phone, sms: `RENTBOOK: ${sms}`}).then(()=>{
          console.log("sms sent ");
          setSmsLoading(false);
          setShowSMSModal(false);
          let config = {
            message: "Success",
            description: i18n.t(ShareModalTKeys.smsSuccess)
          };
          
          notification.success(config);
        })
        .catch((err) => {
          setSmsLoading(false)
          getSentry().captureException(err.message);
          let config = {
            message: i18n.t(CommonTKeys.error),
            description: i18n.t(ShareModalTKeys.smsError)
          };
          
          notification.error(config);
        })

      }
    }

    useEffect(() => {
        if(url){
         setShareLink(url);
         setLoading(false);

        }
    },[url])

    

    return (
        <>
        {showSMSModal? 
         <Modal
         onOk={sendSMS}
         okText={i18n.t(ShareModalTKeys.sendSMS)}
         okButtonProps={{ loading: smsLoading }}
         visible={showSMSModal}
         onCancel={() => {
           setShowSMSModal(false);
           setVisible(true)
         }}
        >
          <Form>
              <Form.Item label={i18n.t(ShareModalTKeys.enterPhone)}>
                  <PhoneInput
                    country={"ae"}
                    preferredCountries={["ae", "sa", "ng"]}
                    onChange={(e) => {
                      setPhone(`+${e}`)
                    }}
                  />
              </Form.Item>
          </Form>
         </Modal>
         :
         null
        }
       
         <Modal
          className="share-modal"
          title="Share"
          visible={visible}
          onCancel={() => closeModal()}
        >
          {loading? 
          <div>
            {i18n.t(CommonTKeys.loading)}
          </div>
          :
          <div>
          <div className="icons">
              <div>
              <a onClick={() => sendEmail()}>
                <div><MailOutlined /></div>
                <div className="label">
                  
                {i18n.t(ShareModalTKeys.email)}
                  
                 </div>
                 </a>
              </div>
              <div>
              <a onClick={() => {
                setShowSMSModal(true)
                setVisible(false)
                }}>
                  <div><PhoneOutlined /></div>
                  <div className="label">{i18n.t(ShareModalTKeys.phone)}</div>
              </a>
              </div>
              <div>
                <a onClick={() => facebookShare()}>
                <div><FacebookFilled /></div>
                <div className="label">{i18n.t(ShareModalTKeys.facebook)}</div>
                </a>
              </div>
              <div>
                <a onClick={() => whatsAppShare()}>
                <div><WhatsAppOutlined /></div>
                <div className="label">{i18n.t(ShareModalTKeys.whatsapp)}</div>
                </a>
              </div>
          </div>
          <div className='page-link'>
              {/* {report? 
              <Button type="primary" style={{width:'100%'}} onClick={()=>{copylink()}}>Copy Link</Button>
              : */}
              <div>
                <div>{i18n.t(ShareModalTKeys.shareLink)}</div>
                <div className="link" id="link">
                  <span>{ellipsize(shareLink, 40 )}<CopyOutlined onClick={()=>{copylink()}}/></span>
                </div>
              </div>
              <Button type="primary" style={{width:'100%',marginTop: '10px'}} onClick={()=>{copylink()}}>Copy Link</Button>
              {/* } */}
          </div>
          </div>
          }
        </Modal>
        </>
    )

}

export default ShareModal;