import * as React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { connect, useDispatch, useSelector } from 'react-redux';
import * as firebase from 'firebase';
//imported components within webapp
import './LoginPage.css';
import { i18n } from '../translations';
import { CommonTKeys, LoginPageTKeys } from '../translations/keys';
import { getService } from '../clients';
import { store } from '../clients/store';
import { getSentry } from '../clients';
import { getAnalytics, Events } from "../clients/analytics";
import { getServer } from "../clients/server";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { changeLanguage, updateUser } from '@hermes/shared/src/redux/user/actions';
import { getAssetsList } from "@hermes/shared/src/redux/assets/selectors";
import { Language, langAbbrMap, countriesArray } from '@hermes/schema';
//antd imports
import { Input, Select, Radio, Button, Alert, Row, Col, Card, ConfigProvider} from "antd";
import { RadioChangeEvent } from 'antd/lib/radio';
//other library imports
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import ReactGA from "react-ga";

const { Option } = Select;

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

const LoginPage: React.FunctionComponent<{}> = ({ }) => {
  const dispatch = useDispatch();
  const active = useSelector(
    (store: StoreState) => store.assets[store.ui.activeAsset || ""]
  );

  const phoneNumberFromURL = useParams().phoneNumber;

  const assets = useSelector(getAssetsList);
  const [error, setError] = useState({ key: '', value: false });
  const [number, setNumber] = useState('');
  const [name, setName] = useState<string>();
  const [language, setLanguage] = useState('english');
  const [id, setId] = useState<string>();
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState<'ltr' | 'rtl' | undefined>();
  const [resend, setResend] = useState(false);

  const createNewUser = (userId, userName) => {

    return getService().authService.createNewUser({
      id: userId,
      fields: {
      name: userName,
      phone_number: number,
      language: language as Language
      },
      extra:{

      }
    }).then(() => {
      setLoading(false);
      window.location.href = '/assets'
      getAnalytics().track(Events.SignUpComplete, { phone_number: number, id: userId});
      ReactGA.event({category: Events.SignUpComplete, action: `Clicked on ${Events.SignUpComplete}`});
    }).catch((error) => {
      getSentry().captureException(error);
      console.log(error)
    });

  }

  const collectDetails = () => {
    let name = (document.getElementsByName("name")[0] as HTMLInputElement).value;
    setName(name)
    if (name && number && language && id) {
      setLoading(true);
      createNewUser(id, name);
    }
    else {
      setError({ key: 'LoginPage.errorMsg', value: true });
      setLoading(false);
    }

  }

  const handleOTPChange = (value: string) => {
    if (value.length >= 7) {
      setError({ key: 'LoginPage.otpLengthError', value: true });
      return;
    }
    else {
      setOtp(value);
    }
  };

  const checkIfNumberExistsInLead = async (num: string) => {
    const leadData = await getService().leadQuery.getLeadByPhone(num);
    console.log(leadData.docs.length, "checking lead")
    if (leadData.docs.length === 0) {
      ReactGA.event({category: `Lead Collected: ${num}`, action: `WebApp`});
    }
    getServer()
      .getStarted({ phone: number })
      .then((result) => {
        console.log("lead added")
      })
    .catch((err) => {
        getSentry().captureException(err.message);
        console.log(err);
    });
  }

  const verifyOTP = () => {
    // let otp = (document.getElementsByName("otp")[0] as HTMLInputElement).value;
    // setOtp(otp);
    setLoading(true);
    getAnalytics().track(Events.ClickVerifyOtp, { phone_number: number});
    ReactGA.event({category: `${Events.ClickVerifyOtp} ${number}`, action: `WebApp`});
    if (!otp.length) {
      setError({ key: 'LoginPage.errorMsg', value: true });
      setLoading(false);
    }
    let code = otp;
    window.confirmationResult.confirm(code).then(async (result: any) => {
      let user = result.user;
      let userData = await getService().query.getUser(user.uid);
      if(userData && userData.doc){
        //subsequent time
          window.location.href = '/rentals'
          setLoading(false);
          getAnalytics().track(Events.LoginSuccess, { phone_number: userData.doc.phone_number, id: userData.id});
          ReactGA.event({category: `Login: ${number}`, action: `WebApp`});

          //update login success count and send sms if they don't have mobile app installed
          if (userData.doc.internal.login_count && userData.doc.internal.login_count % 10 == 0 && !userData.doc.internal.push_notification) {
             //send sms
             const sms = i18n.t(CommonTKeys.doYouKnowMessage);
             await getServer().sendSms({phone: userData.doc.phone_number, sms: `RENTBOOK: ${sms}`}).then(() => {
                dispatch(updateUser(
                  userData.id,
                  { internal: {
                    ...userData.doc.internal,
                    login_count: userData.doc.internal.login_count? userData.doc.internal.login_count++ : 1,
                  }}
                ));
             })
          }
      }
      else{
          //first time
          getAnalytics().track(Events.SignUpComplete, {id: user.uid, phone_number: number});
          ReactGA.event({category: `Signup: ${number}`, action: `WebApp`});
          setId(user.uid)
          setStep(2)
          setLoading(false);
      }
    }).catch((error) => {
      getAnalytics().track(Events.InvalidOTP, { phone_number: number});
      ReactGA.event({category: `${Events.InvalidOTP}: ${number}`, action: `WebApp`});
      getSentry().captureException(error);
      console.log(error);
      setError({ key: 'Common.notVerified', value: true });
      setLoading(false);
    });
  }

  const sendOTP = () => {
    // let phone = (document.getElementsByName("phone")[0] as HTMLInputElement).value;
    // setNumber(phone);
   let phone = number;
    if (!phone || !language) {
      setError({ key: 'LoginPage.errorMsg', value: true });
      return;
    } else if (phone.length <= 8) {
      setError({ key: 'LoginPage.errorMsg', value: true });
      return;
    }
    else {
      // let phone = (document.getElementsByName("phone")[0] as HTMLInputElement).value;
      checkIfNumberExistsInLead(number)
      getAnalytics().track(Events.SendOTP, { phone_number: number});
      ReactGA.event({category: `${Events.SendOTP} ${number}`, action: `WebApp`});
      setLoading(true);
      firebase.auth().languageCode = 'en';
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
          'size': 'invisible',
          'callback': function(response:any) {
              console.log("response", response)
          }
      })
      /* for testing without actually sending the message */
      // firebase.auth().settings.appVerificationDisabledForTesting = true;
      // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      /*******/
      let appVerifier = window.recaptchaVerifier;
      console.log({appVerifier});
      firebase.auth().signInWithPhoneNumber(phone, appVerifier)
        .then((confirmationResult) => {
          console.log({confirmationResult})
          window.confirmationResult = confirmationResult;
          setStep(1);
          setLoading(false);
          setError({ key: '', value: false });
        }).catch((error) => {
          getSentry().captureException(error);
          console.log(error)
          setLoading(false)
          setError({ key: 'Common.smsNotSent', value: true });
        });
    }
  }

  const resendOTP = () => {
    setResend(true);
   let phone = number;
   if (!phone || !language) {
     setError({ key: 'LoginPage.errorMsg', value: true });
     return;
   }
   else {
     setLoading(true);
     firebase.auth().languageCode = 'en';
     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("resend-button", {
         'size': 'invisible',
         'callback': function(response:any) {
             console.log("response", response)
         }
     })
     let appVerifier = window.recaptchaVerifier;
     firebase.auth().signInWithPhoneNumber(phone, appVerifier)
       .then((confirmationResult) => {
         window.confirmationResult = confirmationResult;
         setLoading(false);
         setError({ key: '', value: false });
       }).catch((error) => {
         getSentry().captureException(error);
         console.log(error)
         setLoading(false)
         setError({ key: 'Common.smsNotSent', value: true });
       });
   }
  };

  const handleLangChange = (lang: string) => {
    let language = lang;
    setLanguage(language);
    // TODO: Use dispatch hook after conversion
    i18n.changeLanguage(langAbbrMap[lang]);
    store.dispatch(changeLanguage(language as Language));
    if(lang === Language.Arabic || lang === Language.Urdu){
      setDirection('rtl')
    }
    else{
      setDirection('ltr')
    }
  }

  const closeError = () => {
    setError({ key: '', value: false });
  }

  useEffect(() => {
    // Update the document title using the browser API
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        let userData = await getService().query.getUser(user.uid);
        if(userData && userData.doc){
            window.location.href = '/rentals'
            setLoading(false);
        }
        else{
            console.log(user.uid);
            setId(user.uid)
            // setStep(2)
            setLoading(false);
        }
      }
    });

    if(phoneNumberFromURL && phoneNumberFromURL.length) {
      setNumber(phoneNumberFromURL);
    }

  }, []);

  return (
    <ConfigProvider direction={direction}>
    <div className="login-page">
      <img className="bg" src="/assets/bg.jpg" />
      <div className="overlay"></div>
      <div id="recaptcha-container"></div>

      <div className="page-content">
        <Card title={null} className="parent">
          <Row align="middle" justify="center">
            <Col span={12}>
              <div className="img-holder">
                <img src="/assets/login-loop.svg" />
              </div>
            </Col>
            <Col span={12}>
              <div className="form-container">
                <img className="logo" src="/assets/logo-dark.svg" />

                {step === 0 ? (
                  <div className="form">
                    <h2>{i18n.t("LoginPage.heading")}</h2>
                    {error.value ? (
                      <Alert
                        className="errMsg"
                        message={i18n.t(error.key)}
                        type="error"
                        closable
                        onClose={() => closeError()}
                      />
                    ) : null}
                    <div className="inner">
                      <Radio.Group
                        className="item"
                        value={language}
                        onChange={(e: RadioChangeEvent) =>
                          handleLangChange(e.target.value)
                        }
                      >
                        <Row gutter={[0, 10]}>
                          <Col span={6}>
                            <Radio.Button value={Language.English}>
                              English
                            </Radio.Button>
                          </Col>
                          <Col span={6}>
                            <Radio.Button value={Language.Hindi}>
                              हिन्दी
                            </Radio.Button>
                          </Col>
                          <Col span={6}>
                            <Radio.Button value={Language.Arabic}>
                              عربى
                            </Radio.Button>
                          </Col>
                          <Col span={6}>
                            <Radio.Button value={Language.Urdu}>
                              اردو
                            </Radio.Button>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </div>
                    <ConfigProvider direction='ltr'>
                    <div className="inner">
                      <PhoneInput
                        country={"ae"}
                        enableSearch
                        preferredCountries={countriesArray}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true
                        }}
                        value={number}
                        onChange={(e) => {
                          setNumber(`+${e}`);
                        }}
                      />
                    </div>
                    </ConfigProvider>
                    <div className="inner">
                      <Button
                        type="primary"
                        block
                        size="large"
                        id="sign-in-button"
                        loading={loading}
                        onClick={() => sendOTP()}
                      >
                        {i18n.t("Common.sendOTP")}
                      </Button>
                    </div>
                  </div>
                ) : null}

                {step === 1 ? (
                  <div className="form">
                    <h2>
                      {i18n.t("Common.verifyOTPMsg")}
                      <br />
                      {` ${number}`}
                    </h2>
                    {error.value ? (
                      <Alert
                        className="errMsg"
                        message={i18n.t(error.key)}
                        type="error"
                        closable
                        onClose={() => closeError()}
                      />
                    ) : null}
                    <div className="inner">
                      <Input
                        className="item"
                        size="large"
                        name="otp"
                        value={otp}
                        type="number"
                        onChange={(e) => handleOTPChange(e.target.value)}
                      />
                    </div>

                    <div className="inner">
                      <span>{i18n.t(LoginPageTKeys.didntGetOTP)}
                        <a id="resend-button" onClick={() => resendOTP()}>{" "}{i18n.t(LoginPageTKeys.resendText)}</a>
                        </span>
                    </div>

                    <div className="inner">
                      <Button
                        type="primary"
                        block
                        size="large"
                        loading={loading}
                        onClick={() => verifyOTP()}
                      >
                        {i18n.t("Common.verifyOTPButton")}
                      </Button>
                    </div>
                  </div>
                ) : null}

                {step === 2 ? (
                  <div className="form">
                    <h2>{i18n.t(LoginPageTKeys.enterName)}</h2>
                    {error.value ? (
                      <Alert
                        className="errMsg"
                        message={i18n.t(error.key)}
                        type="error"
                        closable
                        onClose={() => closeError()}
                      />
                    ) : null}
                    <div className="inner">
                      <Input className="item" size="large" name="name" />
                    </div>
                    <div className="inner">
                      <Button
                        type="primary"
                        block
                        size="large"
                        loading={loading}
                        onClick={() => collectDetails()}
                      >
                        {i18n.t(LoginPageTKeys.LoginMsg)}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      {/* <div className="left-poster">
          <img src="/assets/logo.svg" alt="RentBook-Logo" />
        </div> */}
      {/* {step === 0 ? (
          <div className="page-content">
            <div className="form-container">
              <img src="/assets/login-loop.svg" />
              <div className="form">
                <h2>{i18n.t("LoginPage.heading")}</h2>
                {error.value ? (
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                ) : null}
                <div className="inner">
                  <Radio.Group
                    className="item"
                    value={language}
                    onChange={(e: RadioChangeEvent) =>
                      handleLangChange(e.target.value)
                    }
                  >
                    <Row gutter={[10, 10]}>
                      <Col span={8}>
                        <Radio.Button value="english">English</Radio.Button>
                      </Col>
                      <Col span={8}>
                        <Radio.Button value="hindi">हिन्दी</Radio.Button>
                      </Col>
                      <Col span={8}>
                        <Radio.Button value="arabic">عربى</Radio.Button>
                      </Col>
                    </Row>
                  </Radio.Group>
                </div>

                <div className="inner">
                  <PhoneInput
                    country={"ae"}
                    preferredCountries={["ae", "sa"]}
                    inputProps={{
                      name: "phone",
                    }}
                  />
                </div>
                <div className="inner">
                  <Button
                    type="primary"
                    block
                    size="large"
                    id="sign-in-button"
                    loading={loading}
                    onClick={() => sendOTP()}
                  >
                    {i18n.t("Common.sendOTP")}
                  </Button>
                </div>
              </div>
            </div>

            <div id="recaptcha-container"></div>
          </div>
        ) : null}
        {step === 1 ? (
          <div className="page-content">
            <div className="header-container">
              <HeaderBar />
            </div>
            <div className="form-container">
              <div className="form">
                <h2>
                  {language === "english"
                    ? i18n.t("Common.verifyOTPMsg") + " " + number
                    : number + " " + i18n.t("Common.verifyOTPMsg")}
                </h2>
                {error.value ? (
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                ) : null}
                <div className="inner">
                  <Input
                    className="item"
                    size="large"
                    name="otp"
                    type="number"
                  />
                </div>
                <div className="inner">
                  <Button
                    type="primary"
                    block
                    size="large"
                    // style={{ backgroundColor: 'var(--primary)',
                    // color: 'var(--secondary)'}}
                    loading={loading}
                    onClick={() => verifyOTP()}
                  >
                    {i18n.t("Common.verifyOTPButton")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {step === 2 ? (
          <div className="page-content">
            <div className="header-container">
              <HeaderBar />
            </div>
            <div className="form-container">
              <div className="form">
                <h2>{i18n.t(LoginPageTKeys.enterName)}</h2>
                {error.value ? (
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                ) : null}
                <div className="inner">
                  <Input className="item" size="large" name="name" />
                </div>
                <div className="inner">
                  <Button
                    type="primary"
                    block
                    size="large"
                    // style={{ backgroundColor: 'var(--primary)',
                    // color: 'var(--secondary)'}}
                    loading={loading}
                    onClick={() => collectDetails()}
                  >
                    {i18n.t(LoginPageTKeys.LoginMsg)}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
    </div>
    </ConfigProvider>
  );
};

export default LoginPage;