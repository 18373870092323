import { Shared, FirestoreDocument } from "@hermes/schema";
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { action } from "typesafe-actions";
import { Service } from "../../business";
import { StoreState } from "../store/types";
import { ActionTypes } from "./constants";

export const getSharedSuccess = (payload: {[id: string]: FirestoreDocument<Shared>;}) => action(ActionTypes.GET_SHARED_SUCCESS, payload);
export const getShared = () => action(ActionTypes.GET_SHARED_REQUEST);
export const getSharedFailure = (message: string) => action(ActionTypes.GET_SHARED_FAILURE, message);

type SharedMap = {
    [id: string]: FirestoreDocument<Shared>;
};

export const loadShareById = (shareId: string): ThunkAction<Promise<SharedMap>, StoreState, {}, AnyAction> => {
    return (dispatch, getState, { service }: { service: Service }) => {
        return service.sharedQuery.get(shareId).then((shareData) => {
            const responseMap: SharedMap  = {};
                responseMap[shareData.id] = {
                    id: shareData.id,
                    doc: shareData.doc,
                };
            dispatch(getSharedSuccess(responseMap));
            return responseMap
        })
        .catch((err) => {
            console.log("comes here", err)
            dispatch(getSharedFailure(err.message));
            return {}
        })  
    }
}
