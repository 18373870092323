import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import AddContactModal from "../components/AddContactModal/AddContactModal";
import ContactDetails from "../components/ContactDetails/ContactDetails";
import ContactsTable from "../components/ContactsTable/ContactsTable";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "../translations/hooks";
import { CommonTKeys, ContactsPageTKeys } from "../translations/keys";
import { getAnalytics, Events } from "../clients/analytics";
import "./ContactsPage.css";
//shared folder imports
import {
  getPeopleList
} from "@hermes/shared/src/redux/people/selector";
import {
  loadPeople
} from "@hermes/shared/src/redux/people/actions";
import {
  People,
  FirestoreDocument
} from "@hermes/schema";
//antd imports
import { Button } from "antd";

const ContactsPage: React.FunctionComponent = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const {i18n, t} = useTranslation();
  const contacts = useSelector(getPeopleList);

  let { personId } = useParams();
  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const [selectedContact, setSelectedContact] = useState<FirestoreDocument<People>>();


  useEffect(() => {
    dispatch(loadPeople());
  }, []);

  useEffect(() => {
    contacts.forEach((contact) => {
      console.log(contact);
      if(contact.id === personId){
        setSelectedContact(contact);
        return;
      }
    });
  }, [contacts]);

  return (
    <>
      <div className="contacts-page">
        {showAddContactModal ? (
          <AddContactModal
            visible={showAddContactModal}
            closeModal={() => {
              setShowAddContactModal(false);
            }}
            record={undefined}
          />
        ) : null}

        <div className="page-content">

          {!personId && (
            <div className="heading">
              <h3>{i18n.t(ContactsPageTKeys.peopleHeading)}</h3>
              <div className="button-container">
                <Button
                  type="primary"
                  onClick={() => {
                    setShowAddContactModal(true);
                    getAnalytics().track(Events.ClickAddNewContact, {})
                  }}
                >
                  {i18n.t(ContactsPageTKeys.newPersonButton)}
                </Button>
              </div>
            </div>
          )}

          {!personId && (
            <ContactsTable contacts={contacts} showModal={() =>  setShowAddContactModal(true)} setSelectedContact={(record) => setSelectedContact(record)}/>
          )}

          {personId && selectedContact && selectedContact.id && (
            <ContactDetails record={selectedContact} />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ContactsPage;
