import * as firebase from 'firebase';

// firebase.auth().settings.appVerificationDisabledForTesting = true;

const webApp = firebase.initializeApp({
  apiKey: "AIzaSyCudr3ZvJ5iRAGYKfgD3Ku12a85vutY03s",
  authDomain: "hermes-5a3c3.firebaseapp.com",
  databaseURL: "https://hermes-5a3c3.firebaseio.com",
  projectId: "hermes-5a3c3",
  storageBucket: "hermes-5a3c3.appspot.com",
  messagingSenderId: "982116000138",
  appId: "1:982116000138:web:4960c0dc29bdcb41182873",
  measurementId: "G-SKH689DN57"
});

export const getWebFirebaseApp = () => webApp;
