import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase";
//imported components within webapp
import "./SettingsPage.css";
import Footer from "../components/Footer/Footer";
//shared folder imports
import { updateUser, changeLanguage } from "@hermes/shared/src/redux/user/actions";
import { Language, Currency, langAbbrMap, countriesArray, currenciesArray, countryCodes, currencyDefaultCode, languagesArray} from "@hermes/schema";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { useTranslation } from "../translations/hooks";
import { SettingsPageTKeys } from "../translations/keys";
import { getAnalytics, Events } from "../clients/analytics";
import { getSentry } from '../clients';
//antd imports
import {
  Input,
  Radio,
  Button,
  Alert,
  Row,
  Col,
  notification,
  Form,
  Spin,
  Card,
  Select
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
//other library imports
import PhoneInput from "react-phone-input-2";
import ReactPlayer from "react-player/lazy";


const { Option } = Select;

const SettingsPage: React.FunctionComponent<{}> = ({}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: StoreState) => state.user);

  const [language, setLanguage] = useState<Language>();
  const [currency, setCurrency] = useState<Currency>();
  const [error, setError] = useState({ key: "", value: false });
  const [name, setName] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [videoLoading, setVideoLoading] = useState<boolean>(true);
  const [email, setEmail] = useState<string>();
  const {i18n, t} = useTranslation();
  

  const signOut = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("sign out successful");
        window.location.href = "/login";
      })
      .catch((error) => {
        // An error happened.
        getSentry().captureException(error);
        console.log(error);
      });
  };


  const handleOk = (lang=language, curr=currency) => {
    if (name && lang && curr) {
      let obj = {
        name: name,
        phone_number: user.user.doc.phone_number,
        language: lang,
        currency: curr,
        email: email,
        internal: {
          created_at: user.user.doc.internal.created_at,
          updated_at: firebase.firestore.Timestamp.fromDate(new Date()),
        }
      };
      dispatch(updateUser(user.user.id, obj)).then(() => {
        let config = {
          message: i18n.t(SettingsPageTKeys.userSavedHeading),
          description: i18n.t(SettingsPageTKeys.userSavedDesc),
        };
        notification.success(config);
      });
    }
    else{  
        if(!name){
          setError({key: SettingsPageTKeys.nameErrMsg, value:true});
        }
        else if(!lang){
          setError({key: SettingsPageTKeys.langErrMsg, value:true});
        }
        else if(!curr){
          setError({key: SettingsPageTKeys.currErrMsg, value:true});
        }
        // let config = {
        //   message: i18n.t(SettingsPageTKeys.errHeading),
        //   description: i18n.t(SettingsPageTKeys.errMsg),
        // };
        // notification.error(config);   
    }
  };


  const closeError = () => {
    setError({ key: "", value: false });
  };
  
  useEffect(() => {
    if (!language) {
      setLanguage(user.user.doc.language);
    }
    if (!name) {
      setName(user.user.doc.name);
    }
    if (!email) {
      if (user.user.doc.email) {
        setEmail(user.user.doc.email);
      } else {
        setEmail("");
      }
    }
    if (!currency) {
        setCurrency(user.user.doc.currency);
    }
  }, []);

  useEffect(() => {
    if(language)(
      i18n.changeLanguage(langAbbrMap[language])
    )
  }, [language]);

  return (
    <div className="settings-page">
      <div className="page-content">
        <div className="content">
          <Row justify="end" gutter={[0, 15]}>
            <Col span={24}>
              <div className="heading">
                <h3>{i18n.t(SettingsPageTKeys.heading)}</h3>
              </div>
              <Card className="form-container">
                <Row>
                  <Col span={10} className="img-holder">
                    <img src="/assets/profile-loop.svg" />
                  </Col>
                  <Col span={14} className="form-col">
                    <Form
                      layout="horizontal"
                      labelCol={{ span: 5 }}
                      labelAlign="right"
                      className="form"
                    >
                      {error.value ? (
                        <Alert
                          className="errMsg"
                          message={i18n.t(error.key)}
                          type="error"
                          closable
                          onClose={() => closeError()}
                        />
                      ) : null}
                      <div className="inner">
                        <Form.Item label={i18n.t(SettingsPageTKeys.nameLabel)}>
                          <Input
                            className="item"
                            value={name}
                            placeholder={i18n.t(
                              SettingsPageTKeys.namePlaceholder
                            )}
                            name="name"
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                            onBlur={() => {
                              handleOk();
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="inner">
                        <Form.Item label={i18n.t(SettingsPageTKeys.emailLabel)}>
                          <Input
                            className="item"
                            value={email}
                            placeholder={i18n.t(
                              SettingsPageTKeys.emailPlaceholder
                            )}
                            name="email"
                            type="email"
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            onBlur={() => {
                              handleOk();
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="inner">
                        <Form.Item
                          label={i18n.t(SettingsPageTKeys.currencyLabel)}
                        >
                          <Select
                            className="item"
                            value={currency}
                            placeholder={i18n.t(
                              SettingsPageTKeys.currencyplaceholder
                            )}
                            onChange={(e) => {
                              setCurrency(e);
                              handleOk(language, e);
                            }}
                          >
                            {currenciesArray.map((v) => {
                              return (
                                <Option value={v.value}>{v.label}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="inner">
                        <Form.Item label={i18n.t(SettingsPageTKeys.phoneLabel)}>
                          <PhoneInput
                            country={currencyDefaultCode[user.user.doc.phone_number.slice(0,4)]}
                            preferredCountries={countriesArray}
                            inputProps={{
                              name: "phone",
                              value: user.user.doc.phone_number,
                            }}
                            disabled
                          />
                        </Form.Item>
                      </div>
                      <div className="inner">
                        <Form.Item
                          label={i18n.t(SettingsPageTKeys.languageLabel)}
                        >
                          <Select
                            showSearch
                            className="item"
                            value={language}
                            placeholder={i18n.t(
                              SettingsPageTKeys.languageplaceholder
                            )}
                            onChange={(e) => {
                              setLanguage(e);
                              handleOk(e);
                            }}
                          >
                            {languagesArray.map((v) => {
                              return (
                                <Option value={v.key}>{v.label}</Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <div className="heading">
                <h3>{i18n.t(SettingsPageTKeys.getStartedHeading)}</h3>
              </div>

              <Card>
                <Col span={10} className="img-holder">
                  <img src="/assets/video-loop.svg" />
                </Col>
                <Col span={14}>
                  <Spin
                    spinning={videoLoading}
                    tip={i18n.t(SettingsPageTKeys.loadingVideo)}
                  >
                    {/* <ReactPlayer
                    
                    {/* 
                      // @ts-ignore */}
                    <ReactPlayer
                      className="video"
                      url="https://www.youtube.com/watch?v=PEWZnqG07JQ"
                      onReady={() => {
                        console.log("loaded");
                        setVideoLoading(false);
                      }}
                    />
                  </Spin>
                </Col>
              </Card>
            </Col>
            <Col span={8}>
              <div className="heading">
                <Button
                  block
                  type="primary"
                  size="large"
                  danger
                  loading={loading}
                  onClick={() => {
                    getAnalytics().track(Events.ClickLogout, {})
                    signOut()
                  }}
                >
                  {i18n.t(SettingsPageTKeys.logout)}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SettingsPage;
