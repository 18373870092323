// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum AddAssetModalTKeys {
	assetAddedDescription = 'AddAssetModal.assetAddedDescription',
	assetAddedTitle = 'AddAssetModal.assetAddedTitle',
	assetDescriptionLabel = 'AddAssetModal.assetDescriptionLabel',
	assetDescriptionPlaceholder = 'AddAssetModal.assetDescriptionPlaceholder',
	assetIdLabel = 'AddAssetModal.assetIdLabel',
	assetIdPlaceholder = 'AddAssetModal.assetIdPlaceholder',
	assetNameLabel = 'AddAssetModal.assetNameLabel',
	assetNamePlaceholder = 'AddAssetModal.assetNamePlaceholder',
	assetStatusLabel = 'AddAssetModal.assetStatusLabel',
	cancel = 'AddAssetModal.cancel',
	heading = 'AddAssetModal.heading',
	idle = 'AddAssetModal.idle',
	imagesLabel = 'AddAssetModal.imagesLabel',
	modalOkText = 'AddAssetModal.modalOkText',
	nameError = 'AddAssetModal.nameError',
	rentedOut = 'AddAssetModal.rentedOut',
	upload = 'AddAssetModal.upload',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum AddPersonModalTKeys {
	contactNameLabel = 'AddPersonModal.contactNameLabel',
	contactNamePlaceholder = 'AddPersonModal.contactNamePlaceholder',
	contactTypeLabel = 'AddPersonModal.contactTypeLabel',
	contactTypePlaceholder = 'AddPersonModal.contactTypePlaceholder',
	customerOption = 'AddPersonModal.customerOption',
	errMsg = 'AddPersonModal.errMsg',
	heading = 'AddPersonModal.heading',
	nameErrMsg = 'AddPersonModal.nameErrMsg',
	personAddedDescription = 'AddPersonModal.personAddedDescription',
	personAddedTitle = 'AddPersonModal.personAddedTitle',
	phoneErrMsg = 'AddPersonModal.phoneErrMsg',
	phoneNumberLabel = 'AddPersonModal.phoneNumberLabel',
	supplierOption = 'AddPersonModal.supplierOption',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum AssetsPageTKeys {
	allAssets = 'AssetsPage.allAssets',
	assetUpdatedDesc = 'AssetsPage.assetUpdatedDesc',
	assetUpdatedHeading = 'AssetsPage.assetUpdatedHeading',
	assetsHeading = 'AssetsPage.assetsHeading',
	deleteAsset = 'AssetsPage.deleteAsset',
	deleteAssetHeading = 'AssetsPage.deleteAssetHeading',
	deleteImageHeading = 'AssetsPage.deleteImageHeading',
	docAddedDesc = 'AssetsPage.docAddedDesc',
	docAddedHeading = 'AssetsPage.docAddedHeading',
	docNamePlaceholder = 'AssetsPage.docNamePlaceholder',
	docReminder = 'AssetsPage.docReminder',
	docsHeading = 'AssetsPage.docsHeading',
	emptyAssets = 'AssetsPage.emptyAssets',
	emptyAssetsLink = 'AssetsPage.emptyAssetsLink',
	idleStatus = 'AssetsPage.idleStatus',
	imagesAddedDesc = 'AssetsPage.imagesAddedDesc',
	imagesAddedHeading = 'AssetsPage.imagesAddedHeading',
	newAssetButton = 'AssetsPage.newAssetButton',
	noDocs = 'AssetsPage.noDocs',
	paymentsLinkedDesc = 'AssetsPage.paymentsLinkedDesc',
	paymentsLinkedHeading = 'AssetsPage.paymentsLinkedHeading',
	rentedOutStatus = 'AssetsPage.rentedOutStatus',
	shareAllAssets = 'AssetsPage.shareAllAssets',
	shareAsset = 'AssetsPage.shareAsset',
	statusLabel = 'AssetsPage.statusLabel',
	uploadDoc = 'AssetsPage.uploadDoc',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum CommonTKeys {
	actionLabel = 'Common.actionLabel',
	assetLowerText = 'Common.assetLowerText',
	assetsLowerText = 'Common.assetsLowerText',
	cancel = 'Common.cancel',
	delete = 'Common.delete',
	descLabel = 'Common.descLabel',
	doYouKnowMessage = 'Common.doYouKnowMessage',
	edit = 'Common.edit',
	emailBody = 'Common.emailBody',
	emailBodyForReceipt = 'Common.emailBodyForReceipt',
	emailBodyForReport = 'Common.emailBodyForReport',
	emailSubject = 'Common.emailSubject',
	emailSubjectForReceipt = 'Common.emailSubjectForReceipt',
	emailSubjectForReport = 'Common.emailSubjectForReport',
	error = 'Common.error',
	loading = 'Common.loading',
	nameLabel = 'Common.nameLabel',
	no = 'Common.no',
	notVerified = 'Common.notVerified',
	receiptLowerText = 'Common.receiptLowerText',
	receiptsLowerText = 'Common.receiptsLowerText',
	sendOTP = 'Common.sendOTP',
	smsNotSent = 'Common.smsNotSent',
	textMessage = 'Common.textMessage',
	textMessageForReceipt = 'Common.textMessageForReceipt',
	textMessageForReport = 'Common.textMessageForReport',
	verifyOTPButton = 'Common.verifyOTPButton',
	verifyOTPMsg = 'Common.verifyOTPMsg',
	viewDetailsButton = 'Common.viewDetailsButton',
	yes = 'Common.yes',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum ContactsPageTKeys {
	allPeople = 'ContactsPage.allPeople',
	basicDetails = 'ContactsPage.basicDetails',
	deleteContact = 'ContactsPage.deleteContact',
	deleteContactConfirm = 'ContactsPage.deleteContactConfirm',
	emptyPeopleDesc = 'ContactsPage.emptyPeopleDesc',
	emptyPeopleLink = 'ContactsPage.emptyPeopleLink',
	newPersonButton = 'ContactsPage.newPersonButton',
	paymentsLinkedDesc = 'ContactsPage.paymentsLinkedDesc',
	paymentsLinkedHeading = 'ContactsPage.paymentsLinkedHeading',
	peopleHeading = 'ContactsPage.peopleHeading',
	phoneNumber = 'ContactsPage.phoneNumber',
	typeLabel = 'ContactsPage.typeLabel',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum CreateAccountPageTKeys {
	LoginMsg = 'CreateAccountPage.LoginMsg',
	errorMsg = 'CreateAccountPage.errorMsg',
	fullNamePlaceholder = 'CreateAccountPage.fullNamePlaceholder',
	heading = 'CreateAccountPage.heading',
	mobileNo = 'CreateAccountPage.mobileNo',
	userExists = 'CreateAccountPage.userExists',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum CurrencySelectorModalTKeys {
	cancel = 'CurrencySelectorModal.cancel',
	okText = 'CurrencySelectorModal.okText',
	selectCurr = 'CurrencySelectorModal.selectCurr',
	title = 'CurrencySelectorModal.title',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum EditPersonModalTKeys {
	contactNameLabel = 'EditPersonModal.contactNameLabel',
	contactNamePlaceholder = 'EditPersonModal.contactNamePlaceholder',
	contactTypeLabel = 'EditPersonModal.contactTypeLabel',
	contactTypePlaceholder = 'EditPersonModal.contactTypePlaceholder',
	countryCodeErrorMessage = 'EditPersonModal.countryCodeErrorMessage',
	customerOption = 'EditPersonModal.customerOption',
	errMsg = 'EditPersonModal.errMsg',
	heading = 'EditPersonModal.heading',
	okText = 'EditPersonModal.okText',
	personUpdatedDescription = 'EditPersonModal.personUpdatedDescription',
	personUpdatedTitle = 'EditPersonModal.personUpdatedTitle',
	phoneNumberLabel = 'EditPersonModal.phoneNumberLabel',
	supplierOption = 'EditPersonModal.supplierOption',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum LoginPageTKeys {
	LoginMsg = 'LoginPage.LoginMsg',
	SignUpMsg = 'LoginPage.SignUpMsg',
	didntGetOTP = 'LoginPage.didntGetOTP',
	enterName = 'LoginPage.enterName',
	errorMsg = 'LoginPage.errorMsg',
	heading = 'LoginPage.heading',
	mobileNo = 'LoginPage.mobileNo',
	otpLengthError = 'LoginPage.otpLengthError',
	resendText = 'LoginPage.resendText',
	userExistError = 'LoginPage.userExistError',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum PaymentModalTKeys {
	addAsset = 'PaymentModal.addAsset',
	addPaymentHeading = 'PaymentModal.addPaymentHeading',
	addPerson = 'PaymentModal.addPerson',
	amountZeroError = 'PaymentModal.amountZeroError',
	assetErrorMsg = 'PaymentModal.assetErrorMsg',
	assetLabel = 'PaymentModal.assetLabel',
	dailyDurationErrorMessage = 'PaymentModal.dailyDurationErrorMessage',
	dailyRate = 'PaymentModal.dailyRate',
	deleteRental = 'PaymentModal.deleteRental',
	descriptionLabel = 'PaymentModal.descriptionLabel',
	descriptionPlaceholder = 'PaymentModal.descriptionPlaceholder',
	durationEmptyMessage = 'PaymentModal.durationEmptyMessage',
	durationErrorMsg = 'PaymentModal.durationErrorMsg',
	durationLabel = 'PaymentModal.durationLabel',
	durationPlaceholder = 'PaymentModal.durationPlaceholder',
	editOkText = 'PaymentModal.editOkText',
	editPaymentHeading = 'PaymentModal.editPaymentHeading',
	endDateLabel = 'PaymentModal.endDateLabel',
	endDatePlaceholder = 'PaymentModal.endDatePlaceholder',
	errorMsg = 'PaymentModal.errorMsg',
	expenseOption = 'PaymentModal.expenseOption',
	incomeOption = 'PaymentModal.incomeOption',
	monthlyDurationErrorMessage = 'PaymentModal.monthlyDurationErrorMessage',
	monthlyRate = 'PaymentModal.monthlyRate',
	onGoingDesc = 'PaymentModal.onGoingDesc',
	onGoingLabel = 'PaymentModal.onGoingLabel',
	oneTimeRate = 'PaymentModal.oneTimeRate',
	paymentAddedDescription = 'PaymentModal.paymentAddedDescription',
	paymentAddedTitle = 'PaymentModal.paymentAddedTitle',
	paymentTypeLabel = 'PaymentModal.paymentTypeLabel',
	paymentTypePlaceholder = 'PaymentModal.paymentTypePlaceholder',
	paymentUpdatedDescription = 'PaymentModal.paymentUpdatedDescription',
	paymentUpdatedTitle = 'PaymentModal.paymentUpdatedTitle',
	personLabel = 'PaymentModal.personLabel',
	rateErrorMsg = 'PaymentModal.rateErrorMsg',
	rateLabel = 'PaymentModal.rateLabel',
	ratePlaceholder = 'PaymentModal.ratePlaceholder',
	rateTypeErrorMsg = 'PaymentModal.rateTypeErrorMsg',
	rateTypeLabel = 'PaymentModal.rateTypeLabel',
	rateTypePlaceholder = 'PaymentModal.rateTypePlaceholder',
	rentalDetailsHeading = 'PaymentModal.rentalDetailsHeading',
	selectAsset = 'PaymentModal.selectAsset',
	selectPerson = 'PaymentModal.selectPerson',
	startDateErrorMsg = 'PaymentModal.startDateErrorMsg',
	startDateLabel = 'PaymentModal.startDateLabel',
	startDatePlaceholder = 'PaymentModal.startDatePlaceholder',
	weeklyRate = 'PaymentModal.weeklyRate',
	yearlyDurationErrorMessage = 'PaymentModal.yearlyDurationErrorMessage',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum RentalWarningModalTKeys {
	warningMsg = 'RentalWarningModal.warningMsg',
	warningTitle = 'RentalWarningModal.warningTitle',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum RentalsPageTKeys {
	addReceiptButton = 'RentalsPage.addReceiptButton',
	addReceiptModal = 'RentalsPage.addReceiptModal',
	addReceiptModalButton = 'RentalsPage.addReceiptModalButton',
	allRentals = 'RentalsPage.allRentals',
	amountReceiptModal = 'RentalsPage.amountReceiptModal',
	assetsLabel = 'RentalsPage.assetsLabel',
	cancel = 'RentalsPage.cancel',
	columnAssetText = 'RentalsPage.columnAssetText',
	columnTypeText = 'RentalsPage.columnTypeText',
	daily = 'RentalsPage.daily',
	dayText = 'RentalsPage.dayText',
	daysText = 'RentalsPage.daysText',
	deleteRentalConfirmation = 'RentalsPage.deleteRentalConfirmation',
	descPlaceholderReceiptModal = 'RentalsPage.descPlaceholderReceiptModal',
	descReceiptModal = 'RentalsPage.descReceiptModal',
	descriptionColumnText = 'RentalsPage.descriptionColumnText',
	docGeneratedTimeText = 'RentalsPage.docGeneratedTimeText',
	downloadPdf = 'RentalsPage.downloadPdf',
	durationLabel = 'RentalsPage.durationLabel',
	editDetails = 'RentalsPage.editDetails',
	editReceiptModal = 'RentalsPage.editReceiptModal',
	editReceiptModalButton = 'RentalsPage.editReceiptModalButton',
	emptyConnectedRentals = 'RentalsPage.emptyConnectedRentals',
	emptyDesc = 'RentalsPage.emptyDesc',
	emptyLink = 'RentalsPage.emptyLink',
	endColumnText = 'RentalsPage.endColumnText',
	expense = 'RentalsPage.expense',
	expenseButton = 'RentalsPage.expenseButton',
	expenseText = 'RentalsPage.expenseText',
	grandTotalText = 'RentalsPage.grandTotalText',
	heading = 'RentalsPage.heading',
	incomeText = 'RentalsPage.incomeText',
	monthText = 'RentalsPage.monthText',
	monthly = 'RentalsPage.monthly',
	monthsText = 'RentalsPage.monthsText',
	noReceipts = 'RentalsPage.noReceipts',
	oneTime = 'RentalsPage.oneTime',
	ongoingText = 'RentalsPage.ongoingText',
	paymentLabel = 'RentalsPage.paymentLabel',
	personColumnText = 'RentalsPage.personColumnText',
	profit = 'RentalsPage.profit',
	rateColumnText = 'RentalsPage.rateColumnText',
	rateTypeLabel = 'RentalsPage.rateTypeLabel',
	receiptAdded = 'RentalsPage.receiptAdded',
	receiptAddedDesc = 'RentalsPage.receiptAddedDesc',
	receiptEdited = 'RentalsPage.receiptEdited',
	receiptEditedDesc = 'RentalsPage.receiptEditedDesc',
	receiptImages = 'RentalsPage.receiptImages',
	rentalButton = 'RentalsPage.rentalButton',
	rentalHistory = 'RentalsPage.rentalHistory',
	rentalReport = 'RentalsPage.rentalReport',
	reportDownloaded = 'RentalsPage.reportDownloaded',
	reportDownloadedDesc = 'RentalsPage.reportDownloadedDesc',
	reportError = 'RentalsPage.reportError',
	reportErrorDesc = 'RentalsPage.reportErrorDesc',
	revenue = 'RentalsPage.revenue',
	shareAllReceipts = 'RentalsPage.shareAllReceipts',
	shareReceipt = 'RentalsPage.shareReceipt',
	shareReport = 'RentalsPage.shareReport',
	startColumnText = 'RentalsPage.startColumnText',
	totalColumnText = 'RentalsPage.totalColumnText',
	totalExpensesText = 'RentalsPage.totalExpensesText',
	totalIncomeText = 'RentalsPage.totalIncomeText',
	weekText = 'RentalsPage.weekText',
	weekly = 'RentalsPage.weekly',
	weeksText = 'RentalsPage.weeksText',
	yearText = 'RentalsPage.yearText',
	yearly = 'RentalsPage.yearly',
	yearsText = 'RentalsPage.yearsText',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum SettingsPageTKeys {
	currErrMsg = 'SettingsPage.currErrMsg',
	currencyLabel = 'SettingsPage.currencyLabel',
	currencyplaceholder = 'SettingsPage.currencyplaceholder',
	emailLabel = 'SettingsPage.emailLabel',
	emailPlaceholder = 'SettingsPage.emailPlaceholder',
	errHeading = 'SettingsPage.errHeading',
	errMsg = 'SettingsPage.errMsg',
	getStartedHeading = 'SettingsPage.getStartedHeading',
	heading = 'SettingsPage.heading',
	langErrMsg = 'SettingsPage.langErrMsg',
	languageLabel = 'SettingsPage.languageLabel',
	languageplaceholder = 'SettingsPage.languageplaceholder',
	loadingVideo = 'SettingsPage.loadingVideo',
	logout = 'SettingsPage.logout',
	nameErrMsg = 'SettingsPage.nameErrMsg',
	nameLabel = 'SettingsPage.nameLabel',
	namePlaceholder = 'SettingsPage.namePlaceholder',
	phoneLabel = 'SettingsPage.phoneLabel',
	phoneplaceholder = 'SettingsPage.phoneplaceholder',
	userSavedDesc = 'SettingsPage.userSavedDesc',
	userSavedHeading = 'SettingsPage.userSavedHeading',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum ShareModalTKeys {
	assetShareError = 'ShareModal.assetShareError',
	copiedDesc = 'ShareModal.copiedDesc',
	copiedHeading = 'ShareModal.copiedHeading',
	email = 'ShareModal.email',
	enterPhone = 'ShareModal.enterPhone',
	facebook = 'ShareModal.facebook',
	inquiryText = 'ShareModal.inquiryText',
	inquiryText2 = 'ShareModal.inquiryText2',
	phone = 'ShareModal.phone',
	sendSMS = 'ShareModal.sendSMS',
	shareLink = 'ShareModal.shareLink',
	smsError = 'ShareModal.smsError',
	smsSuccess = 'ShareModal.smsSuccess',
	whatsapp = 'ShareModal.whatsapp',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum SharedAssetViewerTKeys {
	documentsAvailable = 'SharedAssetViewer.documentsAvailable',
	noDocAvailable = 'SharedAssetViewer.noDocAvailable',
	sendInquiry = 'SharedAssetViewer.sendInquiry',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum SharedFileViewerPageTKeys {
	download = 'SharedFileViewerPage.download',
	downloading = 'SharedFileViewerPage.downloading',
	fileDownload = 'SharedFileViewerPage.fileDownload',
	openInApp = 'SharedFileViewerPage.openInApp',
	tryOutRentbook = 'SharedFileViewerPage.tryOutRentbook',
	tryRentbook = 'SharedFileViewerPage.tryRentbook',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum SharedReceiptViewTKeys {
	amountReceived = 'SharedReceiptView.amountReceived',
	asset = 'SharedReceiptView.asset',
	billedTo = 'SharedReceiptView.billedTo',
	description = 'SharedReceiptView.description',
	duration = 'SharedReceiptView.duration',
	invoice = 'SharedReceiptView.invoice',
	invoiceDate = 'SharedReceiptView.invoiceDate',
	preparedBy = 'SharedReceiptView.preparedBy',
	rate = 'SharedReceiptView.rate',
	rateType_Daily = 'SharedReceiptView.rateType_Daily',
	rateType_Monthly = 'SharedReceiptView.rateType_Monthly',
	rateType_OneTime = 'SharedReceiptView.rateType_OneTime',
	rateType_Weekly = 'SharedReceiptView.rateType_Weekly',
	rateType_Yearly = 'SharedReceiptView.rateType_Yearly',
	receipt = 'SharedReceiptView.receipt',
	receiptDate = 'SharedReceiptView.receiptDate',
}

// DONT EDIT THIS FILE. AUTOGENERATED. 
export enum SideBarTKeys {
	assetsInUse = 'SideBar.assetsInUse',
	assetsLabel = 'SideBar.assetsLabel',
	peopleLabel = 'SideBar.peopleLabel',
	rentalRevenue = 'SideBar.rentalRevenue',
	rentalsLabel = 'SideBar.rentalsLabel',
	settingsLabel = 'SideBar.settingsLabel',
}

