import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import AddRentalModal from "../components/AddRentalModal/AddRentalModal";
import RentalsTable from "../components/RentalsTable/RentalsTable";
import RentalDetails from "../components/RentalDetails/RentalDetails";
import Footer from "../components/Footer/Footer";
import { useTranslation } from "../translations/hooks";
import { RentalsPageTKeys } from "../translations/keys";
import { getAnalytics, Events } from "../clients/analytics";
import ShareModal from "../components/ShareModal/ShareModal";
import { getSentry } from '../clients';
import { getWebFirebaseApp } from "../clients/firebase";
import "./RentalsPage.css";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
  getPaymentsList,
  getTotals
} from "@hermes/shared/src/redux/payments/selector";
import {
  Payment,
  FirestoreDocument,
  PaymentType,
} from "@hermes/schema";
import {
  loadPayments,
} from "@hermes/shared/src/redux/payments/actions";
import {
  loadAssets
} from "@hermes/shared/src/redux/assets/actions";
import { generatePDFReport, GeneratePDFReportForm } from "@hermes/shared/src/business/functions/generatePDF";
//antd imports
import { Button, Modal, Card, notification, Row, Col } from "antd";
import {
  FileProtectOutlined,
  ShareAltOutlined
} from "@ant-design/icons";


const RentalsPage: React.FunctionComponent = () => {
  const {i18n, t} = useTranslation();
  const dispatch = useDispatch();
  const rentals = useSelector(getPaymentsList);
  const total = useSelector(getTotals);
  const user = useSelector((state: StoreState) => state.user);
  let currency = user.user.doc.currency;
  const uuidv4 = require('uuid/v4')
  const uniqueDocId = uuidv4()
  const locale = i18n.language;

  const storageRef = getWebFirebaseApp().storage().ref();
  const documentRef = storageRef.child("shared-documents").child(user.user.id).child(uniqueDocId);

  let { rentalId } = useParams();

  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [showAddRentalModal , setShowAddRentalModal] = useState(false);
  const [selectedRental, setSelectedRental] = useState<FirestoreDocument<Payment>>();
  const [shareModal, setShareModal] = useState(false)
  const [shareLoading, setShareLoading] = useState(false)
  const [url, setUrl] = useState<string>('')

  const form: GeneratePDFReportForm = {
    templateType: "both",
    payments: rentals.map((a) => a.doc),
    docGeneratedTimeText: t(RentalsPageTKeys.docGeneratedTimeText),
    columnTypeText: t(RentalsPageTKeys.columnTypeText),
    columnAssetText: t(RentalsPageTKeys.columnAssetText),
    personColumnText: t(RentalsPageTKeys.personColumnText),
    descriptionColumnText: t(RentalsPageTKeys.descriptionColumnText),
    startColumnText: t(RentalsPageTKeys.startColumnText),
    endColumnText: t(RentalsPageTKeys.endColumnText),
    rateTypeColumnText: t(RentalsPageTKeys.rateTypeLabel),
    rateColumnText: t(RentalsPageTKeys.rateColumnText),
    totalColumnText: t(RentalsPageTKeys.totalColumnText),
    totalIncomeText: t(RentalsPageTKeys.totalIncomeText),
    totalExpensesText: t(RentalsPageTKeys.totalExpensesText),
    grandTotalText: t(RentalsPageTKeys.grandTotalText),
    incomeText: t(RentalsPageTKeys.incomeText),
    expenseText: t(RentalsPageTKeys.expenseText),
    ongoingText: t(RentalsPageTKeys.ongoingText),
    dailyText: t(RentalsPageTKeys.daily),
    durationText: t(RentalsPageTKeys.durationLabel),
    monthlyText: t(RentalsPageTKeys.monthly),
    weeklyText: t(RentalsPageTKeys.weekly),
    yearlyText: t(RentalsPageTKeys.yearly),
    oneText: t(RentalsPageTKeys.oneTime),
    onTimeText: t(RentalsPageTKeys.oneTime),
    weekText: t(RentalsPageTKeys.weekText),
    weeksText: t(RentalsPageTKeys.weeksText),
    monthText: t(RentalsPageTKeys.monthText),
    monthsText: t(RentalsPageTKeys.monthsText),
    yearText: t(RentalsPageTKeys.yearText),
    yearsText: t(RentalsPageTKeys.yearsText),
    dayText: t(RentalsPageTKeys.dayText),
    daysText: t(RentalsPageTKeys.daysText),
    lang: locale,

}

  const generatePDF = async (share=false) => {
    generatePDFReport(form)
      .then((result) => {
        console.log(result)
        const linkSource = `data:application/pdf;base64,${result}`;
        if(share){
          documentRef.putString(linkSource, 'data_url').then(function(snapshot) {
            return snapshot.ref.getDownloadURL();
          })
          .then((downloadURL) => {
            setUrl(downloadURL)
            setShareModal(true)
          });
        }
        else{
          const downloadLink = document.createElement("a");
          const fileName = "Payment Report.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
         
          let config = {
            message: i18n.t(RentalsPageTKeys.reportDownloaded),
            description: i18n.t(RentalsPageTKeys.reportDownloadedDesc),
          };
          notification.success(config);
        }
       
        getAnalytics().track(Events.ClickGeneratePDF, {
          url: linkSource
        });
      })
      .catch((err) => {
        console.log(err);
        getSentry().captureException(err.message);
        let config = {
          message: i18n.t(RentalsPageTKeys.reportError),
          description:
          i18n.t(RentalsPageTKeys.reportErrorDesc),
        };
        notification.error(config);
      });
  };

  useEffect(() => {
    dispatch(loadPayments());
    dispatch(loadAssets())
  }, []);

  // useEffect(() => {
  //   rentals.forEach((rental) => {
  //     console.log(rental);
  //     if(rental.id === rentalId){
  //       setSelectedRental(rental);
  //       return;
  //     }
  //   });
  // }, [rentals]);

  return (
    <>
      <div className="rentals-page">
      {/* {shareModal?  */}
      <ShareModal
        assetNames={''}
        loading={shareLoading}
        visible={shareModal}
        closeModal={() => setShareModal(false)}
        setLoading={(bool) => setShareLoading(bool)}
        setVisible={(bool) => setShareModal(bool)}
        type='payment-report'
        url={url}
      />
      {/* :null} */}
        {showAddRentalModal && paymentType ? (
          <AddRentalModal
            visible={showAddRentalModal}
            closeModal={() => {
              setShowAddRentalModal(false);
            }}
            type={paymentType}
          />
        ) : null}

        <div className="page-content">
          {!rentalId && (
            <div className="heading">
              <h3>{i18n.t(RentalsPageTKeys.heading)}</h3>
              <div className="button-container">
                <Button
                  type="primary"
                  onClick={() => {
                    setPaymentType(PaymentType.Income);
                    setShowAddRentalModal(true);
                    getAnalytics().track(Events.ClickNewRental, {});
                  }}
                  className="rental"
                >
                  {i18n.t(RentalsPageTKeys.rentalButton)}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setPaymentType(PaymentType.Expense);
                    setShowAddRentalModal(true);
                    getAnalytics().track(Events.ClickNewExpense, {});
                  }}
                  className="expense"
                >
                  {i18n.t(RentalsPageTKeys.expenseButton)}
                </Button>
                <Button
                  icon={<ShareAltOutlined />}
                  onClick={() => {
                    generatePDF(true)
                  }}
                >
                  {i18n.t(RentalsPageTKeys.shareReport)}
                </Button>
              </div>
            </div>
          )}

          {!rentalId && (
            <Card title={null} className="report-container">
              <Row justify="space-around">
                <Col span={8}>
                  <h3>{i18n.t(RentalsPageTKeys.rentalReport)}</h3>
                  <Button
                    icon={<FileProtectOutlined />}
                    type="link"
                    onClick={() => generatePDF()}
                  >
                    {i18n.t(RentalsPageTKeys.downloadPdf)}
                  </Button>
                </Col>
                <Col span={16}>
                  <Row justify="space-between">
                    <Col span={8} className="amount">
                      <p>{i18n.t(RentalsPageTKeys.revenue)}</p>
                      <p>
                        {currency}&nbsp;
                        <b style={{ color: "#65C87A" }}>
                          {total.totalEarned
                            ? total.totalEarned
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0}
                        </b>
                      </p>
                    </Col>
                    <Col span={8} className="amount">
                      <p>{i18n.t(RentalsPageTKeys.expense)}</p>
                      <p>
                        {currency}&nbsp;
                        <b style={{ color: "#D75745" }}>
                          {total.totalSpent
                            ? total.totalSpent
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0}
                        </b>
                      </p>
                    </Col>
                    <Col span={8} className="amount">
                      <p>{i18n.t(RentalsPageTKeys.profit)}</p>
                      <p>
                        {currency}&nbsp;
                        <b>
                          {total.totalProfit
                            ? (total.totalProfit)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0}
                        </b>
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          )}

          {!rentalId && (
            <RentalsTable
              rentals={rentals}
              showModal={() => setShowAddRentalModal(true)}
              setSelectedRental={(record) => setSelectedRental(record)}
            />
          )}

          {rentalId && selectedRental && selectedRental.id && (
            <RentalDetails record={selectedRental} type={undefined} />
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default RentalsPage;
