import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
//imported components within webapp
import { getWebFirebaseApp } from "../../clients/firebase";
import ShareModal from "../ShareModal/ShareModal"
import ConnectedRental from "../ConnectedRental/ConnectedRental";
import AssetDocuments from "../AssetDocuments/AssetDocuments";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { ColorCode } from "../../constants";
import { useTranslation } from "../../translations/hooks";
import { AssetsPageTKeys, CommonTKeys, PaymentModalTKeys, AddAssetModalTKeys, ShareModalTKeys  } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import "./AssetDetails.css";
import { getServer } from "../../clients/server";
import { getSentry } from '../../clients';
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { Assets, FirestoreDocument, Status, Document } from "@hermes/schema";
import {
  deleteAsset,
  updateAsset,
  loadAssets,
} from "@hermes/shared/src/redux/assets/actions";
import {
  loadPayments
} from "@hermes/shared/src/redux/payments/actions";
import {
  getAssetPayments
} from "@hermes/shared/src/redux/payments/selector";
import { updatePayment } from '@hermes/shared/src/redux/payments/actions';
import { generateShortLink } from "@hermes/shared/src/business/functions/generateShortLink";
//antd imports
import {
  Input,
  Button,
  notification,
  Modal,
  Form,
  Card,
  Upload,
  Row,
  Col,
  Carousel,
  Tag,
  Alert
} from "antd";
import {
  ShareAltOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const confirm = Modal.confirm;
const info = Modal.info;

interface Props {
  active: FirestoreDocument<Assets>
}



const AssetDetails: React.FunctionComponent<Props> = ({ active }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const payments = useSelector(getAssetPayments(active.id));
  const [error, setError] = useState({ key:'', value:false});
  const [assetName, setAssetName] = useState<string>();
  const [assetId, setAssetId] = useState<string>();
  const [assetStatus, setAssetStatus] = useState<Status>();
  const [desc, setDesc] = useState<string>();
  const [documents, setDocuments] = useState<Document[]>();
  const [gallery, setGallery] = useState<{ image: string }[]>();
  const [viewLoading, setViewLoading] = useState(false);
  const [shareModal, setShareModal] = useState(false)
  const [shareLoading, setShareLoading] = useState(false);
  const [url, setUrl] = useState<string>('');
  const user = useSelector((state: StoreState) => state.user);
  const uuidv4 = require('uuid/v4');
  const storageRef = getWebFirebaseApp().storage().ref();
  let imagesRef =storageRef.child('assets-images').child(user.user.id);

  const closeError = () => {
    setError({key:'', value:false});
  }

  const onSave = (
    status = assetStatus,
    document = documents,
    arr = gallery
  ) => {
    if(assetName){
    dispatch(
      updateAsset(active.id, {
        name: assetName,
        identifier: assetId || "",
        description: desc || "",
        ...(gallery && { gallery: arr }),
        status: status,
        ...(document && { document: document }),
      })
    ).then((res) => {
      let config = {
        message: i18n.t(AssetsPageTKeys.assetUpdatedHeading),
        description: i18n.t(AssetsPageTKeys.assetUpdatedDesc),
      };
      notification.success(config);
      if (assetName && assetName !== active.doc.name) {
        for (let i = 0; i < payments.length; i++) {
          let assetRef = {
            id: active.id,
            label: assetName
          }
          let obj = {
            ...assetRef && { asset_ref: assetRef },
          }
          dispatch(updatePayment(payments[i].id, obj)).then((res) => {
            let config = {
              message: i18n.t(PaymentModalTKeys.paymentUpdatedTitle),
              description: i18n.t(PaymentModalTKeys.paymentUpdatedDescription),
            }
            notification.success(config)
          });

        }

      }

    });
    getAnalytics().track(Events.UpdateAsset, {
      id: active.id,
      name: assetName,
      description: desc || "",
      ...(gallery && { gallery: arr }),
      status: status,
      ...(document && { document: document }),
    });
    }
    else{
      setError({key:AddAssetModalTKeys.nameError, value:true});
    }

  };

  const handleDeleteAsset = () => {
    getAnalytics().track(Events.ClickDeleteAsset, {});
    dispatch(deleteAsset(active.id));
    //dispatch(loadAssets());
    history.push(`/assets`);
  };

  const showDeleteConfirm = () => {
    confirm({
      title: i18n.t(AssetsPageTKeys.deleteAssetHeading),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText: i18n.t(CommonTKeys.no),
      onOk() {
        handleDeleteAsset();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const uploadImages = async (arr) => {
    if (arr) {
      let images: { image: string }[] = [];
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].type !== "image/png" && arr[i].type !== "image/jpeg") {
          console.log("not an image");
          return;
        } else {
          let imgRef = imagesRef.child(uuidv4());
          let url: string = await imgRef
            .put(arr[i])
            .then(function (snapshot) {
              return snapshot.ref.getDownloadURL();
            })
            .then((downloadURL) => {
              return downloadURL;
            });
          let obj = {
            image: url,
          };
          images.push(obj);
        }
      }
      dispatch(
        updateAsset(active.id, {
          gallery: [...images, ...(active.doc.gallery || [])],
        })
      ).then((res) => {
        let config = {
          message: i18n.t(AssetsPageTKeys.imagesAddedHeading),
          description: i18n.t(AssetsPageTKeys.imagesAddedDesc),
        };
        notification.success(config);
        getAnalytics().track(Events.ClickAddAssetImage, { images: (active.doc.gallery || []) })

      });
    }
  };

  const showDeleteImageConfirm = (index) => {
    confirm({
      title: i18n.t(AssetsPageTKeys.deleteImageHeading),
      okText: i18n.t(CommonTKeys.yes),
      okType: "danger",
      cancelText: i18n.t(CommonTKeys.no),
      onOk() {
        deleteImage(index);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  const deleteImage = (index) => {
    if (gallery) {
      let arr = gallery.filter((item) => item !== gallery[index]);
      setGallery(arr);
      onSave(assetStatus, documents, arr);
    }
  };

  const imgProps = {
    name: "file",
    multiple: true,
    showUploadList: false,
    action: "//jsonplaceholder.typicode.com/posts/",
    beforeUpload: (file, fileList) => {
      console.log(file, fileList);
      if (file === fileList[fileList.length - 1]) {
        uploadImages(fileList);
      }
      return true;
    },
  };

  const showInfo = () => {
    info({
      title: i18n.t(AssetsPageTKeys.paymentsLinkedHeading),
      content: i18n.t(AssetsPageTKeys.paymentsLinkedDesc),
      onOk() { },
    });
  };

  const getShareUrl = () => {
    const assetIds = [active.id];
    getServer()
      .shareAsset({ assetIds: assetIds })
      .then((response) => {
        console.log(response, "response")
        let link = `https://app.rentbook.com/share/${response.id}/${i18n.language}`
        generateShortLink(link)
        .then((res) => {
          console.log(res)
          if(typeof(res)==='string'){
            setUrl(res)
            setShareLoading(false)
          }
        })
      })
      .catch((err) => {
        getSentry().captureException(err.message);
        console.log(err);
        let config = {
          message: i18n.t(CommonTKeys.error),
          description: i18n.t(ShareModalTKeys.assetShareError)
        };

        notification.error(config);
      })

  }

  useEffect(() => {
    if (active) {
      dispatch(loadPayments());
      setViewLoading(true);
      setTimeout(() => {
        setViewLoading(false);
      }, 500);
      setAssetName(active.doc.name);
      setAssetId(active.doc.identifier);
      setDesc(active.doc.description);
      setDocuments(active.doc.document);
      setGallery(active.doc.gallery);
      setAssetStatus(active.doc.status);
    }
  }, [active]);

  if (!active) {
    return null;
  }

  return (
    <>
      {/* {shareModal ? */}
        <ShareModal
          assetNames={active.doc.name}
          loading={shareLoading}
          visible={shareModal}
          closeModal={() => setShareModal(false)}
          setLoading={(bool) => setShareLoading(bool)}
          setVisible={(bool) => setShareModal(bool)}
          type='asset'
          url={url}
        />
        {/* : null} */}
      <Row
        className={`asset-details ${viewLoading ? "fade-out" : "fade-in"}`}
        gutter={[0, 15]} justify='end'
      >
        <Col span={16}>
          <BreadCrumb link="/assets" heading={i18n.t(AssetsPageTKeys.allAssets)} name={active.doc.name} />
        </Col>

        <Col span={8}>
          <div className="buttons">
            <Button
              icon={<ShareAltOutlined />}
              onClick={() => {
                setShareLoading(true)
                setShareModal(true)
                getShareUrl()
                getAnalytics().track(Events.ClickShareAsset, {});
              }}
            >
              {i18n.t(AssetsPageTKeys.shareAsset)}
            </Button>
          </div>
        </Col>

        <Col span={24}>
          <Card className="basic" bordered>
            {gallery && gallery.length ? (
              <Carousel className="image-container" autoplay>
                {gallery.map((value, index: number) => {
                  return (
                    <div className="image">
                      <div className="image-actions">
                        <div onClick={() => showDeleteImageConfirm(index)}>
                          <DeleteOutlined style={{ color: "red" }} />
                        </div>
                        <div onClick={() => console.log("edit")}>
                          <Upload {...imgProps} accept="image/png, image/jpeg">
                            <PlusOutlined />
                          </Upload>
                        </div>
                      </div>

                      <img src={value.image} alt="asset image" />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
                <div className="image-container empty">
                  <img src={"/assets/placeholder.svg"} alt="Asset Image" />
                  <div className="image-actions">
                    <div onClick={() => console.log("edit")}>
                      <Upload {...imgProps} accept="image/png, image/jpeg">
                        <PlusOutlined />
                      </Upload>
                    </div>
                  </div>
                </div>
              )}

            <Form
              className="basic-details"
              labelCol={{ span: 6 }}
              labelAlign="left"
            >
              {error.value ? (
                <Col span={24}>
                  <Alert
                    className="errMsg"
                    message={i18n.t(error.key)}
                    type="error"
                    closable
                    onClose={() => closeError()}
                  />
                </Col>
              ) : null}
              <Form.Item label={i18n.t(CommonTKeys.nameLabel)}>
                <Input
                  name={i18n.t(CommonTKeys.nameLabel)}
                  value={assetName}
                  onChange={(e) => {
                    setAssetName(e.target.value);
                  }}
                  onBlur={(e) => {
                    onSave();
                  }}
                />
              </Form.Item>
              <Form.Item label={i18n.t(AddAssetModalTKeys.assetIdLabel)}>
                <Input
                  name={i18n.t(CommonTKeys.nameLabel)}
                  placeholder={i18n.t(AddAssetModalTKeys.assetIdPlaceholder)}
                  value={assetId}
                  onChange={(e) => {
                    setAssetId(e.target.value);
                  }}
                  onBlur={(e) => {
                    onSave();
                  }}
                />
              </Form.Item>
              <Form.Item label={"Status"}>
                {assetStatus? <Tag style={{backgroundColor: ColorCode.getEquipmentStatusColor(assetStatus), color: '#fff'}}>{assetStatus}</Tag> : null}
              </Form.Item>


              <Form.Item label={i18n.t(CommonTKeys.descLabel)}>
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  name="name"
                  value={desc}
                  onChange={(e) => {
                    setDesc(e.target.value);
                  }}
                  onBlur={(e) => {
                    onSave();
                  }}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <AssetDocuments documents={documents} active={active} setDocuments={(docs) => setDocuments(docs)} onSave={(status, docs) => onSave(status, docs)} />

        <Col span={24}>
          <ConnectedRental
            assetActive={active}
            personActive={{ id: "", doc: undefined }}
          />
        </Col>
        <Col span={8}>
          <Button
            type="primary"
            size="large"
            icon={<DeleteOutlined />}
            danger block
            onClick={() => {
              if (payments.length) {
                showInfo();
              } else {
                showDeleteConfirm();
              }
            }}
          >
            {i18n.t(AssetsPageTKeys.deleteAsset)}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AssetDetails;
