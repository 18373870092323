import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import "./SharedInvoiceViewer.css";
import { useTranslation } from "../../translations/hooks";
import { SharedFileViewerPageTKeys, SharedReceiptViewTKeys} from "../../translations/keys";
//shared folder imports
import { loadShareInvoiceById } from "@hermes/shared/src/redux/sharedInvoice/actions";
import { getShareInvoice } from "@hermes/shared/src/redux/sharedInvoice/selectors";
import { StoreState } from "@hermes/shared/src/redux/store/types";
import {
    FirestoreDocument,
    Payment,
    RateTypes,
    SharedInvoice,
    Reference
  } from "@hermes/schema";
  import {
    loadAssetById
  } from "@hermes/shared/src/redux/assets/actions";
  import { getAssetsList, getAssetById } from "@hermes/shared/src/redux/assets/selectors";
//antd imports
import { Card, Button, Row, Col, Layout, Modal, Divider, Avatar, Menu, Collapse, List, notification } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
//Other imports
import { isMobileOnly } from "react-device-detect";
import moment from "moment";
const appLink = "https://app.rentbook.com";

const { Panel } = Collapse;

interface Props {
    shareId: string
    owner: Reference
} 

const SharedInvoiceViewer: React.FunctionComponent<Props> = ({ shareId, owner }) => {
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();
    const invoices = useSelector(getShareInvoice);
    const [downloading, setDownloading] = useState<boolean>(false);
    console.log(invoices)

    const formatDate = (d: firebase.firestore.Timestamp)=>{
        let date = new Date(d.seconds*1000+d.nanoseconds/1000)
        const formatted_date = ('0' + date.getDate()).slice(-2)+"/"+('0' + (date.getMonth()+1)).slice(-2)+"/"+ date.getFullYear().toString()
        return formatted_date
    }

    const calcDuration = (invoice:SharedInvoice) => {
        const type: RateTypes = invoice.rate_type
        let duration =''
        switch(type){
            case RateTypes.Daily:
                duration =  i18n.t(SharedReceiptViewTKeys.rateType_Daily, {
                  __DURATION__: `${invoice.duration}`
                  })
                break;
            case RateTypes.Monthly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Monthly, {
                  __DURATION__: `${invoice.duration}`
                })
                break;
            case RateTypes.Weekly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Weekly, {
                  __DURATION__: `${invoice.duration}`
                })
                break;
            case RateTypes.Yearly:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_Yearly, {
                  __DURATION__: `${invoice.duration}`
                })
                break;
            case RateTypes.One_time:
                duration = i18n.t(SharedReceiptViewTKeys.rateType_OneTime)
                break;
        }
        return duration
    }

    useEffect(()=>{
        dispatch(loadShareInvoiceById(shareId)).then((response) => {
            console.log(response);
          }
        );
        if(isMobileOnly){
          let url = window.location.href;
          window.location.href = `rentbook://rentbook.com?shareLink=`+url;
        }
        
        
    }, [])

    return(
        <div className="ant-layout invoice-view">
            <Layout.Header className={"app-header"}>
            <div className="header-container">
              <div className="logo">
                <img src="/assets/logo-dark.svg" />
              </div>
              <Menu mode="horizontal">
                <Button type="primary" size="large" href={appLink}>
                  {isMobileOnly ? i18n.t(SharedFileViewerPageTKeys.tryRentbook) : i18n.t(SharedFileViewerPageTKeys.tryOutRentbook) }
                </Button>
              </Menu>
            </div>
          </Layout.Header>
          {downloading ? (
            <Modal
              className="share-modal"
              title={i18n.t(SharedFileViewerPageTKeys.fileDownload)}
              visible={downloading}
              closable={false}
              cancelButtonProps={{ style: { display: "none" } }}
            >
              <div>{i18n.t(SharedFileViewerPageTKeys.downloading)}</div>
            </Modal>
          ) : null}
         <Row gutter={[30,10]} className="container">
         {invoices.map((invoice, index) => {
              return (
            <Col xs={24} md={12} lg={12} xl={8}>
                <Card>
                    <Col span={24}  className='heading-container'>
                        <h1 className="heading">{i18n.t(SharedReceiptViewTKeys.invoice)}</h1>
                        <div className="amount">
                          <div className="label">{i18n.t(SharedReceiptViewTKeys.amountReceived)}</div>
                          <div className="value">{invoice.doc.currency} {invoice.doc.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                        </div>
    
                    </Col>
                    <Col span={24}>
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.billedTo)}</div>
                        <div className="value">{invoice.doc.people_ref?.label}</div>
                    </Col>
                    <Col span={24}>
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.invoiceDate)}</div>
                        <div className="value">{formatDate(invoice.doc.invoice_date)}</div>
                    </Col>
                    <Col span={24} className="attachments">
                        {/* <div className="label">Attachments</div> */}
                        {/* {receipt.doc.documents && receipt.doc.documents.length ?
                        <div>
                        {receipt.doc.documents.map((doc) =>{
                            return(
                                
                                <a className="images" href={doc.url} target="_blank">
                                    <Avatar
                                        shape="square"
                                        size={60}
                                        src={
                                            doc.type === "application/pdf"
                                            ? "/assets/pdf.png"
                                            : doc.url
                                        }
                                        />
                                </a>
                                
                            )
                        })}
                        </div>
                        :
                        <div>No attachments</div>
                       } */}
                       
                    </Col>
                    <Divider dashed={true} />
                    <Col span={24} className="asset-detail asset">
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.asset)}</div>
                        <div className="value">{invoice.doc.asset_ref?.label}</div>
                        {/* <div className="value identifier">{asset?.doc.identifier||''}</div> */}
                    </Col>
                    <Col span={24} className="asset-detail">
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.rate)}</div>
                        <div className="value">{invoice.doc.currency} {invoice.doc.rate}</div>
                    </Col>
                    <Col span={24} className="asset-detail duration">
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.duration)}</div>
                        <div className="value">{calcDuration(invoice.doc)}</div>
                    </Col>
                    <Divider dashed={true} />
                    {invoice.doc.description?
                    <Col span={24}>
                        <div className="label desc">{i18n.t(SharedReceiptViewTKeys.description)}</div>
                        <div className="value desc">{invoice.doc.description.charAt(0).toUpperCase()+ invoice.doc.description.slice(1)}</div>
                    </Col>
                    :
                    null
                    }
                    <Col span={24}>
                        <div className="label">{i18n.t(SharedReceiptViewTKeys.preparedBy)}</div>
                        <div className="value">{owner.label}</div>
                    </Col>
                    
                </Card>
            </Col>
        
              )}
        )}
        
        </Row>  
    
    
    
        </div>)
    
}

export default SharedInvoiceViewer;