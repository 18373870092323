import * as React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//imported components within webapp
import { useTranslation } from "../../translations/hooks";
import { CommonTKeys, RentalsPageTKeys } from "../../translations/keys";
import { getAnalytics, Events } from "../../clients/analytics";
import "./RentalsTable.css";
//shared folder imports
import { StoreState } from "@hermes/shared/src/redux/store/types";
import { Payment, FirestoreDocument, RateTypes } from "@hermes/schema";
import { calculateAmount } from "@hermes/shared/src/redux/payments/selector";
import { setActivePayment, setActivePaymentType } from "@hermes/shared/src/redux/ui/actions";
//antd imports
import { Button, Table, Empty, Tag } from "antd";
import { RightOutlined, UserOutlined, SyncOutlined } from "@ant-design/icons";

interface Props {
  rentals: FirestoreDocument<Payment>[];
  showModal: () => void;
  setSelectedRental: (record: FirestoreDocument<Payment>) => void;
}

const { Column } = Table;

const RentalsTable: React.FunctionComponent<Props> = ({
  rentals,
  showModal,
  setSelectedRental,
}) => {
  const { i18n, t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: StoreState) => state.user);
  let currency = user.user.doc.currency;

  const emptyRentals = (
    <Empty
      className="empty-large"
      image="/assets/empty-rentals.svg"
      description={
        <p>
          {i18n.t(RentalsPageTKeys.emptyDesc)}
          <br />
          <a
            onClick={() => {
              getAnalytics().track(Events.ClickAddNewPayment, {});
              showModal();
            }}
          >
            {i18n.t(RentalsPageTKeys.emptyLink)}
          </a>
        </p>
      }
    />
  );

  return (
    <div className="rentals-table">
      <Table
        dataSource={rentals}
        locale={{
          emptyText: emptyRentals,
        }}
        rowKey="id"
        pagination={{
          defaultPageSize: 20,
          showSizeChanger: true,
          total: rentals.length,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        {/* <Column title="SL No" dataIndex="id" render={(text, record: FirestoreDocument<Payment>) => payments.indexOf(record) + 1} /> */}
        <Column
          title={i18n.t(RentalsPageTKeys.assetsLabel)}
          dataIndex={["doc", "asset_ref", "label"]}
          sorter={(
            a: FirestoreDocument<Payment>,
            b: FirestoreDocument<Payment>
          ) =>
            a.doc.asset_ref && b.doc.asset_ref
              ? a.doc.asset_ref.label.localeCompare(b.doc.asset_ref.label)
              : 0
          }
          sortDirections={["descend", "ascend"]}
          render={(text, record: FirestoreDocument<Payment>) => {
            if (!text || !text.length) text = "--";
            let contact = "--";
            if (record.doc.people_ref) contact = record.doc.people_ref.label;
            return (
              <span>
                <p>{text}</p>
                <Tag icon={<UserOutlined />}>{contact}</Tag>
              </span>
            );
          }}
        />
        {/* <Column
          title="Person"
          dataIndex={["doc", "people_ref", "label"]}
          render={(text, record: FirestoreDocument<Payment>) => {
            if (!text || !text.length) text = "--";
            return text;
          }}
        /> */}
        <Column
          title={i18n.t(CommonTKeys.descLabel)}
          dataIndex={["doc", "data", "description"]}
          render={(text, record: FirestoreDocument<Payment>) => {
            if (!text || !text.length) text = "--";
            return <span>{text}</span>;
          }}
        />
        {/* <Column
          title="Rental Type"
          dataIndex={["doc", "data", "rate_type"]}
          render={(text, record: FirestoreDocument<Payment>) => {
            let endDate_f = moment();
            let currentDate_f = moment();
            if (record.doc.data.end_date) {
              endDate_f = moment(record.doc.data.end_date!.toDate());
            }
            return (
              <span>
                <b>{text}</b>
                {text === RateTypes.One_time
                  ? ` ${record.doc.type}`
                  : ` repeating ${record.doc.type}`}
                {!record.doc.data.on_going && (
                  <span>
                    <br />
                    ending on <b>{moment(endDate_f).format("DD-MM-YYYY")}</b>
                  </span>
                )}
              </span>
            );
          }}
        /> */}
        <Column
          title={i18n.t(RentalsPageTKeys.paymentLabel)}
          dataIndex={["doc", "type"]}
          render={(text, record: FirestoreDocument<Payment>) => {
            let d;
            if (record.doc.type === "income") {
              d = "earned";
            } else {
              d = "spent";
            }
            let amount = calculateAmount(
              record.doc.data.rate,
              record.doc.data.duration,
              record.doc.data.rate_type,
              record.doc.data.start_date,
              record.doc.data.end_date,
              record.doc.data.on_going
            );
            if (!amount) {
              amount = 0;
            }
            let msg =
              `${currency} ` +
              amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return (
              <span>
                <b
                  style={{
                    color: d === "earned" ? "#2ecc71" : "#e74c3c",
                  }}
                >
                  {d === "earned" ? "+" : "-"} {msg}
                </b>
                {record.doc.data.on_going ? (
                  <div style={{ margin: "5px 0px" }}>
                    <Tag color="green" icon={<SyncOutlined />}>
                      On Going
                    </Tag>
                  </div>
                ) : null}
              </span>
            );
          }}
        />
        <Column
          title={i18n.t(CommonTKeys.actionLabel)}
          render={(text, record: FirestoreDocument<Payment>) => {
            return (
              <Button
                type="primary"
                shape="round"
                ghost
                onClick={() => {
                  setSelectedRental(record);
                  console.log(record);
                  dispatch(setActivePayment(record.id));
                  dispatch(setActivePaymentType(record.doc.type))
                  history.push(`/rentals/${record.id}`);
                  getAnalytics().track(Events.ClickPaymentDetails, { record });
                }}
              >
                {i18n.t(CommonTKeys.viewDetailsButton)}
                <RightOutlined />
              </Button>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default RentalsTable;
