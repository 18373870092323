//@ts-ignore
import numeral from 'numeral';
//@ts-ignore
import moment from 'moment'
import { Payment, RateTypes, PaymentType, Reference, PaymentTypeData } from "@hermes/schema";
import pdfmake from "pdfmake/build/pdfmake";
import pdfFonts from './pdfmake_vfs_fonts';
require("moment/min/locales.min");
import './string.extension' 
import { useTemplate, UseTemplateForm } from './templateChooser';
// pdfmake.vfs = pdfFonts.pdfMake ? pdfFonts.pdfMake.vfs : pdfmake.vfs;
pdfmake.vfs = pdfFonts;

const fonts = {
    en: "MarkaziText",
    hi: "Hind",
    ur: "MarkaziText",
    ar: "MarkaziText",
    id: "MarkaziText"
};

pdfmake.fonts = {
    MarkaziText: {
        bold: 'MarkaziText-Bold.ttf',
        bolditalics: 'MarkaziText-Medium.ttf',
        normal: 'MarkaziText-Regular.ttf',
        italic: 'MarkaziText-Italic.ttf'
    },
    Hind: {
        bold: 'Hind-Bold.ttf',
        bolditalics: 'Hind-Medium.ttf',
        normal: 'Hind-Regular.ttf',
        italic: 'Hind-Italic.ttf'
    }
};

export interface GeneratePDFReportForm {
    templateType: "income" | "expense" | "both",
    payments: Payment[],
    docGeneratedTimeText: string,
    columnTypeText: string,
    columnAssetText: string,
    personColumnText: string,
    descriptionColumnText: string
    startColumnText: string,
    endColumnText: string,
    rateTypeColumnText: string,
    rateColumnText: string,
    totalColumnText: string,
    totalIncomeText: string,
    totalExpensesText: string,
    grandTotalText: string,
    incomeText: string,
    expenseText: string,
    lang: string,
    durationText: string,
    ongoingText: string,
    dailyText: string,
    monthlyText: string,
    yearlyText: string,
    oneText: string,
    weeklyText: string,
    onTimeText: string,
    dayText: string,
    daysText: string,
    monthText: string,
    monthsText: string,
    weekText: string,
    weeksText: string,
    yearText: string,
    yearsText: string,
}

const directionMap: {[lang: string]: string} = {
    en: "left",
    hi: "left",
    ur: "right",
    ar: "right",
    id: "left"
};

export interface templateForm {
    onTimeText: string,
    dayText: string,
    weekText: string,
    monthText: string,
    yearText: string,
    moment: moment,
    amountEarned: number,
    amountSpent: number,
    directionMap: {[lang: string]: string},
    appName: string,
    paymentsMap: {
        netTotal: number;
        incomeRate: string;
        incomeDuration: string;
        expenseRate: string;
        expenseDuration: string;
        user_ref: Reference;
        asset_ref?: Reference;
        people_ref?: Reference;
        type: PaymentType,
        data: PaymentTypeData,
        ended?: boolean,
    }[],
    generatedText: string,
    form: GeneratePDFReportForm,
    dateConv: any,
    RateTypeMap: {[type: string]: string},
    numberConv: (num: string) => string,
    fonts: {[name: string]: string},

};


const appName = "RentBook";
export const generatePDFReport = (form: GeneratePDFReportForm) => {
    moment().locale(form.lang);
    const paymentsMap = form.payments.map((v) => {
        return {
            ...v,
            netTotal: v.data.rate * v.data.duration,
            incomeRate: v.type === PaymentType.Income? v.data.rate.toString(): "",
            incomeDuration: v.type === PaymentType.Income? v.data.duration.toString(): "",
            expenseRate: v.type === PaymentType.Expense? v.data.rate.toString(): "",
            expenseDuration: v.type === PaymentType.Expense? v.data.duration.toString(): "",
        }
    });
    const dateConv = (d: any) => {
        if (form.lang === "ar" || form.lang === "ur") {
            const day = d.format("Do,").split(" ").reverse().join(" ");
            const year = d.format("YYYY,").split(" ").reverse().join(" ");
            const month = d.format("MMM").split(" ").reverse().join(" ");
            return year+" "+day+" "+month;
        }
        else {
            return d.format("MMM Do, YYYY");
        }
    };
    const amountEarned = paymentsMap.filter((f) => f.type === "income").reduce((prev, curr) => prev + curr.netTotal, 0);
    const amountSpent = paymentsMap.filter((f) => f.type === "expense").reduce((prev, curr) => prev + curr.netTotal, 0);
    const generatedTime = () => {
        if (form.lang == "ar" || form.lang == "ur") {
            return dateConv(moment(new Date()).locale(form.lang))
        } else {
            return new Date().toLocaleString();
        }
    };
    const generatedText = () => {
        if (form.lang == "ar" || form.lang == "ur") {
            return form.docGeneratedTimeText + " " + generatedTime();
        }
        else {
            return form.docGeneratedTimeText + " "+ generatedTime();
        }
    };

    const useTemplateForm: UseTemplateForm = {
        templateType: form.templateType,
        ...form,
        amountEarned,
        amountSpent,
    };    

const dd = {
    pageOrientation: "landscape",
    pageMargins: [40, 40, 40, 40],

    content: [
        { text: appName, style: "header", alignment: directionMap[form.lang] },
        { text: generatedText(), style: "micro", alignment: directionMap[form.lang] },
        " ",
        {
            style: "tableExample",
            table: {
                width: useTemplate(useTemplateForm).width,
                body: useTemplate(useTemplateForm).body,

            },
            layout: {
                paddingLeft: function() {
                    return 5;
                },
                paddingRight: function() {
                    return 5;
                },
                paddingTop: function() {
                    return 5;
                },
                paddingBottom: function() {
                    return 5;
                },
                hLineColor: function() {
                    return "#D1D8DD";
                },
                vLineColor: function() {
                    return "#D1D8DD";
                },
            },
        },
    ],
    styles: {
        header: {
            fontSize: 24,
            bold: true,
            margin: [0, 0, 0, 0],
        },
        subheader: {
            fontSize: 16,
            bold: true,
            alignment: "center",
            margin: [0, 10, 0, 5],
        },
        tableExample: {
            margin: [0, 5, 0, 15],
            fontSize: 10,
        },
        tableHeader: {
            bold: true,
            fontSize: 13,
            color: "black",
        },
        micro: {
            fontSize: 10,
        },
    },
    defaultStyle: {
        fontSize: 10,
        font: fonts[form.lang],
        alignment: directionMap[form.lang],
    },
};

    const pdfDocGenerator = pdfmake.createPdf(dd);
    return new Promise ((resolve, reject) => {
        pdfDocGenerator.getBase64((data:any) => {
            resolve(data);
        });
    }).catch((err) => {
        console.log({createPDFError: err})
    })
};
