import * as React from 'react';
import { StoreState } from '../../../redux/store/types';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../Theme';
import { Language } from '@hermes/schema';
import { Translation } from 'react-i18next';

// renders only if lang is present in store.
// subscribes to lang and updates ui when lang changes.
export const withLanguage = (WrappedComponent) => {

    const WithComponent: React.FunctionComponent = (props) => {
        const language = useSelector((state: StoreState) => state.user.language);
        if (!language) {
            return null;
        }
        const isRTL = language === Language.Arabic || language === Language.Urdu;
        return (<ThemeContext.Provider value={{ defaultTextAlignment:  isRTL ? 'right': 'left' }}>
            <WrappedComponent {...props} />
        </ThemeContext.Provider>)
    }
    (WithComponent as any).navigationOptions = WrappedComponent.navigationOptions;
    return WithComponent;
}