// Theme context for keeping locale text alignment etc..
import * as React from 'react';

export interface Theme {
    defaultTextAlignment: 'right' | 'left';
};

export const defaultTheme: Theme = {
    defaultTextAlignment: 'left',
}

export const ThemeContext = React.createContext(defaultTheme);
