import { createSelector } from 'reselect';
import { StoreState } from '../store/types';


const getPeopleMap = (state: StoreState) => state.people;

export const getPeopleList = createSelector(
  [getPeopleMap],
  (peopleMap) => {
    const people = Object.values(peopleMap);
    people.sort((a, b) => b.doc.internal.created_at.seconds - a.doc.internal.created_at.seconds)
    return people;
  }
);

export const getPeopleArray = createSelector(
  [getPeopleMap],
  (peopleMap) => {
    const people = Object.values(peopleMap);
    const poepleArray = people.map((p) => {
      return {
        label: p.doc.name,
        value: p.id
      }
    });
    return poepleArray;
  }
)

export const getPersonById = (id:string) => createSelector(
  [getPeopleMap],
  (peopleMap) => {
    const people = Object.values(peopleMap);
    const person = people.find((item) => item.id === id)
    return person;
  }
);