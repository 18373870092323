import { createSelector } from 'reselect';
import { StoreState } from '../store/types';


const getAssetsMap = (state: StoreState) => state.assets;

export const getAssetsList = createSelector(
  [getAssetsMap],
  (assetMap) => {
    const assets = Object.values(assetMap);
    assets.sort((a, b) => b.doc.internal.created_at.seconds - a.doc.internal.created_at.seconds)
    return assets;
  }
);

export const getAssetArray = createSelector(
  [getAssetsMap],
  (assetMap) => {
    const asset = Object.values(assetMap);
    const assetArray = asset.map((p) => {
      return {
        label: p.doc.name,
        value: p.id
      }
    });
    return assetArray;
  }
)

export const getAssetById = (id:string) => createSelector(
  [getAssetsMap],
  (assetMap) => {
    const assets = Object.values(assetMap);
    const asset = assets.find((item) => item.id === id)
    return asset;
  }
);