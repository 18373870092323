import * as React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Input,
  Avatar,
  Button,
  List,
  Row,
  Col,
  Card,
  Modal,
  notification,
  Collapse,
  Divider,
  Carousel,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { isMobileOnly } from "react-device-detect";
import "./SharedAssetViewer.css";
import AddInquiryModal from '../AddInquiryModal/AddInquiryModal';
import { useTranslation } from "../../translations/hooks";
import { SharedAssetViewerTKeys, SharedFileViewerPageTKeys } from "../../translations/keys";
import { toDataURL } from "../../SharedFileViewerPage/SharedFileViewerPage";
import { Owner, FirestoreDocument,SharedAsset, Assets, langAbbrMap, Language  } from "@hermes/schema";
import { changeLanguage } from "@hermes/shared/src/redux/user/actions";

const { Panel } = Collapse;

type Props = {
   store: false
   assets: FirestoreDocument<SharedAsset>[]
} | {
    store: true
    assets: FirestoreDocument<Assets>[]
    owner: Owner
}

const SharedAssetViewer: React.FunctionComponent<Props> = (props) => {
  const {i18n, t} = useTranslation();
  const [downloading, setDownloading] = useState<boolean>(false);
  const [showInquiryModal, setShowInquiryModal] = useState<boolean>(false);
  const [assetName, setAssetName] = useState<string>('');
  const [assetId, setAssetId] = useState<string>('');
  const [owner, setOwner] = useState<Owner>();
  const dispatch = useDispatch();


  useEffect(() => {
    if (props.store) {
      setOwner(props.owner);
      i18n.changeLanguage(langAbbrMap[props.owner.language || langAbbrMap[Language.English] as Language]);
     // dispatch(changeLanguage(langAbbrMap[owner?.language || langAbbrMap[Language.English] as Language]))
    }
  }, [props.store]);

  console.log({languageLolOwner: owner})


  const download = async (url) => {
    setDownloading(true);
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownloading(false);
    let config = {
      message: "Downloaded",
      description: "File has been downloaded",
    };
    notification.success(config);
  };

  const sendInquiry = (owner: Owner, assetName:string, assId: string,) =>{
    setAssetName(assetName);
    setAssetId(assId);
    console.log("coming here")
    //setOwner(owner);
    setShowInquiryModal(true)
  }

  return (
    <>
      {downloading ? (
        <Modal
          className="share-modal"
          title={i18n.t(SharedFileViewerPageTKeys.fileDownload)}
          visible={downloading}
          closable={false}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>{i18n.t(SharedFileViewerPageTKeys.downloading)}</div>
        </Modal>
      ) : null}

      {showInquiryModal && owner? (
          <AddInquiryModal
            visible={showInquiryModal}
            closeModal={() => {
              setShowInquiryModal(false);
            }}
            owner={owner}
            assetName={assetName}
            assetId={assetId}
          />
      ) : null}


      <Row gutter={[20,20]} className="container">
          
        {props.store && props.assets.map((asset, index) => {
          return (
            <Col xs={24} md={12} lg={12} xl={8}>
              <Card>
              {props.assets.length &&
                  asset.doc.gallery &&
                  asset.doc.gallery.length ? (
              <Carousel className="image-container" dots={true}>
                {asset.doc.gallery.map((value, index: number) => {
                  return (
                    <div className="image">
                      <div className="image-actions">
                          {!isMobileOnly? (
                            <Button
                            size={isMobileOnly ? "small" : "middle"}
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              download(value.image);
                            }}
                          >
                            {i18n.t(SharedFileViewerPageTKeys.download)}
                          </Button>
                          ): null}
                      </div>
                      <img src={value.image} alt="asset image" />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
                <div className="image-container empty">
                  <img src={"/assets/placeholder.svg"} alt="Asset Image" />
                </div>
              )}
                  <div className='basic-details'>
                    <div className="name-btn">
                      <h1>{props.assets.length && asset.doc.name && asset.doc.name.length ? asset.doc.name : "--"}</h1>
                      <div className='btn-container'>
                        <Button type="primary" onClick={() => sendInquiry(props.owner, asset.doc.name, asset.id) }>
                        {i18n.t(SharedAssetViewerTKeys.sendInquiry)}
                        </Button> 
                      </div>
                    </div>
                    <Input.TextArea
                      name="name"
                      value={props.assets.length ? asset.doc.description : "--"}
                      disabled
                    />
                  </div>
                   
                 
                  <Divider/>
                  
                  {props.assets.length &&
                  asset.doc.document &&
                  asset.doc.document.length ? (

                      <Collapse className='collapse' bordered={false} expandIconPosition={'right'}>
                      <Panel 
                      header={
                        <p style={{color:"#E88247", fontWeight:600, marginBottom:'0px'}}>{
                          t(SharedAssetViewerTKeys.documentsAvailable, {
                          __DOC_COUNT__: `${asset.doc.document.length}`
                      })}</p>
                        
                      } 
                      key="1">
                        
                      <List
                        className="images-list"
                        itemLayout="horizontal"
                        dataSource={
                            props.assets.length ? asset.doc.document : []
                        }
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <a href={item.url} target="_blank">
                                <Avatar
                                  shape="square"
                                  size={60}
                                  src={
                                    item.type === "application/pdf"
                                      ? "/assets/pdf.png"
                                      : item.url
                                  }
                                />
                                </a>
                              
                              }
                              description={
                                <div className="doc-desc">
                                  <h3>{item.name || "--"}</h3>
                                  <Button 
                                    ghost 
                                    icon={<DownloadOutlined />} 
                                    size={"large"}
                                    onClick={() => {
                                      download(item.url);
                                    }}
                                  />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                      </Panel>
                      </Collapse>
                      
                   
                  ) : 
                  <Collapse className='collapse' bordered={false} expandIconPosition={'right'}>
                      <Panel 
                      header={
                        <p style={{color:"#E88247", fontWeight:600, marginBottom:'0px'}}>{i18n.t(SharedAssetViewerTKeys.noDocAvailable)}</p>
                        
                      } 
                      key="1"></Panel>
                  </Collapse>
                  }
                
              </Card>
            </Col>
          );
        })}

        {!props.store && props.assets.map((asset, index) => {
          return (
            <Col xs={24} md={12} lg={12} xl={8}>
              <Card>
              {props.assets.length &&
                  asset.doc.gallery &&
                  asset.doc.gallery.length ? (
              <Carousel className="image-container" dots={true}>
                {asset.doc.gallery.map((value, index: number) => {
                  return (
                    <div className="image">
                      <div className="image-actions">
                          {!isMobileOnly? (
                            <Button
                            size={isMobileOnly ? "small" : "middle"}
                            icon={<DownloadOutlined />}
                            onClick={() => {
                              download(value.image);
                            }}
                          >
                            {i18n.t(SharedFileViewerPageTKeys.download)}
                          </Button>
                          ): null}
                      </div>
                      <img src={value.image} alt="asset image" />
                    </div>
                  );
                })}
              </Carousel>
            ) : (
                <div className="image-container empty">
                  <img src={"/assets/placeholder.svg"} alt="Asset Image" />
                </div>
              )}
                  <div className='basic-details'>
                    <div className="name-btn">
                      <h1>{props.assets.length && asset.doc.name && asset.doc.name.length ? asset.doc.name : "--"}</h1>
                      <div className='btn-container'>
                        <Button type="primary" onClick={() => sendInquiry(asset.doc.owner, asset.doc.name, asset.id) }>
                        {i18n.t(SharedAssetViewerTKeys.sendInquiry)}
                        </Button> 
                      </div>
                    </div>
                    <Input.TextArea
                      name="name"
                      value={props.assets.length ? asset.doc.description : "--"}
                      disabled
                    />
                  </div>
                   
                 
                  <Divider/>
                  
                  {props.assets.length &&
                  asset.doc.document &&
                  asset.doc.document.length ? (

                      <Collapse className='collapse' bordered={false} expandIconPosition={'right'}>
                      <Panel 
                      header={
                        <p style={{color:"#E88247", fontWeight:600, marginBottom:'0px'}}>{
                          t(SharedAssetViewerTKeys.documentsAvailable, {
                          __DOC_COUNT__: `${asset.doc.document.length}`
                      })}</p>
                        
                      } 
                      key="1">
                        
                      <List
                        className="images-list"
                        itemLayout="horizontal"
                        dataSource={
                            props.assets.length ? asset.doc.document : []
                        }
                        renderItem={(item) => (
                          <List.Item>
                            <List.Item.Meta
                              avatar={
                                <a href={item.url} target="_blank">
                                <Avatar
                                  shape="square"
                                  size={60}
                                  src={
                                    item.type === "application/pdf"
                                      ? "/assets/pdf.png"
                                      : item.url
                                  }
                                />
                                </a>
                              
                              }
                              description={
                                <div className="doc-desc">
                                  <h3>{item.name || "--"}</h3>
                                  <Button 
                                    ghost 
                                    icon={<DownloadOutlined />} 
                                    size={"large"}
                                    onClick={() => {
                                      download(item.url);
                                    }}
                                  />
                                </div>
                              }
                            />
                          </List.Item>
                        )}
                      />
                      </Panel>
                      </Collapse>
                      
                   
                  ) : 
                  <Collapse className='collapse' bordered={false} expandIconPosition={'right'}>
                      <Panel 
                      header={
                        <p style={{color:"#E88247", fontWeight:600, marginBottom:'0px'}}>{i18n.t(SharedAssetViewerTKeys.noDocAvailable)}</p>
                        
                      } 
                      key="1"></Panel>
                  </Collapse>
                  }
                
              </Card>
            </Col>
          );
        })}
      </Row>
   
    </>
  );
};

export default SharedAssetViewer;
