import { QueryDocumentSnapshot, QuerySnapshot } from "./types";
import { User, FirestoreDocument, Collection, Assets } from "@hermes/schema";
import { firestore } from "@firebase/testing";

export class Query {
    
    constructor(private store: firebase.firestore.Firestore) {

    }

    getAll() {
        return this.store
        .collection("users")
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUserByStoreName(storeName: string) {
        return this.store
        .collection(Collection.User)
        .where("store_name", "==", storeName)
        .get().then((doc) => {
            return (doc.docs || []).map((d) => {
                return {
                    id: d.id,
                    doc: d.data() as User,
                } as FirestoreDocument<User>;
            }) as FirestoreDocument<User>[]
        })
    }

    getUsersInLast7Days(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .orderBy('internal.created_at')
        .startAt(start_date)
        .endAt(end_date)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUserAssets(userId: string) {
        return this.store
        .collection(Collection.User)
        .doc(userId)
        .collection(Collection.Asset)
        .get() as Promise<QuerySnapshot<Assets>>;
    }

    getUsersByLastOpened(date: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .orderBy("internal.last_opened")
        .endAt(date)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUsersByCreatedDate(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .orderBy("internal.created_at")
        .where('internal.created_at', ">=", start_date)
        .where('internal.created_at', "<", end_date)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUsersByStartAndEndDate(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .orderBy("internal.created_at")
        .startAt(start_date)
        .endAt(end_date)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getNotifiedUsersBy7DaysDate(today: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .where("internal.last_sent_sms", "==", true)
        .orderBy("internal.created_at")
        .orderBy('internal.next_7_days')
        .endAt(today)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getNotifiedUsersToShareAssetBy7DaysDate(today: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .where("internal.notified_after_2_days", "==", true)
        .orderBy("internal.created_at")
        .orderBy('internal.next_7_days_share_asset_notification')
        .endAt(today)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getLastOpenedUsersBy7DaysDate(today: firebase.firestore.Timestamp) {
        return this.store
        .collection(Collection.User)
        .where('internal.last_opened_sms', "==", true)
        .orderBy('internal.last_opened')
        .orderBy('internal.last_opened_next_7_days')
        .endAt(today)
        .get() as Promise<QuerySnapshot<User>>;
    }

    // getUsersBy7DaysDate(today: firebase.firestore.Timestamp) {
    //     return this.store
    //     .collection(Collection.User)
    //     .where("internal.last_sent_sms", "==", true)
    //     .where("internal.next_7_days", "<=", today)
    //     .get() as Promise<QuerySnapshot<User>>;
    // }

    getUsersByFirstTwoDays() {
        return this.store
        .collection(Collection.User)
        .where("internal.last_sent_sms", "==", true)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUsersForNotification() {
        return this.store
        .collection("users")
        .where("internal.push_notification", "==", true)
        .get() as Promise<QuerySnapshot<User>>;
    }

    getUser(userId: string) {
        return this.store
        .collection("users")
        .doc(userId)
        .get()
        .then(snapshot => {
            const userDoc: FirestoreDocument<User> = {
                id: snapshot.id,
                doc: snapshot.data() as User,
            };
            return userDoc;
        });    
    };

    getUsersByPhone(number: string) {
        return this.store
        .collection("users")
        .where("phone_number", "==", number)
        .get() as Promise<QuerySnapshot<User>>
    }

    createUser(doc: FirestoreDocument<Partial<User>>) {
        return this.store.collection("users").doc(doc.id).set(doc.doc, {merge: true,})
    }

    getUserByEmail(email: string) {
        return this.store.collection(Collection.User)
        .where("email", "==", email)
        .get() as Promise<QuerySnapshot<User>>
    }

    updateUser(userId: string, user: FirestoreDocument<Partial<User>>) {
        return this.store
            .collection("users")
            .doc(userId)
            .update(user.doc);
    }

    getUsersByDate(date: firebase.firestore.Timestamp) {
        return this.store
            .collection(Collection.User)
            .orderBy("internal.created_at")
            .startAt(date) 
            .get() as Promise<QuerySnapshot<User>>;
    }

    getUsersByDateRange(start_date: firebase.firestore.Timestamp, end_date: firebase.firestore.Timestamp) {
        return this.store
            .collection(Collection.User)
            .orderBy("internal.created_at")
            .startAt(start_date) 
            .endAt(end_date)
            .get() as Promise<QuerySnapshot<User>>;
    }

}